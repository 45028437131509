import gql from 'graphql-tag';

const Profilevalidationquery = gql`
	query profileValidationQuery($username: String!) {
		viewProfileValidation(username: $username) {
			profileValidation {
				id
				employer_username
				employee_username
				employee_name
				employee_email
				employee_response
				file_hashes
				document_count
				requested_at
				expire_time
			}
			__typename
		}
		__typename
	}
`;

export default Profilevalidationquery;
