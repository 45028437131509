import React from "react";
import { ApolloConsumer } from "react-apollo";
import { Query } from "react-apollo";
import { Redirect } from "react-router-dom";
//components
import ScreenLoading from "../LoadingScreen.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//file import
import OnboardedEmployeeList from "./OnboardedEmployeeList.jsx";
import Errorpage from "../Pages/errorPage.jsx";
//queries
import OnboaredEmployeeListQuery from "../../Graphql/OnboaredEmployeeListQuery.js";

//requested employee list for onboarding query
const Onboarding = ({ username = localStorage.getItem("username") }) => (
  <Query query={OnboaredEmployeeListQuery} variables={{ username }}>
    {({ loading, data, error }) => {
      if (loading) {
        return (
          <GridContainer justify="center">
            <GridItem xs={10} sm={8} md={8}>
              <ScreenLoading />
            </GridItem>
          </GridContainer>
        );
      }
      if (error)
        // To show error page on failure to retrieve data from backend

        return (
          <div>
            <Errorpage />
          </div>
        );
      return localStorage.getItem("aadhar_verified") === "0" ? ( // redirect to new user page when the user is new
        <Redirect to="/identityverify" />
      ) : (
        <div>
          <OnboardedEmployeeList
            key={username}
            Onboarded_Employee={data.onBoardList}
          />
        </div>
      );
    }}
  </Query>
);

class EmployeeOnboardingQuery extends React.Component {
  render() {
    return (
      //Apollo client instances
      <ApolloConsumer>
        {client => <Onboarding client={client} />}
      </ApolloConsumer>
    );
  }
}

export default EmployeeOnboardingQuery;
