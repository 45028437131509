import React from "react";
import { Query } from "react-apollo";
import { Redirect } from "react-router-dom";
//core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//File import
import ViewRequestList from "./ViewRequestList.jsx";
import Errorpage from "../Pages/errorPage.jsx";
import Datanotfound from "../Pages/Datanotfound.jsx";
import ScreenLoading from "../LoadingScreen.jsx";

//Query
import EmploymentQuery from "../../Graphql/Employmentquery";

// Retrieve Employee document approval request query
const Requestquery = ({
  username = localStorage.getItem("username"),
  client,
  document_requested_status,
  history
}) => (
  <Query query={EmploymentQuery} variables={{ username }}>
    {({ loading, data, getMulEmployment, error }) => {
      if (loading) {
        return (
          <GridContainer justify="center">
            <GridItem xs={10} sm={8} md={8}>
              <ScreenLoading />
            </GridItem>
          </GridContainer>
        );
      }
      // To show error page on failure to retrieve data from backend

      if (error)
        return (
          <div>
            <Errorpage />
          </div>
        );

      return localStorage.getItem("aadhar_verified") === "0" ? ( // redirect to new user page when the user is new
        <Redirect to="/identityverify" />
      ) : (
        <div>
          {data.getMulEmployment === null ? (
            //If no request exist
            <Datanotfound
              title={"No Verification Request Found"}
              message={
                "You will get this data if Employee sent you the Document verification Request"
              }
              button={false}
            />
          ) : (
            <ViewRequestList
              key={data.getMulEmployment}
              Employment={data.getMulEmployment}
              client={client}
              document_requested_status={document_requested_status}
              history={history}
            />
          )}
        </div>
      );
    }}
  </Query>
);

export default Requestquery;
