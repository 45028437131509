import React from "react";
import { Query } from "react-apollo";
import { ApolloConsumer } from "react-apollo";
import { Redirect } from "react-router-dom";
//components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ScreenLoading from "../LoadingScreen.jsx";
//file imports
import EmployerHistory from "./EmployerHistory.jsx";
import Errorpage from "../Pages/errorPage.jsx";
import Datanotfound from "../Pages/Datanotfound.jsx";
//queries
import EmployerHistoryRetrieveQuery from "../../Graphql/EmployerHistoryRetrieveQuery.js";

const Historyquery = ({
  username = localStorage.getItem("username"),
  history,
  client
}) => (
  <Query query={EmployerHistoryRetrieveQuery} variables={{ username }}>
    {({ loading, data, error }) => {
      if (loading) {
        return (
          <GridContainer justify="center">
            <GridItem xs={10} sm={8} md={8}>
              <ScreenLoading />
            </GridItem>
          </GridContainer>
        );
      }
      // To show error page on failure to retrieve data from backend

      if (error)
        return (
          <div>
            <Errorpage />
          </div>
        );

      return localStorage.getItem("aadhar_verified") === "0" ? ( // redirect to new user page when the user is new
        <Redirect to="/identityverify" />
      ) : (
        <div>
          {data.getEmployerHistory.history === null ? (
            //If data not exits show data not found page
            <Datanotfound
              title={"No History Found"}
              message={
                "You will get this data if You Request your Employee to share their Employment Data with you"
              }
              button={true}
              link={"/employee/viewrequest"}
              buttontext={"Approve Document"}
            />
          ) : (
            <EmployerHistory
              key={username}
              Employerhistory={data.getEmployerHistory}
              history={history}
              client={client}
            />
          )}
        </div>
      );
    }}
  </Query>
);

class HistoryQuerypage extends React.Component {
  render() {
    return (
      <ApolloConsumer>
        {client => (
          <Historyquery history={this.props.history} client={client} />
        )}
      </ApolloConsumer>
    );
  }
}

export default HistoryQuerypage;
