import React, { Component, Fragment, Suspense } from "react";
import MUIDataTable from "mui-datatables";
import Tour from "reactour";
import { getGlobal, resetGlobal } from "reactn";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import { MuiThemeProvider } from "@material-ui/core/styles";

// @material-ui/icons
import PersonAdd from "@material-ui/icons/PersonAdd";
import GroupAdd from "@material-ui/icons/GroupAdd";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
//File Import
import Datanotfound from "../Pages/Datanotfound.jsx";
//Styles
import Mytheme from "../Custom-Styles/CustomMuiTheme";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import ErrorPage from "../Pages/errorPage.jsx";
import ScreenLoading from "../LoadingScreen.jsx";

const OnboardSingleEmployee = React.lazy(() =>
  import("./OnboardSingleEmployee.jsx")
);
const OnboardBulkEmployee = React.lazy(() =>
  import("./OnboardBulkEmployee.jsx")
);
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  SingleOnboardemployeeButton: {
    float: "left"
  },
  BulkOnboardemployeeButton: {
    float: "right"
  }
};

class OnboardedEmployeeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowOnboardedEmployeeList: true,
      ShowOnboardBulkEmployee: false,
      Onboard_Employee_List: [],
      hidebutton: true,
      hashError: false,
      // react tour variables
      listHelp: false,
      singleOnboardhelp: false,
      bulkOnboardhelp: false,
      steps: [
        {
          content: () => (
            <Fragment>
              <b> Let's see how to onboard employee</b>
              <br />
              Here you can onboard single employee at a time
            </Fragment>
          ),

          position: "top",
          selector: ".SingleEmployeeClass"
        },
        {
          content: () => (
            <Fragment>
              <b>Onboard Employee</b>
              <br />
              You can onboard bulk employee
            </Fragment>
          ),
          position: "bottom",
          selector: ".BulkOnboardemployeeClass"
        }
      ] // steps
    };
  }

  // function to close the tour
  closeTour = () => {
    resetGlobal();
    this.setState({
      listHelp: false,
      singleOnboardhelp: true,
      bulkOnboardhelp: true
    });
  };
  handleBack = data => {
    resetGlobal();
    this.setState({ listHelp: false });

    // setting the running state help to false if we move back to onboard list page

    if (data === "singleOnboard") {
      this.setState({ singleOnboardhelp: false });
    }

    if (data === "bulkOnboard") {
      this.setState({ bulkOnboardhelp: false });
    }

    // if employee onboarded user
    if (this.state.Onboard_Employee_List.length || data === "onboardSuccess") {
      this.setState({
        ShowOnboardSingleEmployee: false,
        ShowOnboardedEmployeeList: true,
        ShowOnboardBulkEmployee: false,
        showDataNotFound: false
      });
    } else {
      //  Employer didnot onboard any employee
      this.setState({
        showDataNotFound: true,
        ShowOnboardSingleEmployee: false,
        ShowOnboardedEmployeeList: false,
        ShowOnboardBulkEmployee: false
      });
    }
  };
  showaddemployee = () => {
    this.setState({
      ShowOnboardSingleEmployee: true,
      ShowOnboardedEmployeeList: false,
      ShowOnboardBulkEmployee: false,
      hidebutton: false
    });
  };
  showaddbulkemployee = () => {
    this.setState({
      ShowOnboardSingleEmployee: false,
      ShowOnboardedEmployeeList: false,
      ShowOnboardBulkEmployee: true
    });
  };

  handleTable(Onboarded_Employee) {
    var Onboard_Employee_List = [];
    const Onboarded_Employeedata = Onboarded_Employee.onboardList;
    Object.keys(Onboarded_Employeedata).forEach(function(key) {
      Onboard_Employee_List.push([
        Onboarded_Employeedata[key].employee_name,
        Onboarded_Employeedata[key].employee_id,
        Onboarded_Employeedata[key].aadhar_number,
        Onboarded_Employeedata[key].document_status,
        <div
          style={
            Onboarded_Employeedata[key].document_status === "Migrated"
              ? { color: "green" }
              : Onboarded_Employeedata[key].document_status === "Pending"
              ? { color: "#ff9800" }
              : { color: "red" }
          }
        >
          {Onboarded_Employeedata[key].document_status}
        </div>,
        Onboarded_Employeedata[key].is_onboard
      ]);
    });
    this.setState({
      Onboard_Employee_List: Onboard_Employee_List
    });
  }
  //handling error in components
  componentDidCatch(error, info) {
    //if (greater than the total available page length) error occur we redirecting it to starting page
    if (
      error.toString().includes("greater than the total available page length")
    ) {
      this.setState({ page: 0 });
    } else {
      this.setState({ hashError: true });
    }
  }
  componentDidMount() {
    if (this.props.Onboarded_Employee.onboardList) {
      this.handleTable(this.props.Onboarded_Employee);
    } else {
      // To display Data not found page
      this.setState({
        listHelp: true,
        singleOnboardhelp: true,
        bulkOnboardhelp: true,
        showDataNotFound: true,
        ShowOnboardedEmployeeList: false
      });
    }
  }
  componentDidUpdate(props, state) {
    if (props.Onboarded_Employee.onboardList !== null) {
      if (
        (Object.keys(props.Onboarded_Employee.onboardList).length === 1 &&
          state.Onboard_Employee_List.length === 0) ||
        (this.state.Onboard_Employee_List.length === 0 &&
          Object.keys(props.Onboarded_Employee.onboardList).length > 1)
      ) {
        this.handleTable(props.Onboarded_Employee);
      }
    }
    if (state.Onboard_Employee_List.length) {
      if (
        props.Onboarded_Employee.onboardList.length !==
        state.Onboard_Employee_List.length
      ) {
        this.handleTable(props.Onboarded_Employee);
      }
    }
  }

  onboardHandleback = (data, helpStatus) => {
    // To display single employee onboard
    if (data === "onboardSingleEmployee") {
      this.setState({
        ShowOnboardSingleEmployee: true,
        showDataNotFound: false,
        singleOnboardhelp:
          helpStatus === true ? true : this.state.singleOnboardhelp
      });
    } else {
      // To display bulk employee onboard
      this.setState({
        ShowOnboardBulkEmployee: true,
        showDataNotFound: false,
        bulkOnboardhelp: helpStatus === true ? true : this.state.bulkOnboardhelp
      });
    }
  };
  render() {
    const { classes } = this.props;
    const { steps } = this.state;

    const options = {
      filterType: "dropdown",
      responsive: "stacked",
      fixedHeader: true,
      selectableRows: false,
      page: this.state.page
    };
    const columns = [
      {
        name: "Employee Name",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "Employee Id",
        options: {
          filter: true,
          sort: false
        }
      },
      {
        name: "National Identity Number",
        options: {
          filter: true,
          sort: false
        }
      },
      {
        name: "Doc Status",
        options: {
          filter: true,
          sort: false,
          display: false
        }
      },
      {
        name: "Document Status",
        options: {
          filter: false,
          sort: false
        }
      },
      {
        name: "Onboard Status",
        options: {
          filter: true,
          sort: false
        }
      }
    ];
    if (this.state.hashError) {
      return (
        <div>
          <ErrorPage />
        </div>
      );
    } else {
      return (
        <div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} lg={12}>
              {this.state.showDataNotFound ? (
                <Datanotfound
                  title={"Onboard Employee"}
                  message={
                    "You will get this data if You Request your Employee to share their Employment Data with you"
                  }
                  steps={this.state.steps}
                  onboard={true}
                  help={this.state.listHelp}
                  onboardHandleback={this.onboardHandleback}
                />
              ) : null}

              {this.state.ShowOnboardedEmployeeList ? (
                <Card>
                  <Tour
                    onRequestClose={this.closeTour}
                    steps={steps}
                    isOpen={getGlobal().value ? true : false}
                    accentColor="#FFC90D"
                  />
                  <CardHeader color="info" icon>
                    <CardIcon color="info">
                      <Icon>input</Icon>
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                      <b>Onboarded Employee List</b>
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={12}>
                        <Button
                          color="info"
                          className="SingleEmployeeClass"
                          style={{ float: "left" }}
                          onClick={this.showaddemployee}
                        >
                          <PersonAdd />
                          &nbsp; Onboard Single Employee
                        </Button>

                        <span>
                          <Button
                            color="info"
                            style={{ float: "right" }}
                            className="BulkOnboardemployeeClass"
                            onClick={this.showaddbulkemployee}
                          >
                            <GroupAdd />
                            &nbsp; Onboard Bulk of Employees
                          </Button>
                        </span>
                      </GridItem>
                    </GridContainer>

                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={12}>
                        <MuiThemeProvider theme={Mytheme}>
                          <MUIDataTable
                            title={""}
                            data={this.state.Onboard_Employee_List}
                            columns={columns}
                            options={options}
                          />
                        </MuiThemeProvider>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              ) : null}
              {this.state.ShowOnboardSingleEmployee ? (
                <Suspense fallback={<ScreenLoading />}>
                  <OnboardSingleEmployee
                    handleBack={this.handleBack}
                    hidebutton={this.state.hidebutton}
                    help={this.state.singleOnboardhelp}
                  />
                </Suspense>
              ) : null}
              {this.state.ShowOnboardBulkEmployee ? (
                <Suspense fallback={<ScreenLoading />}>
                  <OnboardBulkEmployee
                    handleBack={this.handleBack}
                    help={this.state.bulkOnboardhelp}
                  />
                </Suspense>
              ) : null}
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default withStyles(styles)(OnboardedEmployeeList);
