import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import axios from "axios";
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Hidden from "@material-ui/core/Hidden";

//images
import Robot from "assets/img/robot.png";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Lock from "@material-ui/icons/Lock";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
//Custom component
import CustomInput from "../../Custom-component/CustomInput/CustomInput.jsx";
import LoaderButton from "../../Custom-component/LoaderButton";
import config from "../../config.js"; //Configuration file
//styles
import "../../Custom-component/tippy.css";
//file import
import loginPageStyle from "./loginPageStyle.jsx";
import ProfileValidation from "../Validation/ProfileValidation.jsx";
import WarningAlert from "../WarningAlert.jsx";
//hasing the values
var sha256 = require("js-sha256");

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      login_username: "",
      login_usernameState: "",
      login_password: "",
      login_passwordState: "",
      isLoading: false,
      alert: null,
      user_data: {},
      login_usernameErrMsg: "",
      login_passwordErrMsg: "",
      errorMessage: "",
      loginWarningAlert: false
    };
  }
  autoCloseAlert = name => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-200px", color: "#000000" }}
          title="Login Successful!"
          onConfirm={() => this.successhideAlert()}
          showConfirm={false}
        >
          <h4>Welcome {name}</h4>
        </SweetAlert>
      )
    });
    setTimeout(this.successhideAlert, 2000);
  };

  successhideAlert = () => {
    this.setState({
      alert: null
    });

    this.props.history.push("/dashboard");
  };

  change_login(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "loginusername":
        var login_user_name = ProfileValidation.validateUsername(
          event.target.value,
          stateName
        );
        if (!login_user_name[stateName]) {
          this.setState({
            [stateName + "State"]: "success"
          });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            [stateName + "ErrMsg"]: login_user_name[stateName]
          });
        }
        break;
      case "loginpassword":
        var password_login_validation = ProfileValidation.validatePassword(
          event.target.value,
          stateName
        );
        if (!password_login_validation[stateName]) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            [stateName + "ErrMsg"]: password_login_validation[stateName]
          });
        }
        break;
      default:
        break;
    }

    this.setState({ [stateName]: event.target.value });
  }
  //function to handle user signin
  handleClickLogin = async event => {
    event.preventDefault();
    if (!this.enableLogin()) {
      var user_data = {};
      this.setState({ isLoading: true, loginWarningAlert: false });
      if (
        this.state.login_username !== "" &&
        this.state.login_usernameState !== "error" &&
        this.state.login_password !== "" &&
        this.state.login_passwordState !== "error"
      ) {
        try {
          const signin_payload = {
            username: this.state.login_username,
            password: sha256(this.state.login_password)
          };
          var headers = {
            "Content-Type": "application/json"
          };
          axios
            .post(config.Cognito.signin_url, signin_payload, {
              headers: headers
            })
            .then(response => {
              if (response.data.data === null) {
                if (response.data.errors.error_code === "GBECO1007") {
                  this.setState({
                    isLoading: false,
                    errorMessage:
                      "You are not verified your OTP. Please wait 24 hrs to make registration. Use new Username and EmailId for immediate registration "
                  });
                } else {
                  localStorage.setItem("userlogged", false);
                  this.setState({
                    isLoading: false,
                    errorMessage: response.data.errors.error_message
                  });
                }
                this.setState({ loginWarningAlert: true });
              } else {
                user_data = {
                  accessToken: response.data.data.AccessToken,
                  idToken: response.data.data.IdToken,
                  refreshToken: response.data.data.RefreshToken,
                  username: this.state.login_username,
                  name: response.data.data.Name,
                  email: response.data.data.Email,
                  aadhar_verified: response.data.data.aadhar_verified
                };
                localStorage.setItem(
                  "accessToken",
                  response.data.data.AccessToken
                );
                localStorage.setItem("name", response.data.data.Name);
                localStorage.setItem("useremail", response.data.data.Email);
                localStorage.setItem(
                  "username",
                  this.state.login_username.toLowerCase()
                );
                localStorage.setItem(
                  "aadhar_verified",
                  response.data.data.aadhar_verified
                );
                this.props.getuserdata(user_data);
                this.autoCloseAlert(response.data.data.Name);
                localStorage.setItem("userlogged", true);
                this.setState({ isLoading: false });
              }
            })
            .catch(err => {
              localStorage.setItem("userlogged", false);
              this.setState({
                isLoading: false,
                loginWarningAlert: true,
                errorMessage:
                  "We experiencing difficulties with connectivity of the application, Please try again later"
              });
            });
        } catch (e) {
          this.setState({
            isLoading: false,
            loginWarningAlert: true,
            errorMessage:
              "We experiencing difficulties with connectivity of the application, Please try again later"
          });
        }
      } else {
        if (
          this.state.login_password === "" ||
          this.state.login_passwordState === "error"
        ) {
          this.setState({
            login_passwordState: "error"
          });
        }
        if (
          this.state.login_username === "" ||
          this.state.login_usernameState === "error"
        ) {
          this.setState({
            login_usernameState: "error"
          });
        }
      }
    } else {
      if (this.state.login_username === "") {
        this.setState({
          login_usernameState: "error",
          login_usernameErrMsg: "Username is required"
        });
      }
      if (this.state.login_password === "") {
        this.setState({
          login_passwordState: "error",
          login_passwordErrMsg: "Password is required"
        });
      }
    }
  };

  enableLogin() {
    if (
      this.state.login_usernameState === "success" &&
      this.state.login_passwordState === "success"
    ) {
      return false;
    } else {
      return true;
    }
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        {this.state.alert}
        {this.state.loginWarningAlert ? (
          <WarningAlert
            confirmBtnText="Try Again"
            errorMessage={this.state.errorMessage}
            title="Failed"
          />
        ) : null}
        <GridContainer justify="space-between" className={classes.alignImage}>
          <Hidden smDown>
            <GridItem xs={12} sm={6} md={5}>
              <img
                display={{ xs: "none", md: "block" }}
                src={Robot}
                alt="..."
                className="img"
              />
            </GridItem>
          </Hidden>
          <GridItem xs={12} sm={6} md={5}>
            <form>
              <Card login className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="info"
                >
                  <div className={classes.socialLine}>
                    <p
                      style={{ fontSize: "1.3em" }}
                      className={classes.cardTitle}
                    >
                      Login
                    </p>
                  </div>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    success={this.state.login_usernameState === "success"}
                    error={this.state.login_usernameState === "error"}
                    id="login_username"
                    name="Login_username"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses
                    }}
                    helpText={
                      this.state.login_usernameState === "error"
                        ? this.state.login_usernameErrMsg
                        : null
                    }
                    inputProps={{
                      onChange: event =>
                        this.change_login(
                          event,
                          "login_username",
                          "loginusername",
                          3
                        ),
                      // onChange:this.handleInputChange,
                      type: "text",

                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          <Person className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      placeholder: "Username*"
                    }}
                  />
                  <CustomInput
                    success={this.state.login_passwordState === "success"}
                    error={this.state.login_passwordState === "error"}
                    id="login_userpassword"
                    name="Login_userpassword"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses
                    }}
                    helpText={
                      this.state.login_passwordState === "error"
                        ? this.state.login_passwordErrMsg
                        : null
                    }
                    inputProps={{
                      onChange: event =>
                        this.change_login(
                          event,
                          "login_password",
                          "loginpassword"
                        ),
                      // onChange:this.handleInputChange,
                      type: "password",
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          <Lock className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      placeholder: "Password*"
                    }}
                  />
                  <div className={classes.formCategory}>
                    <small>*</small> Required fields
                  </div>
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <GridContainer justify="center">
                    <div className={classes.textCenter}>
                      <GridItem xs={12} sm={12} md={12}>
                        <LoaderButton
                          id="login_button"
                          disabled={this.enableLogin()}
                          type="submit"
                          onClick={this.handleClickLogin}
                          isLoading={this.state.isLoading}
                          text="Let's GO"
                          loadingText="Logging in…"
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <br />
                        <p>
                          {" "}
                          <Link
                            to="/auth/ForgetPasswordPage"
                            className={classes.a}
                          >
                            {" "}
                            Forget Password
                          </Link>
                        </p>
                      </GridItem>
                      <GridItem xs={12} sm={10} md={12}>
                        <p>
                          Not Registered Yet?{" "}
                          <Link to="/auth/register" className={classes.a}>
                            Register Now
                          </Link>
                        </p>
                      </GridItem>
                    </div>
                  </GridContainer>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

// connect this component to the store
const mapStateToProps = state => {
  return {
    user: state.user
  };
};
//to store data into resdux store
const mapDispatchToProps = dispatch => {
  return {
    getuserdata: user => {
      dispatch({
        type: "GET_USER_DATA",
        payload: user
      });
    }
  };
};

const withloginPageStyle = withStyles(loginPageStyle)(LoginPage);

export default connect(mapStateToProps, mapDispatchToProps)(withloginPageStyle);
