import gql from 'graphql-tag';

const EmployeeProfileDocumentquery = gql`
	query EmploymentQuery($username: String!, $id: Int!, $current_time: String!) {
		showEmployment(username: $username, id: $id, current_time: $current_time) {
			Employment {
				username
				document_name
				file_id
				file_hash
				document_extension
				document_upload_type
				employee_id
				employer_name
				employer_mail_id
				organization_name
				joining_date
				end_date
				experience
				designation
				department
				uploaded_by
				uploaded_at
				modified_at
				approved_by
				approver_email
				verified_status
				__typename
			}
			__typename
		}
	}
`;

export default EmployeeProfileDocumentquery;
