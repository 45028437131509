import gql from 'graphql-tag';
const EmploymentQuery = gql`
	query EmploymentQuery($username: String!) {
		getMulEmployment(username: $username) {
			Employment {
				username
				document_name
				file_id
				file_hash
				document_extension
				document_upload_type
				employee_id
				employer_name
				employer_mail_id
				organization_name
				joining_date
				end_date
				experience
				designation
				department
				uploaded_by
				uploaded_at
				modified_at
				verified_status
				approved_by
				approver_email
				__typename
			}
			__typename
		}
	}
`;
export default EmploymentQuery;
