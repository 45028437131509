import {
  cardTitle,
  warningColor,
  infoColor
} from "assets/jss/material-dashboard-pro-react.jsx";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";

const IconStyles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  viewiconbutton: {
    color: infoColor,
    padding: "0px",
    backgroundColor: "#ffffff00",
    "&:hover": {
      backgroundColor: "#eeeeee"
    }
  },
  editiconbutton: {
    color: warningColor,
    padding: "0px",
    backgroundColor: "#ffffff00",
    "&:hover": {
      backgroundColor: "#eeeeee"
    }
  },
  ...buttonStyle
};
export default IconStyles;
