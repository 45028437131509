import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
//@material ui components
import Dialog from "@material-ui/core/Dialog";
import Tooltip from "@material-ui/core/Tooltip";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
//@material ui icon
import LockOpen from "@material-ui/icons/LockOpen";
import LockOutlined from "@material-ui/icons/LockOutlined";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
//components
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";

//Custom components
import CustomInput from "Custom-component/CustomInput/CustomInput.jsx";
import LoaderButton from "Custom-component/LoaderButton";
import config from "../config.js"; //Configuration files
//styles
import changepasswordStyle from "./changepwdStyle.jsx";
//file import
import ProfileValidation from "../Container/Validation/ProfileValidation.jsx";
import WarningAlert from "../Container/WarningAlert.jsx";
import history from "../history.js";

var sha256 = require("js-sha256"); //encryption files

class Changepassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldpassword: "",
      oldpasswordState: "",
      newpassword: "",
      newpasswordconfirm: "",
      newpasswordState: "",
      newpasswordconfirmState: "",
      hidepassword_confirm: false,
      hidepassword: false,
      alert: null,
      showChangepasswordModal: false,
      closemodal: false,
      errorMessage: "",
      newpasswordErrMsg: "",
      oldpasswordErrMsg: "",
      newpasswordconfirmErrMsg: "",
      WarningAlertPassword: false,
      isLoading: false
    };
  }
  // modal close button onclick function
  handleClose = () => {
    this.setState({
      alert: null,
      showChangepasswordModal: false
    });
    this.props.close(this.state.closemodal);
  };

  // onchange function for input fields
  change_password(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "password_change_old":
        var old_password_validation = ProfileValidation.validatePassword(
          event.target.value,
          stateName
        );
        if (!old_password_validation[stateName]) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            [stateName + "ErrMsg"]: old_password_validation[stateName]
          });
        }
        break;
      case "password_change_new":
        if (event.target.value) {
          var changepassword_validation = ProfileValidation.passwordValidateCompare(
            event.target.value,
            stateName,
            this.state.newpasswordconfirm
          );
          if (changepassword_validation === "error") {
            this.setState({
              [stateName + "State"]: "error",
              newpasswordErrMsg:
                "Password should contains at least one capital letter,small letter,integer,special character and length should greater than 8"
            });
          } else {
            if (changepassword_validation === "success_compare") {
              this.setState({
                [stateName + "confirmState"]: "success"
              });
            } else if (changepassword_validation === "error_compare") {
              this.setState({
                [stateName + "confirmState"]: "error"
              });
            }
            this.setState({
              [stateName + "State"]: "success"
            });
          }
        } else {
          this.setState({ newpasswordErrMsg: "Password is required" });
        }
        break;
      case "equalTo_changepass":
        if (
          ProfileValidation.comparePassword(
            event.target.value,
            this.state.newpassword,
            stateName
          )
        ) {
          this.setState({
            [stateName + "State"]: "success"
          });
        } else {
          this.setState({
            [stateName + "State"]: "error"
          });
        }

        break;
      default:
        break;
    }

    this.setState({ [stateName]: event.target.value });
  }

  // to enable a change password button
  enablePassword = () => {
    if (
      this.state.oldpasswordState === "success" &&
      this.state.newpasswordState === "success" &&
      this.state.newpasswordconfirmState === "success"
    ) {
      this.handlechangepassword();
    } else {
      if (this.state.oldpassword === "") {
        this.setState({
          oldpasswordState: "error",
          oldpasswordErrMsg: "Old password is required"
        });
      }
      if (this.state.newpassword === "") {
        this.setState({
          newpasswordState: "error",
          newpasswordErrMsg: "New password is required"
        });
      }
      if (this.state.newpasswordconfirm === "") {
        this.setState({
          newpasswordconfirmState: "error",
          newpasswordconfirmErrMsg: "Confirm password is required"
        });
      }
    }
  };
  autoCloseAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px", color: "#000000" }}
          title="Password changed successfully"
          onConfirm={() => this.handleClose()}
          onCancel={() => this.handleClose()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
          <h4>Password changed successfully</h4>
        </SweetAlert>
      )
    });
  };

  // hide/show password
  hidepassword = () => {
    this.setState({ hidepassword: !this.state.hidepassword });
  };
  // hide/show password
  hidepassword_confirm = () => {
    this.setState({ hidepassword_confirm: !this.state.hidepassword_confirm });
  };

  // when button is enabled and onclicking the button
  handlechangepassword = async event => {
    this.setState({ WarningAlertPassword: false, isLoading: true });
    try {
      const changepassword_payload = {
        newpassword: sha256(this.state.newpassword),
        oldpassword: sha256(this.state.oldpassword)
      };
      var headers = {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("accessToken")
      };
      axios
        .post(config.Cognito.changepassword_url, changepassword_payload, {
          headers: headers
        })
        .then(response => {
          this.setState({ isLoading: false });
          if (response.data.data === null) {
            if (response.data.errors.error_code === "GBECO1013") {
              this.setState({
                errorMessage:
                  "Unable to change your password. Maximum limit exceed. Please try after some time"
              });
            } else {
              this.setState({
                errorMessage: response.data.errors.error_message,
                WarningAlertPassword: true
              });
            }
          } else {
            this.setState({
              shownewpasswordconfirm: false,
              oldpasswordState: "",
              newpasswordState: "",
              newpasswordconfirmState: ""
            });
            this.autoCloseAlert();
          }
        })
        .catch(err => {
          if (err.response.status === 401) {
            localStorage.clear();
            history.push("/auth/login");
          } else {
            this.setState({
              WarningAlertPassword: true,
              isLoading: false,
              errorMessage:
                "We experiencing difficulties with connectivity of the application, Please try again later"
            });
          }
        });
    } catch (e) {
      this.setState({
        isLoading: false,
        WarningAlertPassword: true,
        errorMessage:
          "We experiencing difficulties with connectivity of the application, Please try again later"
      });
    }
  };
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.wrapper}>
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal
          }}
          open={true}
          keepMounted
          onClose={() => this.handleClose()}
          aria-labelledby="notice-modal-slide-title"
          aria-describedby="notice-modal-slide-description"
        >
          <DialogTitle
            id="notice-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}>
              <b>Change Password</b>
            </h4>
          </DialogTitle>
          <DialogContent
            id="notice-modal-slide-description"
            className={classes.modalBody}
          >
            <CustomInput
              success={this.state.oldpasswordState === "success"}
              error={this.state.oldpasswordState === "error"}
              id="changepassword_oldpassword"
              name="changepassword_oldpassword"
              formControlProps={{
                fullWidth: true
              }}
              helpText={
                this.state.oldpasswordState === "error"
                  ? this.state.oldpasswordErrMsg
                  : null
              }
              inputProps={{
                placeholder: "Old Password*",
                type: "password",
                onChange: event =>
                  this.change_password(
                    event,
                    "oldpassword",
                    "password_change_old",
                    3
                  ),
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className={classes.inputAdornment}
                  >
                    <LockOpen
                      className={classes.inputAdornmentIcon}
                      style={{
                        color: "#FFC90D"
                      }}
                    />
                  </InputAdornment>
                )
              }}
            />
            <CustomInput
              success={this.state.newpasswordState === "success"}
              error={this.state.newpasswordState === "error"}
              id="changepassword_newpassword"
              name="changepassword_newpassword"
              formControlProps={{
                fullWidth: true
              }}
              helpText={
                this.state.newpasswordState === "error"
                  ? this.state.newpasswordErrMsg
                  : null
              }
              inputProps={{
                placeholder: "New Password*",
                onChange: event =>
                  this.change_password(
                    event,
                    "newpassword",
                    "password_change_new",
                    3
                  ),

                type: this.state.hidepassword ? "text" : "password",
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className={classes.inputAdornment}
                  >
                    <LockOutlined
                      style={{
                        color: "#FFC90D"
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    style={{ marginRight: "-5px" }}
                    position="end"
                    className={classes.inputAdornment}
                  >
                    {this.state.hidepassword ? (
                      <Tooltip title="View">
                        <Visibility
                          className={classes.inputAdornmentIcon}
                          style={{
                            color: "#FFC90D",
                            cursor: "pointer"
                          }}
                          onClick={this.hidepassword}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Hide">
                        <VisibilityOff
                          className={classes.inputAdornmentIcon}
                          style={{
                            color: "#FFC90D",
                            cursor: "pointer"
                          }}
                          onClick={this.hidepassword}
                        />
                      </Tooltip>
                    )}
                  </InputAdornment>
                )
              }}
            />

            <CustomInput
              success={this.state.newpasswordconfirmState === "success"}
              error={this.state.newpasswordconfirmState === "error"}
              id="changepassword_newpasswordconfirm"
              name="changepassword_newpasswordconfirm"
              formControlProps={{
                fullWidth: true
              }}
              helpText={
                this.state.newpasswordconfirmState === "error"
                  ? "Please provide same password"
                  : null
              }
              inputProps={{
                placeholder: "Confirm Password*",
                type: this.state.hidepassword_confirm ? "text" : "password",
                onChange: event =>
                  this.change_password(
                    event,
                    "newpasswordconfirm",
                    "equalTo_changepass",
                    "newpassword"
                  ),
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className={classes.inputAdornment}
                  >
                    <LockOutlined
                      style={{
                        color: "#FFC90D"
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    style={{ marginRight: "-5px" }}
                    position="end"
                    className={classes.inputAdornment}
                  >
                    {this.state.hidepassword_confirm ? (
                      <Tooltip title="View">
                        <Visibility
                          className={classes.inputAdornmentIcon}
                          style={{
                            color: "#FFC90D",
                            cursor: "pointer"
                          }}
                          onClick={this.hidepassword_confirm}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Hide">
                        <VisibilityOff
                          className={classes.inputAdornmentIcon}
                          style={{
                            color: "#FFC90D",
                            cursor: "pointer"
                          }}
                          onClick={this.hidepassword_confirm}
                        />
                      </Tooltip>
                    )}
                  </InputAdornment>
                )
              }}
            />
          </DialogContent>
          <DialogActions>
            <GridContainer justify="flex-start">
              <Button onClick={() => this.handleClose()}>Close</Button>
            </GridContainer>
            <GridContainer justify="flex-end">
              {/* <Button onClick={() => this.enablePassword()} color='info' round>
                Change Password
              </Button> */}
              <LoaderButton
                type="submit"
                onClick={() => this.enablePassword()}
                isLoading={this.state.isLoading}
                text="Change Password"
                loadingText="Changing..."
              />
            </GridContainer>
          </DialogActions>
        </Dialog>
        {this.state.alert}
        {this.state.WarningAlertPassword ? (
          <WarningAlert
            confirmBtnText="Try Again"
            errorMessage={this.state.errorMessage}
            title="Failed"
          />
        ) : null}
      </div>
    );
  }
}

Changepassword.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(changepasswordStyle)(Changepassword);
