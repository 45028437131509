import React from "react";
import { Query } from "react-apollo";
import { Redirect } from "react-router-dom";
//components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//File imports
import Profilepage from "./Profilepage.jsx";
import Errorpage from "../Pages/errorPage.jsx";
//queries
import EmployerProfilequery from "../../Graphql/EmployerProfilequery";
import EmployeeCountQuery from "../../Graphql/NumberofdocandemployeeQuery.js";
import ScreenLoading from "../LoadingScreen.jsx";

//Employer Profile query
const Profilequery = ({ username = localStorage.getItem("username") }) => (
  <Query query={EmployerProfilequery} variables={{ username }}>
    {({ loading: loadingOne, data: { myProfile }, error, client }) => (
      <Query query={EmployeeCountQuery} variables={{ username }}>
        {({
          loading: loadingTwo,
          data: { currentEmployeesCount, getApprovedCount },
          error
        }) => {
          if (loadingOne || loadingTwo) {
            return (
              <GridContainer justify="center">
                <GridItem xs={10} sm={8} md={8}>
                  <ScreenLoading />
                </GridItem>
              </GridContainer>
            );
          }
          // To show error page on failure to retrieve data from backend

          if (error) {
            return (
              <div>
                <Errorpage />
              </div>
            );
          }
          return localStorage.getItem("aadhar_verified") === "0" ? ( // redirect to new user page when the user is new
            <Redirect to="/identityverify" />
          ) : (
            <div>
              <Profilepage
                key={myProfile.username}
                user={myProfile}
                getempCount={currentEmployeesCount}
                getdocCount={getApprovedCount}
                // history={this.props.history}
              />
            </div>
          );
        }}
      </Query>
    )}
  </Query>
);

export default Profilequery;
