import dashboardStyle from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";

const dashboardstyle = {
  ...dashboardStyle,
  card: {
    maxWidth: 345,
    margin: "15px",
    boxShadow:
      "0 12px 20px -10px rgba(255, 201, 13, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 201, 13, 0.2)"
  },
  cardIconTitle: {
    color: "#ffffff !important",
    marginTop: "15px",
    marginBottom: "0px"
  },
  center: {
    textAlign: "center !important"
  },
  justifyContentCenter: {
    justifyContent: "center"
  },
  title: {
    color: "#3C4858",

    textDecoration: "none",
    fontWeight: "300",
    marginTop: "30px",
    marginBottom: "25px",
    minHeight: "32px",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },

  cardTitle: {
    textAlign: "center",
    marginTop: "15px",
    marginBottom: "3px",
    minHeight: "auto",
    "& a": {
      marginTop: ".625rem",
      marginBottom: "0.75rem",
      minHeight: "auto"
    }
  },
  infoArea: {
    maxWidth: "560px !important",
    margin: "0 auto",
    padding: "0px"
  },
  updateProfileButton: {
    float: "right"
  },
  backProfileButton: {
    float: "left"
  },
  successicons: {
    color: "green",
    fontSize: "3em"
  },
  rejecticons: {
    color: "red",
    fontSize: "4em"
  },
  retryicons: {
    color: "#fff"
  },
  dashboardrowContainer: {
    flexDirection: "row",
    flexWrap: "wrap",

    alignItems: "stretch",
    "@media (min-width: 900px)": {
      display: "flex",
      justifyContent: "space-between"
    }
  },
  statscard: {
    flex: 1,
    marginRight: "30px",
    flexBasis: "20%",
    "@media (max-width: 1200px)": {
      flexBasis: "40%"
    },
    "@media (max-width: 600px)": {
      flexBasis: "90%"
    }
  }
};
export default dashboardstyle;
