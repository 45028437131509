import gql from 'graphql-tag';

const DocumentCountQuery = gql`
	query countQuery($username: String!) {
		getApprovedCount(username: $username) {
			count
			__typename
		}
		getPendingCount(username: $username) {
			count
			__typename
		}
		getRejectedCount(username: $username) {
			count
			__typename
		}
		getTotalCount(username: $username) {
			count
			__typename
		}
		__typename
	}
`;
export default DocumentCountQuery;
