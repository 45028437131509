import gql from "graphql-tag";

const OnboaredEmployeeListQuery = gql`
  query OnBoardQuery($username: String!) {
    onBoardList(username: $username) {
      onboardList {
        employee_name
        employee_id
        aadhar_number
        document_status
        is_onboard
      }
    }
  }
`;
export default OnboaredEmployeeListQuery;
