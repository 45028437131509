import React from "react";
import MUIDataTable from "mui-datatables";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
//Styles
import IconStyles from "../Custom-Styles/GridIconStyle";
import Mytheme from "../Custom-Styles/CustomMuiTheme";
import ErrorPage from "../Pages/errorPage.jsx";
// Date formater
var dateFormat = require("dateformat");
class EmployerActivityList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employerlog_tabledata: [],
      showemployer_activity_log: true,
      hashError: false
    };
  }

  //handling error in components
  componentDidCatch(error, info) {
    //if (greater than the total available page length) error occur we redirecting it to starting page
    if (
      error.toString().includes("greater than the total available page length")
    ) {
      this.setState({ page: 0 });
    } else {
      this.setState({ hashError: true });
    }
  }
  componentDidMount() {
    var employerlog_tabledata = [];
    var self = this;
    var employer_log = this.props.EmployerActivity.logs;
    //for grid view element should be in same order column and the data pushed here should be same count.
    Object.keys(employer_log).forEach(function(key) {
      employerlog_tabledata.push([
        employer_log[key].module_name,
        employer_log[key].operation,
        employer_log[key].timestamp === null
          ? "-"
          : dateFormat(
              new Date(employer_log[key].timestamp),
              "dd/mm/yyyy HH:MM:ss"
            ),
        employer_log[key].browser === null ? "-" : employer_log[key].browser,
        employer_log[key].ip === null ? "-" : employer_log[key].ip
      ]);
    });
    self.setState({
      employerlog_tabledata: employerlog_tabledata
    });
  }
  render() {
    const { classes } = this.props;
    const options = {
      filterType: "dropdown",
      responsive: "stacked",
      fixedHeader: true,
      selectableRows: false,
      page: this.state.page
    };
    const columns = [
      {
        name: "Module Name",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "Activity",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "Performed On",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "Browser",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "IP Address",
        options: {
          filter: true,
          sort: true
        }
      }
    ];
    if (this.state.hashError) {
      return (
        <div>
          <ErrorPage />
        </div>
      );
    } else {
      return (
        <GridContainer>
          <GridItem xs={12}>
            {this.state.showemployer_activity_log ? (
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <Icon>show_chart</Icon>
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Activity Log</h4>
                </CardHeader>
                <CardBody>
                  <MuiThemeProvider theme={Mytheme}>
                    <MUIDataTable
                      title={""}
                      data={this.state.employerlog_tabledata}
                      columns={columns}
                      options={options}
                    />
                  </MuiThemeProvider>
                </CardBody>
              </Card>
            ) : null}
          </GridItem>
        </GridContainer>
      );
    }
  }
}

export default withStyles(IconStyles)(EmployerActivityList);
