import React from "react";
import Datetime from "react-datetime";
import { connect } from "react-redux";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Link from "@material-ui/icons/Link";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormHelperText from "@material-ui/core/FormHelperText";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//custom components
import CustomInput from "Custom-component/CustomInput/CustomInput.jsx";
import InfoArea from "Custom-component/InfoArea/InfoArea.jsx";
import step1style from "./step1style.jsx";
//file import
import validation from "../../Validation/ProfileValidation";
//styles
import "./phonestyle.css";

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //employer personal data
      personal: {
        employer_fullname: "",
        employer_gender: "Male",
        employer_dob: "",
        employer_dob_display: ""
      },
      //employer field validation
      employer_fullnameState: "",
      employer_dobState: "",
      nameErrormessage: ""
    };
  }

  //default in wizard(sending props)
  sendState() {
    return this.state;
  }

  //changing date field
  changeDateRegister = moment => {
    try {
      if (this.getValidatesRegister(moment)) {
        this.setState({
          personal: {
            ...this.state.personal,
            employer_dob: moment.format("YYYY-MM-DD"),
            employer_dob_display: moment.format("DD/MM/YYYY")
          },
          employer_dobState: "success"
        });
      } else {
        this.setState({ employer_dobState: "error" });
      }
    } catch (err) {
      this.setState({ employer_dobState: "error" });
    }
  };
  //enabling the end dates..
  getValidatesRegister(currentDate) {
    //1995 to 2005 valid date of birth...next yer it will increase automatically
    var currdate = 1887 + new Date().getYear() + "-01-01";
    var mindate = 1836 + new Date().getYear() + "-01-01";
    return currentDate.isBefore(currdate) && currentDate.isAfter(mindate);
  }
  //change employer gender
  handleChangeEnabledRegister = event => {
    this.setState({
      personal: {
        ...this.state.personal,
        employer_gender: event.target.value
      }
    });
  };

  // function for input validation
  change_fullname_register(event, stateName, type, stateNameEqualTo) {
    var value = event.target.value;
    switch (type) {
      case "fullname":
        var validateLength = validation.validateFullName(
          value,
          stateName,
          stateNameEqualTo
        );
        if (!validateLength[stateName]) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            nameErrormessage: validateLength[stateName]
          });
        }
        break;
      default:
        break;
    }
    this.setState({
      personal: {
        ...this.state.personal,
        [stateName]: value
      }
    });
  }

  //onclick validation function
  isValidated() {
    if (
      this.state.employer_fullnameState === "success" &&
      this.state.personal.employer_dob !== "" &&
      this.state.employer_gender !== ""
    ) {
      this.props.getPersonaldata(this.state.personal); //  dispatch data to redux
      return true;
    } else {
      if (this.state.personal.employer_fullname === "") {
        this.setState({
          employer_fullnameState: "error",
          nameErrormessage: "Name is required"
        });
      }
      if (this.state.employer_dobState !== "success") {
        this.setState({ employer_dobState: "error" });
      }
      return false;
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={10} lg={10}>
            <h4 className={classes.infoText}>Enter your personal details</h4>
          </GridItem>
          {/* Instructions to employer */}
          <GridItem xs={12} sm={5} lg={5}>
            <InfoArea
              title="Profile data"
              description="Your profile is the outline of your face as it is seen when someone is looking at you."
              icon={Link}
              iconColor="info"
            />
            <InfoArea
              title="Privacy"
              description="Your privacy is of the highest importance to us, and we promise never to release your personal details to any outside without your knowledge"
              icon={Link}
              iconColor="info"
            />
          </GridItem>
          {/*  Employer account details page  */}
          <GridItem xs={12} sm={5} lg={5}>
            <CustomInput
              success={this.state.employer_fullnameState === "success"}
              error={this.state.employer_fullnameState === "error"}
              id="Register_employer_fullname"
              name="Register_employer_fullname"
              formControlProps={{
                fullWidth: true
              }}
              helpText={
                this.state.employer_fullnameState === "error"
                  ? this.state.nameErrormessage
                  : null
              }
              inputProps={{
                placeholder: "Full Name*",
                onChange: event =>
                  this.change_fullname_register(
                    event,
                    "employer_fullname",
                    "fullname",
                    3
                  ),
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className={classes.inputAdornment}
                  >
                    <Person className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                )
              }}
            />
            <InputLabel
              className={classes.label}
              style={
                this.state.employer_dobState === "error"
                  ? { color: "#f44336" }
                  : null
              }
            >
              Date of Birth*
            </InputLabel>
            <br />
            <FormControl fullWidth>
              <Datetime
                dateFormat="DD/MM/YYYY"
                value={this.state.employer_dob_display}
                timeFormat={false}
                isValidDate={this.getValidatesRegister.bind(this)}
                id="Register_employer_dob"
                name="Register_employer_dob"
                viewDate={"12/12/" + (1886 + new Date().getYear())}
                onChange={this.changeDateRegister.bind(this)}
                closeOnSelect={true}
                inputProps={{
                  placeholder: "Date of Birth"
                }}
              />
              {this.state.employer_dobState === "error" ? (
                <FormHelperText
                  style={{
                    color: "#d50000",
                    fontSize: "0.8rem",
                    lineHeight: "1.3em",
                    fontWeight: "bolder"
                  }}
                  error
                >
                  Please provide valide date of birth
                </FormHelperText>
              ) : null}
            </FormControl>
            <GridContainer justify="flex-start">
              <FormLabel
                className={classes.labelHorizontal}
                style={{ paddingLeft: "10px" }}
              >
                Gender*
              </FormLabel>
            </GridContainer>
            <GridContainer justify="flex-start">
              <GridItem xs={12} sm={4} lg={4}>
                <div className={classes.checkboxAndRadio}>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={this.state.personal.employer_gender === "Male"}
                        onChange={this.handleChangeEnabledRegister}
                        value="Male"
                        name="radio button enabled"
                        aria-label="A"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio
                        }}
                      />
                    }
                    classes={{
                      label: classes.label
                    }}
                    label="Male"
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={4} lg={4}>
                <div className={classes.checkboxAndRadio}>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={
                          this.state.personal.employer_gender === "Female"
                        }
                        onChange={this.handleChangeEnabledRegister}
                        value="Female"
                        name="radio button enabled"
                        aria-label="B"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio
                        }}
                      />
                    }
                    classes={{
                      label: classes.label
                    }}
                    label="Female"
                  />
                </div>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

//connect this component to the store
const mapStateToProps = state => {
  return {
    employers: state.employers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPersonaldata: personal => {
      dispatch({
        type: "GET_PERSONAL_DATA",
        payload: personal
      });
    }
  };
};

const withstylepersonal = withStyles(step1style)(Step1);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withstylepersonal);
