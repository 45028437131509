import LoginPage from "Container/Auth/LoginPage.jsx";
import RegisterPage from "Container/Auth/Registerpage.jsx";
import ForgetPasswordPage from "Container/Auth/Forgetpwd.jsx";
import LockScreen from "Container/Auth/LockScreen.jsx";
// @material-ui/icons
import PersonAdd from "@material-ui/icons/PersonAdd";
import Fingerprint from "@material-ui/icons/Fingerprint";
import LockOpen from "@material-ui/icons/LockOpen";

const unauthenticatedroutes = [
  {
    path: "/auth/register",
    name: "Register Page",
    short: "Register",
    mini: "RP",
    icon: PersonAdd,
    component: RegisterPage
  },
  {
    path: "/auth/login",
    name: "Login Page",
    short: "Login",
    mini: "LP",
    icon: Fingerprint,
    component: LoginPage
  },
  {
    path: "/auth/lockscreen",
    name: "LockScreen",
    short: "Lock",
    mini: "LS",
    icon: Fingerprint,
    component: LockScreen
  },

  {
    path: "/auth/ForgetPasswordPage",
    name: "Forget Password Page",
    short: "Forget Password",
    mini: "FP",
    icon: LockOpen,
    component: ForgetPasswordPage
  },
  {
    redirect: true,
    path: "/auth",
    pathTo: "/auth/login",
    name: "Login Page"
  }
];

export default unauthenticatedroutes;
