import React, { Fragment, Suspense } from "react";
import MUIDataTable from "mui-datatables";
import { ApolloConsumer } from "react-apollo";
import SweetAlert from "react-bootstrap-sweetalert";
import Tour from "reactour";
import { getGlobal, resetGlobal } from "reactn";
//@materil ui components
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon"; //* Material ui Icon
import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/core/styles/withStyles";
import { MuiThemeProvider } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// Styles component
import Mytheme from "../Custom-Styles/CustomMuiTheme";
import {
  cardTitle,
  warningColor,
  infoColor
} from "assets/jss/material-dashboard-pro-react.jsx";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import ScreenLoading from "../LoadingScreen.jsx";
//queries and mutation
import CommentRetrieveQuery from "../../Graphql/CommentRetrieveQuery";
import BlockchaindetailsQuery from "../../Graphql/blockchaindetailsQuery";
//file import
import ErrorPage from "../Pages/errorPage.jsx";
// Date formater
var dateFormat = require("dateformat");
const Requestviewpage = React.lazy(() => import("./Requestviewpage.jsx"));
const Requestapproval = React.lazy(() => import("./Requestapproval.jsx"));

const styles = {
  ...buttonStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  CardCard: {
    color: "rgba(0, 0, 0, 0.87)",
    // width: '100%',
    border: "0",
    display: "flex",
    position: "relative",
    wordWrap: " break-word",
    minWidth: 0,
    fontSize: ".875rem",
    background: "#fff",

    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    marginBottom: "30px",
    paddingBottom: "20px",
    borderRadius: "6px"
  },
  approveicon: {
    color: "green"
    // backgroundColor:'#33DDFF',
  },
  rejecticon: {
    color: "red"
    // backgroundColor:'#33DDFF',
  },
  pendingicon: {
    color: "red"
  },
  viewiconbutton: {
    color: infoColor,
    padding: "0px",
    backgroundColor: "#ffffff00",
    "&:hover": {
      backgroundColor: "#eeeeee"
    }
  },
  editiconbutton: {
    color: warningColor,
    padding: "0px",
    backgroundColor: "#ffffff00",
    "&:hover": {
      backgroundColor: "#eeeeee"
    }
  }
};
class ViewRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showrequested_doc_2_approve: false,
      showrequested_documentlist_grid: true,
      showdocument_2_view: false,
      tabledata: [],
      employee_employment_data: [],
      loadingalert: false,
      employer_comments: [],
      document_requested_status:
        this.props.document_requested_status !== undefined
          ? this.props.document_requested_status
          : "Pending",
      // react tour variables
      help: false,
      steps: [
        {
          content: "All the documents uploaded by employee are listed here",
          position: "bottom",
          selector: ".tableClass"
        },
        {
          content: () => (
            <Fragment>
              <b> View document Details</b>
              <br />
              You can view the employee document details.
            </Fragment>
          ),
          position: "top",
          selector: ".viewDocumentClass"
        },
        {
          content: () => (
            <Fragment>
              <b> Edit document request</b>
              <br />
              On clicking Edit icon you can either approve or reject the
              document
            </Fragment>
          ),
          position: "top",
          selector: ".editDocumentClass"
        }
      ], // Steps to display in tour
      blockchaindetails_response: "",
      loadingalert_error: false,
      hashError: false
    };
  }

  // function to close the tour
  closeTour = () => {
    resetGlobal();
    this.setState({ prevhelp: true, help: false });
  };
  handleUpdate = employment => {
    var tabledata = [];
    var self = this;
    const { client, classes } = this.props;
    // * data to show in grid
    // ! NOTE: Dont change the order, column name order and this data values order should be same
    Object.keys(employment).forEach(function(key) {
      tabledata.push([
        employment[key].organization_name, // future: need to change as employee name
        employment[key].employee_id,
        dateFormat(new Date(employment[key].joining_date), "dd/mm/yyyy"),
        employment[key].end_date !== null &&
        employment[key].end_date !== "0000-00-00"
          ? dateFormat(new Date(employment[key].end_date), "dd/mm/yyyy")
          : "-",
        employment[key].experience !== null ? employment[key].experience : "-",
        employment[key].department,
        employment[key].designation,
        employment[key].verified_status,

        // we've added some custom button actions for view ,edit(approval) and download
        <div className="actions-right">
          {/* button to view document details */}
          <IconButton
            aria-label="Download"
            className={classes.viewiconbutton}
            onClick={async () => {
              self.setState({
                help: false,
                loadingalert: true,
                loadingalert_error: false,
                showrequested_documentlist_grid: false
              });
              employment[key].verified_status !== "Pending"
                ? await client
                    .query({
                      query: CommentRetrieveQuery,
                      variables: {
                        username: employment[key].username,
                        file_hash: employment[key].file_hash
                      }
                    })
                    .then(response => {
                      client
                        .query({
                          query: BlockchaindetailsQuery,
                          variables: {
                            file_hash: employment[key].file_hash
                          }
                        })
                        .then(res => {
                          self.setState({
                            loadingalert: false,
                            employee_employment_data: employment[key],
                            showrequested_documentlist_grid: false,
                            showrequested_doc_2_approve: false,
                            showdocument_2_view: true,
                            employer_comments: response,
                            blockchaindetails_response: res
                          });
                        })
                        .catch(e => {
                          self.setState({
                            showrequested_documentlist_grid: true,
                            loadingalert: false,
                            loadingalert_error: true
                          });
                        });
                    })
                    .catch(err => {
                      self.setState({
                        showrequested_documentlist_grid: true,
                        loadingalert: false,
                        loadingalert_error: true
                      });
                    })
                : self.setState({
                    loadingalert: false,
                    employee_employment_data: employment[key],
                    showrequested_documentlist_grid: false,
                    showrequested_doc_2_approve: false,
                    showdocument_2_view: true,
                    employer_comments: "No Comments found"
                  });
            }}
          >
            <Tooltip title="View">
              <Icon className="viewDocumentClass">visibility</Icon>
            </Tooltip>
          </IconButton>{" "}
          &nbsp; &nbsp;
          {/*  use this button to add a edit kind of action */}
          <IconButton
            aria-label="edit"
            className={classes.editiconbutton}
            disabled={employment[key].verified_status !== "Pending"}
            onClick={() => {
              self.setState({
                employee_employment_data: employment[key],
                showrequested_documentlist_grid: false,
                showrequested_doc_2_approve: true,
                showdocument_2_view: false,
                help: false
              });
            }}
          >
            <Tooltip title="Edit">
              <Icon
                className="editDocumentClass"
                style={
                  employment[key].verified_status !== "Pending"
                    ? { color: "#b7b3ae" }
                    : null
                }
              >
                edit
              </Icon>
            </Tooltip>
          </IconButton>{" "}
        </div>
      ]);
    });
    self.setState({
      tabledata: tabledata
    });
  };
  componentDidMount() {
    // to display react tour for new user
    this.setState({
      help: JSON.parse(localStorage.getItem("isNewUser")),
      prevhelp: JSON.parse(localStorage.getItem("isNewUser"))
    });
    this.handleUpdate(this.props.Employment.Employment);
  }

  componentDidUpdate(props, state) {
    if (state.tabledata.length) {
      var employee_request = props.Employment.Employment;
      var changed = false;
      Object.keys(employee_request).forEach(function(key) {
        if (employee_request[key].verified_status !== state.tabledata[key][7]) {
          changed = true;
          if (JSON.parse(localStorage.getItem("isNewUser")) === true) {
            localStorage.setItem("isNewUser", false);
          }
        }
      });
    }
    if (changed) {
      this.handleUpdate(props.Employment.Employment);
    }
  }
  //handling error in components
  componentDidCatch(error, info) {
    //if (greater than the total available page length) error occur we redirecting it to starting page
    if (
      error.toString().includes("greater than the total available page length")
    ) {
      this.setState({ page: 0 });
    } else {
      this.setState({ hashError: true });
    }
  }
  //callback func To show grid from other page
  handleBack = () => {
    resetGlobal();
    this.setState({
      showrequested_doc_2_approve: false,
      showrequested_documentlist_grid: true,
      showdocument_2_view: false,
      prevhelp: false
    });
  };
  // to show doc request approval page
  showrequestapprovalpage = () => {
    this.setState({
      showrequested_documentlist_grid: false,
      showrequested_doc_2_approve: true
    });
  };

  render() {
    const { classes } = this.props;
    const { steps } = this.state;

    const options = {
      filterType: "dropdown",
      responsive: "stacked",
      fixedHeader: true,
      selectableRows: false,
      page: this.state.page
    };
    const columns = [
      {
        name: "Organization Name",
        options: {
          filter: true,
          sort: false
        }
      },
      {
        name: "Employee Id",
        options: {
          filter: true,
          sort: false
        }
      },
      {
        name: "DOJ",
        options: {
          filter: true,
          sort: false
        }
      },
      {
        name: "DOE",
        options: {
          filter: true,
          sort: false
        }
      },
      {
        name: "Experience",
        options: {
          filter: true,
          sort: false,
          display: false
        }
      },
      {
        name: "Department",
        options: {
          filter: true,
          sort: false,
          display: false
        }
      },
      {
        name: "Designation",
        options: {
          filter: true,
          sort: false,
          display: false
        }
      },

      {
        name: "Verified Status",
        options: {
          filter: true,
          sort: false,
          display: true,
          filterList:
            this.state.document_requested_status === "-"
              ? ["Pending", "Approved", "Rejected"]
              : [this.state.document_requested_status]
        }
      },
      {
        name: "Action",
        options: {
          filter: false,
          sort: false,
          download: false
        }
      }
    ];
    if (this.state.hashError) {
      return (
        <div>
          <ErrorPage />
        </div>
      );
    } else {
      return (
        <div>
          <GridContainer>
            <GridItem xs={12}>
              {this.state.showrequested_documentlist_grid ? (
                <Card className={classes.CardCard}>
                  {/* react tour */}
                  <Tour
                    onRequestClose={this.closeTour}
                    steps={steps}
                    isOpen={
                      getGlobal().value ? true : this.state.help ? true : false
                    }
                    accentColor="#FFC90D"
                  />
                  <CardHeader color="info" icon>
                    <CardIcon color="info" className="tableClass">
                      <Icon>assignment</Icon>
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                      Verification Requests
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <MuiThemeProvider theme={Mytheme}>
                      <MUIDataTable
                        title={""}
                        data={this.state.tabledata}
                        columns={columns}
                        options={options}
                      />
                    </MuiThemeProvider>
                  </CardBody>
                </Card>
              ) : null}
              {this.state.showrequested_doc_2_approve ? (
                //Show employee document approval page
                <ApolloConsumer>
                  {client => (
                    <Suspense fallback={<ScreenLoading />}>
                      <Requestapproval
                        help={this.state.prevhelp}
                        handleback={this.handleBack}
                        employee_data={this.state.employee_employment_data}
                        employer_comments={this.state.employer_comments}
                        client={client}
                        history={this.props.history}
                      />
                    </Suspense>
                  )}
                </ApolloConsumer>
              ) : null}
              {this.state.showdocument_2_view ? (
                //show employee document request view page
                <Suspense fallback={<ScreenLoading />}>
                  <Requestviewpage
                    handleback={this.handleBack}
                    employee_data={this.state.employee_employment_data}
                    employer_comments={this.state.employer_comments}
                    blockchaindetails_response={
                      this.state.blockchaindetails_response
                    }
                  />
                </Suspense>
              ) : null}
            </GridItem>
          </GridContainer>
          {this.state.loadingalert ? <ScreenLoading /> : null}
          {this.state.loadingalert_error ? (
            <SweetAlert
              style={{
                display: "block",
                marginTop: "-100px",
                color: "#000000"
              }}
              title="Failed to fetch."
              onConfirm={() => this.setState({ loadingalert_error: false })}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.danger
              }
              showConfirm={true}
            >
              <h4>Please try Again.</h4>
            </SweetAlert>
          ) : null}
        </div>
      );
    }
  }
}

export default withStyles(styles)(ViewRequest);
