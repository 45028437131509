import {
  cardTitle,
  infoColor
} from "assets/jss/material-dashboard-pro-react.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

const userProfileStyles = {
  cardTitle,
  ...customCheckboxRadioSwitch,
  ...sweetAlertStyle,
  ...modalStyle,
  checkboxAndRadioHorizontal: {
    position: "relative",
    display: "block",
    "&:first-child": {
      marginTop: "15px"
    },
    "&:not(:first-child)": {
      marginTop: "-14px"
    },
    "@media (max-width: 300px)": {
      marginTop: "10px "
    },
    marginTop: "40px",
    marginBottom: "0"
  },
  labelHorizontalRadioCheckbox: {
    paddingTop: "20px important",
    float: "left !important"
  },
  urlbox: {
    border: "1px solid #FFC90D",
    borderRadius: "7px",
    height: "60px"
  },
  uniqueid_text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "block"
  },
  viewiconbutton: {
    color: "#ffffff",
    backgroundColor: infoColor,
    "&:hover ,&:focus": {
      color: "black"
    }
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    "& small": {
      fontSize: "80%",
      fontWeight: "400"
    }
  },
  cardSignup: {
    borderRadius: "6px",
    padding: "20px 0px",
    fontWeight: "400 !important"
  },
  cardBody: {
    textAlign: "left"
  },
  cardheader: {
    textAlign: "left",
    fontWeight: "400",
    marginLeft: "20px",
    fontSize: "1.3em",
    color: "#635a5a"
  },
  cardsideHeader: {
    color: "#1f1e1e",
    fontWeight: "400"
  },
  cardCategory: {
    marginTop: "10px",
    color: "#999999 !important",
    textAlign: "center"
  },
  description: {
    color: "#999999"
  },
  updateProfileButton: {
    float: "right"
  },
  backProfileButton: {
    float: "left"
  },
  inputAdornment: {
    marginRight: "18px",
    position: "relative"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  boldheading: {
    color: "#4c4949",
    fontWeight: "bolder"
  },
  mainheading: {
    color: "#4c4949",
    fontWeight: "bolder",
    textAlign: "center"
  },
  commentvalue: {
    display: "flex",
    textAlign: "justify"
  },
  modalCloseButton: {
    color: "#999999",
    float: "right",
    cursor: "pointer",
    border: 0,
    padding: 0,
    opacity: 0.9,
    fontSize: "inherit",
    marginTop: "-42px",
    background: "0 0",
    textShadow: "none",
    fontWeight: "700",
    lineHeight: "1"
  }
};
export default userProfileStyles;
