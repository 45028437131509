import React from "react";
import { ApolloConsumer } from "react-apollo";
import Requestquery from "./Requestquery"; //Retrieve Employee document approval request query

class ViewRequest extends React.Component {
	render() {
		return (
			//Apollo client instances
			<ApolloConsumer>
				{client => (
					<Requestquery
						history={this.props.history}
						client={client}
						document_requested_status={this.props.location.state}
					/>
				)}
			</ApolloConsumer>
		);
	}
}

export default ViewRequest;
