import React from "react";
import ReactLoaderQuotes from "react-loader-quotes";

function ScreenLoading() {
  return (
    <ReactLoaderQuotes
      loading={true}
      speed={1}
      quotes={[`Loading..`, `Please Wait..`]}
      backgroundColorLoader="#FFC90D"
      colorTitle="#000000"
      fontSizeTitle="25px"
    />
  );
}
export default ScreenLoading;
