import gql from 'graphql-tag';

const EmployerProfilequery = gql`
  query EmployerQuery($username: String!) {
    myProfile(username: $username) {
      username
      name
      aadhar_number
      aadhar_verified
      email
      email_verified
      phone_number
      personal_email
      personal_phone_number
      company_name
      company_address
      PAN
      DIN
      hyperledger_id
      gender
      birthdate
      profile_status
      reason
      created_at
      created_by
      updated_at
      updated_by
      profile_image_hash
      __typename
    }
    __typename
  }
`;

export default EmployerProfilequery;
