// input validator
var validator = require("validator");
var errors = {};
// function for name  validation
function validateFullName(value, stateName) {
  var validation = { alphapets: /^[a-zA-Z\s.-`']*$/ };
  if (validator.isEmpty(value.trim())) {
    errors[stateName] = "Name is required";
  } else if (!validation.alphapets.test(value.trim())) {
    errors[stateName] =
      "Only alphabets,special character include .(dot) are allowed";
  } else if (!validator.isLength(value.trim(), 3, 30)) {
    errors[stateName] = "Name must be 3 to 30 characters long";
  } else {
    errors = {};
  }
  return errors;
}
// function for duration field validation
function validateNumber(value, statename, rowLength, compareValue) {
  var numberRex = new RegExp("^[0-9]+$");
  if (validator.isEmpty(value)) {
    errors[statename] = statename + " is required";
  } else if (!numberRex.test(value)) {
    errors[statename] = "Only numbers are allowed";
  } else if (value < 1 || value > rowLength) {
    errors[statename] = "Please enter the value from 1 to " + rowLength;
  } else if (
    statename === "From" &&
    compareValue !== "" &&
    parseInt(value) > parseInt(compareValue)
  ) {
    errors[statename] = "From should be lesser than To value";
  } else if (
    statename === "To" &&
    compareValue !== "" &&
    parseInt(value) < parseInt(compareValue)
  ) {
    errors[statename] = "To should be greater than From value";
  } else {
    errors = {};
  }
  return errors;
}
// function for company address and name validation
function validateNameAddress(value, stateName) {
  var validation;
  if (stateName === "employer_company_address") {
    validation = { alphapetsnumeric: /^[a-zA-Z0-9\s,/()#*,^&+.'_-]*$/ };
    if (validator.isEmpty(value)) {
      errors[stateName] = "Address is required";
    } else if (!validation.alphapetsnumeric.test(value)) {
      errors[stateName] =
        "Only alphabets,numbers,special characters includes /*()^#,+&.'_- are allowed";
    } else if (!validator.isLength(value, 4, 150)) {
      errors[stateName] = "Address must be 4 to 150 characters long";
    } else {
      errors = {};
    }
    return errors;
  } else {
    validation = {
      alphapetsnumeric: /^(?!\s)(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s,()#*,@&+.'_-]*$/
    };
    if (validator.isEmpty(value)) {
      errors[stateName] = "Company name is required";
    } else if (!validation.alphapetsnumeric.test(value)) {
      errors[stateName] =
        "Only alphabets,numbers,special characters includes ()#*,-@&.'_+ are allowed";
    } else if (!validator.isLength(value, 3, 50)) {
      errors[stateName] = "Company name must be 3 to 50 characters long";
    } else {
      errors = {};
    }
    return errors;
  }
}

// function for comapany Din and Pan number validation
function validatePanDin(value, stateName) {
  if (stateName === "employer_pan_number") {
    // example:PAN: ABCDE1234F
    var regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if (validator.isEmpty(value)) {
      errors[stateName] = "PAN is required";
    } else if (!regpan.test(value)) {
      errors[stateName] = "Please provide valid PAN number";
    } else {
      errors = {};
    }
    return errors;
  } else {
    if (value) {
      var dinvalidation = /^([0-9])*$/;
      if (!dinvalidation.test(value)) {
        errors[stateName] = "Only numbers are allowed";
      } else if (!(value.length === 8)) {
        errors[stateName] = "DIN number must be 8 characters long";
      } else {
        errors = {};
      }
    } else {
      errors = {};
    }
    return errors;
  }
}

// function for email validation
function validateEmail(value, stateName) {
  if (stateName !== "employer_personal_mailid" && validator.isEmpty(value)) {
    errors[stateName] = "Email is required";
  } else if (!validator.isEmail(value)) {
    if (stateName === "employer_personal_mailid" && value === "") {
      errors = {};
    } else {
      errors[stateName] = "Invalid email address";
    }
  } else {
    errors = {};
  }
  return errors;
}

// function for username validation
function validateUsername(value, stateName) {
  var validation = { alphanumeric: /^([a-zA-Z])+[A-Za-z0-9-_]*$/ };
  if (validator.isEmpty(value)) {
    errors[stateName] = "Username is required";
  } else if (!validation.alphanumeric.test(value)) {
    errors[stateName] =
      "Only alphabets,numbers and some special characters including - (hyphen) _ (underscore) are allowed";
  } else if (!validator.isLength(value, 3, 30)) {
    errors[stateName] = "Username must be 3 to 30 characters long";
  } else {
    errors = {};
  }
  return errors;
}

// compare password and confirm password fields
function comparePassword(string1, string2, statename) {
  if (string1 === string2) {
    return true;
  }
  return false;
}
// password validation for new password
function passwordValidateCompare(value, statename, compare_value) {
  var passwordRegex = /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+-.])(?!.*\s)[0-9a-zA-Z!@#$%^&*()]*$/;
  var finalResult = passwordRegex.test(value);
  if (finalResult && compare_value && comparePassword(value, compare_value)) {
    return "success_compare";
  } else if (
    finalResult &&
    compare_value &&
    !comparePassword(value, compare_value)
  ) {
    return "error_compare";
  } else if (!finalResult) {
    return "error";
  } else {
    return "success";
  }
}
// function for OTP validation
function validateOTP(value, stateName) {
  var numberRex = new RegExp("^[0-9]+$");
  if (validator.isEmpty(value)) {
    errors[stateName] = "OTP is required";
  } else if (!numberRex.test(value)) {
    errors[stateName] = "Only numbers are allowed";
  } else if (!(value.length === 6)) {
    errors[stateName] = "OTP must be 6 characters long";
  } else {
    errors = {};
  }
  return errors;
}

// function for comment validation
function validateComment(value, stateName) {
  var validation = {
    alphapetsnumeric: /^([a-zA-Z])+[A-Za-z0-9!@#$%^&+-._ ]*$/
  };
  if (validator.isEmpty(value)) {
    errors = {};
  } else if (!validation.alphapetsnumeric.test(value)) {
    errors[stateName] =
      "Only alphabets,numbers,special characters includes !@#$%^&+-._ are allowed";
  } else if (!validator.isLength(value, 4, 250)) {
    errors[stateName] = "Comments must be 4 to 150 characters long";
  } else {
    errors = {};
  }
  return errors;
}

//function for employeeId validation
function validateEmployeeId(value, stateName) {
  var verifyalphanumericvalidation;
  verifyalphanumericvalidation = {
    alphanumeric: /^[A-Za-z0-9-_]*$/
  };
  if (validator.isEmpty(value)) {
    errors[stateName] = "Employee ID is required";
  } else if (!verifyalphanumericvalidation.alphanumeric.test(value)) {
    errors[stateName] =
      "Only alphabets,numbers and some special characters including -(hyphen) _(underscore) are allowed";
  } else if (!validator.isLength(value, 4, 15)) {
    errors[stateName] = "Employee ID must be 4 to 15 characters long";
  } else {
    errors = {};
  }
  return errors;
}

//validations for designation,department
function validateDepartment(value, stateName) {
  if (
    stateName === "Onboard_Employee_Designation" ||
    stateName === "ExitTrigger_Employee_currentDesignation"
  ) {
    var designationvalidation = {
      alphanumeric: /^([a-zA-Z])+[A-Za-z0-9-._ ]*$/
    };
    if (validator.isEmpty(value)) {
      errors[stateName] = "Designation is required";
    } else if (!designationvalidation.alphanumeric.test(value)) {
      errors[stateName] =
        "Only alphabets,numbers,special characters including - _ . are allowed";
    } else if (!validator.isLength(value, 4, 30)) {
      errors[stateName] = "Designation  must be 4 to 30 characters long";
    } else {
      errors = {};
    }
  } else {
    var Departmentvalidation = {
      alphanumeric: /^([a-zA-Z])+[A-Za-z0-9-._ ]*$/
    };
    if (validator.isEmpty(value)) {
      errors[stateName] = "Department is required";
    } else if (!Departmentvalidation.alphanumeric.test(value)) {
      errors[stateName] =
        "Only alphabets,numbers,special characters including - _ . are allowed";
    } else if (!validator.isLength(value, 2, 30)) {
      errors[stateName] = "Department name must be 2 to 30 characters long";
    } else {
      errors = {};
    }
  }
  return errors;
}

// function to validate AadharNumber
function validateAadhar(value, stateName) {
  var numberRex = new RegExp("^[0-9]+$");
  if (validator.isEmpty(value)) {
    errors[stateName] = "National identity number is required";
  } else if (!numberRex.test(value)) {
    errors[stateName] = "Only numbers are allowed";
  } else if (!(value.length === 12)) {
    errors[stateName] = "National identity number must be 12 characters long";
  } else {
    errors = {};
  }
  return errors;
}

// Validation for password
function validatePassword(value, statename) {
  var pattern = new RegExp(
    /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+-.])(?!.*\s)[0-9a-zA-Z!@#$%^&+-.]*$/
  );
  if (validator.isEmpty(value)) {
    errors[statename] = "Password is required";
  } else if (!pattern.test(value)) {
    errors[statename] =
      "Password should contains at least one capital letter,small letter,integer,special character and length should greater than 8";
  } else {
    errors = {};
  }
  return errors;
}
export default {
  validateFullName,
  validateNameAddress,
  validatePanDin,
  validateEmail,
  validateUsername,
  validateOTP,
  validateComment,
  validateEmployeeId,
  validateDepartment,
  validateAadhar,
  validatePassword,
  comparePassword,
  passwordValidateCompare,
  validateNumber
};
