import React from "react";
//material-ui icons
import HourglassEmpty from "@material-ui/icons/HourglassEmpty";
import Cancel from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Heading from "components/Heading/Heading.jsx";
import Timeline from "components/Timeline/Timeline.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ErrorPage from "../Pages/errorPage.jsx";
// Date formater
var dateFormat = require("dateformat");

class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hashError: false
    };
  }
  //handling error in components
  componentDidCatch(error, info) {
    this.setState({ hashError: true });
  }
  render() {
    var inverts = true;
    const history = this.props.Employerhistory.history;
    const stories = history.map((prop, key) => {
      inverts ? (inverts = false) : (inverts = true);
      return {
        // Change the icon color and icon based on the status
        inverted: inverts,
        badgeColor:
          prop.action === "Approved"
            ? "success"
            : prop.action === "Rejected"
            ? "danger"
            : "warning",
        badgeIcon:
          prop.action === "Approved"
            ? CheckCircle
            : prop.action === "Rejected"
            ? Cancel
            : HourglassEmpty,
        title: prop.action,
        titleColor:
          prop.action === "Approved"
            ? "success"
            : prop.action === "Rejected"
            ? "danger"
            : "warning",
        body: (
          <React.Fragment>
            <GridContainer>
              <GridItem xs={9} sm={5} md={4}>
                <b>Employee Name</b>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                :
              </GridItem>
              <GridItem xs={11} sm={6} md={4}>
                {prop.employee_name}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={9} sm={5} md={4}>
                <b>Employee Id</b>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                :
              </GridItem>
              <GridItem xs={11} sm={6} md={4}>
                {prop.employee_id}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={9} sm={5} md={4}>
                <b>Joining Date</b>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                :
              </GridItem>
              <GridItem xs={11} sm={6} md={4}>
                {dateFormat(new Date(prop.joining_date), "dd/mm/yyyy")}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={9} sm={5} md={4}>
                <b>Exit Date</b>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                :
              </GridItem>
              <GridItem xs={11} sm={6} md={4}>
                {prop.end_date === null || prop.end_date === "0000-00-00"
                  ? "-"
                  : dateFormat(new Date(prop.end_date), "dd/mm/yyyy")}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={9} sm={5} md={4}>
                <b>Designation</b>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                :
              </GridItem>
              <GridItem xs={11} sm={6} md={4}>
                {prop.designation}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={9} sm={5} md={4}>
                <b>Department</b>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                :
              </GridItem>
              <GridItem xs={11} sm={6} md={4}>
                {prop.department}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={9} sm={5} md={4}>
                <b>
                  {prop.action === "Approved" ? "Approved By" : "Rejected By"}
                </b>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                :
              </GridItem>
              <GridItem xs={11} sm={6} md={4}>
                {prop.employer_name ? prop.employer_name : "Admin"}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={9} sm={5} md={4}>
                <b>
                  {prop.action === "Approved" ? "Approved On" : "Rejected On"}
                </b>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                :
              </GridItem>
              <GridItem xs={11} sm={6} md={4}>
                {dateFormat(new Date(prop.added_on), "dd/mm/yyyy HH:MM:ss")}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={9} sm={5} md={4}>
                <b>Transaction ID</b>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                :
              </GridItem>
              <GridItem xs={11} sm={6} md={6}>
                {!prop.transaction_id ? "-" : prop.transaction_id}
              </GridItem>
            </GridContainer>
          </React.Fragment>
        )
      };
    });
    if (this.state.hashError) {
      return (
        <div>
          <ErrorPage />
        </div>
      );
    } else {
      return (
        <div>
          <Heading title="Document Verification History" textAlign="center" />
          <GridContainer>
            <GridItem xs={12}>
              <Card plain>
                <CardBody plain>
                  <Timeline stories={stories} />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default History;
