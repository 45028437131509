// ##############################
// // // Header styles
// #############################

import {
  containerFluid,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const headerStyle = theme => ({
  appBar: {
    backgroundColor: "transparent",
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    position: "absolute",
    width: "100%",
    paddingTop: "10px",
    zIndex: "1029",
    color: "#555555",
    border: "0",
    borderRadius: "3px",
    padding: "10px 0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block"
  },
  container: {
    ...containerFluid,
    minHeight: "50px"
  },
  flex: {
    flex: 1
  },
  title: {
    ...defaultFont,
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    paddingTop: "0.625rem",
    paddingBottom: "0.625rem",
    margin: "0 !important",
    "&:hover,&:focus": {
      background: "transparent"
    }
  },
  primary: {
    backgroundColor: primaryColor,
    color: "#FFFFFF",
    ...defaultBoxShadow
  },
  info: {
    backgroundColor: infoColor,
    color: "#FFFFFF",
    ...defaultBoxShadow
  },
  success: {
    backgroundColor: successColor,
    color: "#FFFFFF",
    ...defaultBoxShadow
  },
  warning: {
    backgroundColor: warningColor,
    color: "#FFFFFF",
    ...defaultBoxShadow
  },
  danger: {
    backgroundColor: dangerColor,
    color: "#FFFFFF",
    ...defaultBoxShadow
  },
  sidebarMinimize: {
    float: "left",
    padding: "0 0 0 15px",
    display: "block",
    color: "#555555"
  },
  sidebarMinimizeRTL: {
    padding: "0 15px 0 0 !important"
  },
  sidebarMiniIcon: {
    width: "20px",
    height: "17px"
  },

  notifications: {
    zIndex: "4",

    position: "absolute",
    top: "5px",
    border: "1px solid #FFF",
    right: "5px",
    fontSize: "9px",
    background: dangerColor,
    color: "#FFFFFF",
    minWidth: "16px",
    height: "16px",
    borderRadius: "10px",
    textAlign: "center",
    lineHeight: "14px",
    verticalAlign: "middle",
    display: "block"

    // [theme.breakpoints.down("sm")]: {
    // 	...defaultFont,
    // 	fontSize: "14px",
    // 	marginRight: "8px"
    // }
  },
  warningNotification: {
    zIndex: "4",
    position: "absolute",
    top: "5px",
    border: "1px solid #FFF",
    right: "5px",
    fontSize: "9px",
    background: warningColor,
    color: "#FFFFFF",
    minWidth: "16px",
    height: "16px",
    borderRadius: "10px",
    textAlign: "center",
    lineHeight: "14px",
    verticalAlign: "middle",
    display: "block"
  },
  managerClasses: {
    display: "flex"
  },
  buttonLinkRTL: {
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "flex-end",
      width: "-webkit-fill-available",
      margin: "10px 15px 0",
      padding: "10px 15px",
      display: "block",
      position: "relative"
    }
  },
  labelRTL: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row-reverse",
      justifyContent: "initial",
      display: "flex"
    }
  },
  linksRTL: {
    [theme.breakpoints.down("sm")]: {
      marginRight: "-5px !important",
      marginLeft: "16px !important"
    }
  },
  links: {
    width: "20px",
    height: "20px",
    zIndex: "4",

    display: "block",

    color: "inherit",
    opacity: "0.8",
    marginRight: "16px",
    marginLeft: "-5px"
  },
  buttonLink: {
    display: "flex",
    width: "auto",
    height: "auto",
    "& svg": {
      width: "30px",
      height: "24px",

      marginLeft: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "30px",
      fontSize: "24px",
      lineHeight: "30px",
      marginRight: "19px",
      marginLeft: "3px"
    }
  },
  headerLinksSvg: {
    width: "20px !important",
    height: "20px !important"
  },
  pooperResponsiveNullNotification: {
    transform: "translate3d(152px, 49px, 0px)!important",
    zIndex: "1200",
    flex: 1,
    maxWidth: "80%",
    minWidth: "30%",
    display: "flex",
    justifyContent: "flex-end"
  },
  pooperResponsive: {
    transform: "translate3d(33px, 49px, 0px)!important",
    zIndex: "1200",
    flex: 1,
    maxWidth: "80%",
    minWidth: "30%",
    display: "flex",
    justifyContent: "flex-end"
  },
  dropdownItem: {
    ...defaultFont,
    fontSize: "13px",
    padding: "10px 20px",
    margin: "0 5px",
    borderRadius: "2px",
    position: "relative",
    transition: "all 150ms linear",
    display: "block",
    clear: "both",
    fontWeight: "400",
    height: "100%",
    minWidth: "30%",
    color: "#333",
    whiteSpace: "pre-wrap"
  },
  dropdown: {
    overflowY: "auto",
    maxHeight: "376px"
  }
});

export default headerStyle;
