import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
//@material ui components
import Home from '@material-ui/icons/Home';
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import Button from '../../components/CustomButtons/Button.jsx';
import pagenotfound from '../../assets/img/page not found.png';
import history from '../../history.js';
// style
import Notfoundstyle from './Notfoundstyle';

class Notfound extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: true
		};
	}
	handleClose = () => {
		this.setState({ open: false });
	};
	render() {
		const { classes } = this.props;
		return (
			<div>
				<Modal open={this.state.open} animationDuration={1000} onClose={this.handleClose}>
					<GridContainer justify="center">
						<GridItem xs={12} sm={12} md={8}>
							<h1
								style={{
									color: 'white',
									fontWeight: 'bold',
									textAlign: 'center'
								}}
							>
								PAGE NOT FOUND!!!
							</h1>
							<img src={pagenotfound} alt="..." className={classes.img} />
							<div className={classes.center}>
								<Button color="tumblr" onClick={() => history.push('/dashboard')}>
									<Home /> &nbsp; Go Back To Homepage
								</Button>
							</div>
						</GridItem>
					</GridContainer>
				</Modal>
			</div>
		);
	}
}

Notfound.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(Notfoundstyle)(Notfound);
