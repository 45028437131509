import gql from "graphql-tag";

const EmployerActivityQuery = gql`
	query systemlogQuery($username: String!) {
		getSystemLog(username: $username) {
			logs {
				timestamp
				ip
				browser
				version
				device_id
				username
				operation
				row_id
				module_name
			}
			__typename
		}
		__typename
	}
`;

export default EmployerActivityQuery;
