import React, { Suspense } from "react";
import Countdown from "react-countdown-now";
import Gravatar from "react-gravatar";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import ScreenLoading from "../LoadingScreen.jsx";
// @material-ui/icon
import AddAlert from "@material-ui/icons/AddAlert";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";
import HourglassEmpty from "@material-ui/icons/HourglassEmpty";
//components
import Snackbar from "components/Snackbar/Snackbar.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//Custom components
import Button from "Custom-component/CustomButtons/Button.jsx";
//file imports
//queries
import EmployeeProfileDocumentquery from "../../Graphql/EmployeeProfileDocumentquery";
//styles
import "./Profile.css";
import ErrorPage from "../Pages/errorPage.jsx";

var dateFormat = require("dateformat"); // Date formater
const EmployeeDocumentList = React.lazy(() =>
  import("./EmployeeDocumentList.jsx")
);
const styles = {
  card: {
    maxWidth: 345,
    boxShadow:
      "0 12px 20px -10px rgba(158, 155, 155, 0.66), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(104, 108, 109, 0.55)",
    marginTop: "20px",
    padding: "10px",
    position: "relative",
    flexBasis: "25%",
    boxSizing: "border-box",
    "@media (max-width: 1200px)": {
      flexBasis: "40%"
    },
    "@media (max-width: 600px)": {
      flexBasis: "100%"
    }
  },
  media: {
    height: 140
  },
  blueAvatar: {
    margin: 10,
    color: "#FFFFFF",
    background: "linear-gradient(60deg, #26c6da, #FFC90D)",
    boxShadow:
      "0 12px 20px -10px rgba(255, 201, 13, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 201, 13, 0.2)"
    // backgroundColor:'#33DDFF',
  },
  iconbutton: {
    color: "#FFFFFF"
    // backgroundColor:'#33DDFF',
  },
  accepted_icon: {
    color: "green"
  },
  rejected_icon: {
    color: "#ea2121"
  },
  pending_icon: {
    color: "#ff9800"
  },

  cardactions: {
    // backgroundColor:'#FFC90D',
    justifyContent: "center",
    background: "linear-gradient(60deg, #26c6da, #FFC90D)",
    boxShadow:
      "0 12px 20px -10px rgba(255, 201, 13, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 201, 13, 0.2)"
  },
  h4: {
    fontSize: "1.1em"
  },

  info: {
    margin: "0px",
    height: "50px"
  }, //overridding info class style
  profilecardContainer: {
    display: "flex",

    flexFlow: "row wrap",
    justifyContent: "flex-start",
    alignItems: "stretch"
  },
  profilecardColumn: {
    flexBasis: "25%",
    position: "relative",
    padding: "10px",
    boxSizing: "border-box",
    "@media (max-width: 1200px)": {
      flexBasis: "40%"
    },
    "@media (max-width: 600px)": {
      flexBasis: "100%"
    }
  }
};
class Profileverification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ViewProfileList: true,
      EmployeeProfileVerification: false,
      EmployeeDocuments: [],
      bc: false,
      isLoading: false,
      errorMessage: "",
      loadingalert: false,
      hashError: false
    };
  }

  handleBack = () => {
    this.setState({
      ViewProfileList: true,
      EmployeeProfileVerification: false
    });
  };

  showNotification(place) {
    if (!this.state[place]) {
      this.setState({ bc: true });
      setTimeout(
        function() {
          this.setState({ bc: false });
        }.bind(this),
        6000
      );
    }
  }
  //handling error in components
  componentDidCatch(error, info) {
    this.setState({ hashError: true });
  }
  render() {
    const { classes, employee_profile, client } = this.props;

    const emplyeelist = [];
    const self = this;

    Object.keys(employee_profile).forEach(function(key) {
      var expire_time = new Date(employee_profile[key].expire_time);
      var UtcTime = new Date(
        expire_time.getTime() + expire_time.getTimezoneOffset() * 60000
      );
      emplyeelist.push([
        <Card className={classes.card} key={key}>
          <CardHeader
            avatar={
              <Avatar className={classes.blueAvatar}>
                <Gravatar
                  email={employee_profile[key].employee_email}
                  default="mp"
                  size={40}
                />
              </Avatar>
            }
            title={employee_profile[key].employee_username}
          />
          <CardContent>
            <GridContainer justify="center">
              <GridItem xs={5} sm={5} md={5}>
                <h4>Employee Name</h4>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <h4>:</h4>
              </GridItem>
              <GridItem xs={5} sm={5} md={5}>
                <h4>{employee_profile[key].employee_name}</h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={5} sm={5} md={5}>
                <h4>No. of Documents</h4>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <h4>:</h4>
              </GridItem>
              <GridItem xs={5} sm={5} md={5}>
                <h4>
                  {employee_profile[key].document_count !== null
                    ? employee_profile[key].document_count
                    : "-"}
                </h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={5} sm={5} md={5}>
                <h4>Expiry Time</h4>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <h4>:</h4>
              </GridItem>
              <GridItem xs={5} sm={5} md={5}>
                <h4>
                  {employee_profile[key].expire_time !== null ? (
                    <Countdown date={UtcTime} />
                  ) : (
                    "-"
                  )}
                </h4>
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem xs={5} sm={5} md={5}>
                <h4>Status</h4>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <h4>:</h4>
              </GridItem>
              <GridItem xs={5} sm={5} md={5}>
                <h4>
                  {employee_profile[key].employee_response === "Accepted" ? (
                    <Tooltip title="Accepted">
                      <CheckCircle className={classes.accepted_icon} />
                    </Tooltip>
                  ) : null}
                  {employee_profile[key].employee_response === "Rejected" ? (
                    <Tooltip title="Rejected">
                      <Cancel className={classes.rejected_icon} />
                    </Tooltip>
                  ) : null}
                  {employee_profile[key].employee_response === "Pending" ? (
                    <Tooltip title="Pending">
                      <HourglassEmpty className={classes.pending_icon} />
                    </Tooltip>
                  ) : null}
                </h4>
              </GridItem>
            </GridContainer>
          </CardContent>
          <CardActions>
            <GridContainer justify="center">
              <Button
                round
                id={"viewmore_" + key}
                type="submit"
                color="info"
                disabled={
                  employee_profile[key].employee_response === "Pending" ||
                  employee_profile[key].employee_response === "Rejected" ||
                  UtcTime < new Date()
                }
                //className={classes.info}
                onClick={async () => {
                  self.setState({
                    loadingalert: true,
                    ViewProfileList: false
                  });
                  await client
                    .query({
                      query: EmployeeProfileDocumentquery,
                      variables: {
                        username: localStorage.getItem("username"),
                        id: employee_profile[key].id,
                        current_time: dateFormat(new Date(), "yyyy/mm/dd")
                      }
                    })
                    .then(response => {
                      self.setState({
                        loadingalert: false,
                        EmployeeDocuments:
                          response.data.showEmployment === null
                            ? null
                            : response.data.showEmployment.Employment,
                        ViewProfileList: false,
                        EmployeeProfileVerification: true
                      });
                    })
                    .catch(err => {
                      self.setState({
                        ViewProfileList: true,
                        loadingalert: false,
                        errorMessage: "Failed To Fetch. Please try Again Later"
                      });
                      self.showNotification("bc");
                    });
                }}
              >
                {employee_profile[key].employee_response === "Accepted" &&
                UtcTime < new Date()
                  ? "Expired"
                  : employee_profile[key].employee_response === "Pending"
                  ? "Requested"
                  : "View More"}
                &nbsp;{" "}
                {/* <Icon>
										{employee_profile[key].employee_response === "Accepted" &&
										UtcTime < new Date()
											? null
											: employee_profile[key].employee_response === "Pending" ||
											  employee_profile[key].employee_response === "Rejected"
											? "visibility_off"
											: "visibility"}
									</Icon> */}
              </Button>
            </GridContainer>
          </CardActions>
        </Card>
      ]);
    });
    if (this.state.hashError) {
      return (
        <div>
          <ErrorPage />
        </div>
      );
    } else {
      return (
        <React.Fragment>
          {/* //this to show error message */}
          <Snackbar
            place="bc"
            color="danger"
            icon={AddAlert}
            message={this.state.errorMessage}
            open={this.state.bc}
            closeNotification={() => this.setState({ bc: false })}
            close
          />
          {this.state.ViewProfileList ? (
            //Show employee list card
            <GridContainer justify="center">
              <GridItem xs={12} md={12} lg={11}>
                <div className={classes.profilecardContainer}>
                  {emplyeelist.map((props, i) => (
                    <div key={i} className={classes.profilecardColumn}>
                      {props}
                    </div>
                  ))}
                </div>
              </GridItem>
            </GridContainer>
          ) : null}
          {this.state.EmployeeProfileVerification ? (
            //Show employee shared document list card
            <Suspense fallback={<ScreenLoading />}>
              <EmployeeDocumentList
                handleBack={this.handleBack}
                EmployeeDocuments={this.state.EmployeeDocuments}
                client={client}
              />
            </Suspense>
          ) : null}
          {this.state.loadingalert ? <ScreenLoading /> : null}
        </React.Fragment>
      );
    }
  }
}

export default withStyles(styles)(Profileverification);
