import React from "react";
import { ApolloConsumer } from "react-apollo";
import Profilequery from "./Profilequery"; //Query to retrieve profile data
// initiate client instances
class Profile extends React.Component {
	render() {
		return (
			<ApolloConsumer>
				{client => <Profilequery history={this.props} />}
			</ApolloConsumer>
		);
	}
}

export default Profile;
