import UnauthenticatedLayout from "../layouts/UnauthenticatedLayout";
import AuthenticatedLayout from "../layouts/AuthenticatedLayout";
// import AadharIntegrationQuery from "../Container/Aadhar/AadharIntegrationQuery";

var indexRoutes = [
  // {
  // 	path: "/identityverify",
  // 	name: "identityverify",
  // 	component: AadharIntegrationQuery
  // },
  {
    path: "/auth",
    name: "UnauthenticatedLayout",
    component: UnauthenticatedLayout
  },
  { path: "/", name: "AuthenticatedLayout", component: AuthenticatedLayout }
];

export default indexRoutes;
