import React, { Component, Fragment } from "react";
import Tour from "reactour";
import { getGlobal, resetGlobal } from "reactn";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "Custom-component/CustomButtons/Button.jsx";
import Code from "@material-ui/icons/Code";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "Custom-component/InfoArea/InfoArea.jsx";
import history from "../../history.js";
//styles
import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

class DataNotFound extends Component {
  state = {
    //  react tour variables
    help: this.props.help,
    nextHelp: false, // to enable react tour in onboard page(single/bulk employee) if react tour ended here.
    steps: [
      {
        content: () => (
          <Fragment>
            <b> Let's see how to onboard employee</b>
            <br />
            Please fill the details of employee you need to be onboarded into
            verifymate
          </Fragment>
        ),
        position: "top",
        selector: ".DataNotFoundSingleClass"
      },
      {
        content: "You can onboard bulk employee",
        position: "bottom",
        selector: ".DataNotFoundBulkClass"
      }
    ]
  };

  // function to close the tour
  closeTour = () => {
    resetGlobal();
    this.setState({ help: false, nextHelp: true });
  };

  render() {
    const { classes, button, onboard, title, buttontext, link } = this.props;
    const { help, steps } = this.state;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={10} md={8}>
            <Card className={classes.cardSignup}>
              <h3 className={classes.cardTitle}>
                <b>{title}</b>
              </h3>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={11} sm={12} md={10}>
                    <InfoArea
                      title="Step 1"
                      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet."
                      icon={Code}
                      iconColor="info"
                    />
                    <InfoArea
                      title="Step 2"
                      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet."
                      icon={Code}
                      iconColor="info"
                    />
                    <InfoArea
                      title="Step 3"
                      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet."
                      icon={Code}
                      iconColor="info"
                    />
                  </GridItem>
                </GridContainer>
                {button ? (
                  <GridContainer justify="center">
                    <Button
                      color="info"
                      style={{ textAlign: "center" }}
                      onClick={() => history.push(link)}
                    >
                      {buttontext}
                    </Button>
                  </GridContainer>
                ) : null}
                {/* to display button in onboard employee*/}
                {onboard ? (
                  <GridContainer justify="center">
                    <Tour
                      onRequestClose={this.closeTour}
                      steps={steps}
                      isOpen={getGlobal().value ? true : help ? true : false}
                      accentColor="#FFC90D"
                    />
                    {/* onboard employee button */}
                    <Button
                      className="DataNotFoundSingleClass"
                      color="info"
                      style={{ textAlign: "center" }}
                      onClick={() =>
                        this.props.onboardHandleback(
                          "onboardSingleEmployee",
                          this.state.nextHelp // to onboard page
                        )
                      }
                    >
                      Onboard Single Employee
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      className="DataNotFoundBulkClass"
                      color="info"
                      style={{ textAlign: "center" }}
                      onClick={() =>
                        this.props.onboardHandleback("onboardBulkEmployee")
                      }
                    >
                      Onboard Bulk Employee
                    </Button>
                  </GridContainer>
                ) : null}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(registerPageStyle)(DataNotFound);
