import React from "react";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
// @material-ui/icons
import PhonelinkLock from "@material-ui/icons/PhonelinkLock";
import Link from "@material-ui/icons/Link";
// import Send from '@material-ui/icons/Send';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// core components
import CustomInput from "Custom-component/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "Custom-component/InfoArea/InfoArea.jsx";
// import Button from 'components/CustomButtons/Button.jsx';
import LoaderButton from "../../../Custom-component/LoaderButton";
import config from "../../../config.js";
import history from "../../../history.js";
//styles
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
//file import
import ProfileValidation from "../../Validation/ProfileValidation.jsx";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#FFC90D !important"
  },
  ...customSelectStyle,
  ...buttonStyle
};

class Step4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employer_verification_otpcode: "",
      employer_verification_otpcodeState: "",
      value: "60",
      isLoading: false,
      isLoading1: false,
      alert: null,
      errorMessage: "",
      otpErrormessage: ""
    };
  }
  sendState() {
    return this.state;
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  // button enable
  enable() {
    if (this.state.employer_verification_otpcodeState === "success") {
      return false;
    } else {
      return true;
    }
  }

  _increase = () => {
    if (this.state.value > 0) {
      this.setState({
        value: this.state.value - 1
      });
      if (this.state.value !== 0) {
        setTimeout(this._increase.bind(this), 1000);
        return true;
      } else {
        clearInterval(this.interval);
        return false;
      }
    }
  };

  // onchange set values and do validation
  change_otp_register(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "otp_register":
        var verifyOTP = ProfileValidation.validateOTP(
          event.target.value,
          stateName
        );
        if (!verifyOTP[stateName]) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            otpErrormessage: verifyOTP[stateName]
          });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }

  resetForm() {
    this.sendState({
      employer_verification_otpcode: "",
      employer_verification_otpcodeState: ""
    });
  }
  // dispatch the data to store in redux
  isValidated() {
    // const { employers } = this.props;
    if (this.state.employer_verification_otpcodeState === "success") {
      this.props.getotpcode(this.state);
      return true;
    } else {
      this.setState({ employer_verification_otpcodeState: "error" });
      return false;
    }
  }
  resendcode = async () => {
    const { employers } = this.props;

    try {
      this.setState({ isLoading1: true });
      const resendsignp_payload = {
        username: employers.account.employer_account_username
      };
      var headers = {
        "Content-Type": "application/json"
      };
      axios
        .post(config.Cognito.resendcode_url, resendsignp_payload, {
          headers: headers
        })
        .then(response => {
          if (response.data.data === null) {
            this.setState({
              isLoading1: false,
              errorMessage: response.data.errors.error_message
            });
            this.warningWithConfirmMessage();
          } else {
            this.autoCloseAlert();
            this.setState({ value: "60", isLoading1: false });
            this._increase();
          }
        })
        .catch(err => {
          this.setState({
            isLoading1: false,
            errorMessage:
              "We experiencing difficulties with connectivity of the application, Please try again later"
          });
          this.warningWithConfirmMessage();
        });
    } catch (e) {
      this.setState({
        isLoading1: false,
        errorMessage:
          "We experiencing difficulties with connectivity of the application, Please try again later"
      });
      this.warningWithConfirmMessage();
    }
  };
  warningWithConfirmMessage = () => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px", color: "black" }}
          title="Please try again"
          onConfirm={() => this.successhideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Try Again"
        >
          {this.state.errorMessage}
        </SweetAlert>
      )
    });
  };
  SuccessAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-200px", color: "black" }}
          title="Registration Successfull!"
          onConfirm={() => this.hidesuccessAlert()}
          showConfirm={false}
        >
          <h4>Have a cool Verification process</h4>
        </SweetAlert>
      )
    });
    setTimeout(this.hidesuccessAlert, 2000);
  };
  hidesuccessAlert = () => {
    this.setState({
      alert: null
    });

    history.push("/auth/login");
  };
  //* verify employers mail to confirm the user in cognito registration process
  finishregistration = async () => {
    if (this.state.employer_verification_otpcodeState === "success") {
      try {
        this.setState({ isLoading: true });
        const confirmSignUp_payload = {
          username: localStorage.getItem("username"),
          code: this.state.employer_verification_otpcode
        };
        var headers = {
          "Content-Type": "application/json"
        };
        axios
          .post(config.Cognito.confirmsignup_url, confirmSignUp_payload, {
            headers: headers
          })
          .then(response => {
            if (response.data.data === null) {
              this.setState({
                isLoading: false,
                errorMessage: response.data.errors.error_message
              });
              this.warningWithConfirmMessage();
            } else {
              this.setState({ isLoading: false });
              this.SuccessAlert();
            }
          })
          .catch(err => {
            this.setState({
              isLoading: false,
              errorMessage:
                "We experiencing difficulties with connectivity of the application, Please try again later"
            });
            this.warningWithConfirmMessage();
          });
      } catch (e) {
        this.setState({
          isLoading: false,
          errorMessage:
            "We experiencing difficulties with connectivity of the application, Please try again later"
        });
        this.warningWithConfirmMessage();
      }
    } else {
      if (this.state.employer_verification_otpcode === "") {
        this.setState({
          employer_verification_otpcodeState: "error",
          otpErrormessage: "otp is required"
        });
      }
    }
  };
  autoCloseAlert = () => {
    // var contactnumber = String(employer_personal_contactnumber).substring(10, 13);

    this.setState({
      alert: (
        <SweetAlert
          style={{ display: "block", marginTop: "-200px", color: "black" }}
          title="OTP sent!"
          onConfirm={() => this.successhideAlert()}
          showConfirm={false}
        >
          Check your email to get OTP.
        </SweetAlert>
      )
    });
    setTimeout(this.successhideAlert, 2000);
  };
  successhideAlert = () => {
    this.setState({
      alert: null
    });
  };
  render() {
    const { classes } = this.props;

    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>OTP Verification</h4>
        </GridItem>
        <GridItem xs={12} sm={12} lg={12}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={5} lg={5}>
              <InfoArea
                title="OTP code"
                description="Please check your email for OTP code."
                icon={Link}
                iconColor="info"
              />
            </GridItem>

            <GridItem xs={12} sm={5} lg={5}>
              <CustomInput
                success={
                  this.state.employer_verification_otpcodeState === "success"
                }
                error={
                  this.state.employer_verification_otpcodeState === "error"
                }
                id="Register_employer_verification_otpcode"
                name="Register_employer_verification_otpcode"
                formControlProps={{
                  fullWidth: true
                }}
                helpText={
                  this.state.employer_verification_otpcodeState === "error"
                    ? this.state.otpErrormessage
                    : null
                }
                inputProps={{
                  placeholder: "Enter your OTP*",
                  onChange: event =>
                    this.change_otp_register(
                      event,
                      "employer_verification_otpcode",
                      "otp_register",
                      6
                    ),
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    >
                      <PhonelinkLock className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  )
                }}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} lg={12}>
          <GridContainer justify="center">
            {/* <Button color="info" onClick={this.finishregistration} >
							Verify OTP
						</Button> */}
            <LoaderButton
              disabled={
                this.state.employer_verification_otpcodeState !== "success"
              }
              type="submit"
              onClick={this.finishregistration}
              isLoading={this.state.isLoading}
              text="Verify OTP"
              loadingText="Verifying..."
            />
          </GridContainer>
        </GridItem>
        <br />
        <GridItem xs={11} sm={8} md={8} lg={8}>
          <div>
            <GridContainer>
              <GridItem xs={12} sm={8} md={8} lg={8}>
                <h4 className={classes.infoText}>
                  <br />
                  Pleasae Wait {this.state.value} seconds to resend code..
                </h4>
              </GridItem>
              <br />
              <GridItem xs={11} sm={4} md={4} lg={4}>
                <div>
                  {/* <Button
										color="info"
										disabled={this.state.value !== 0}
										onClick={this.state.value === 0 ? this.resendcode : null}
									>
										Resend <Send />
									</Button> */}
                  <LoaderButton
                    disabled={this.state.value !== 0}
                    type="submit"
                    onClick={this.state.value === 0 ? this.resendcode : null}
                    isLoading={this.state.isLoading1}
                    text="Resend"
                    loadingText="Resending..."
                  />
                </div>
              </GridItem>
            </GridContainer>
          </div>
          {this.state.alert}
        </GridItem>
      </GridContainer>
    );
  }
}

// connect this component to redux store
const mapStateToProps = state => {
  return {
    employers: state.employers
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getotpcode: otpcode => {
      dispatch({
        type: "GET_OTP_DATA",
        payload: otpcode
      });
    }
  };
};
const withstyleOTP = withStyles(style)(Step4);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withstyleOTP);
