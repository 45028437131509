import React from "react";
import { Query } from "react-apollo";
import { ApolloConsumer } from "react-apollo";
import { Redirect } from "react-router-dom";
//components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//Files imports
import MyemployeeList from "./MyemployeeList.jsx";
import Errorpage from "../Pages/errorPage.jsx";
import Datanotfound from "../Pages/Datanotfound.jsx";
import ScreenLoading from "../LoadingScreen.jsx";
//quries
import GetMyEmployeeQuery from "../../Graphql/GetMyEmployeeQuery";

//current working employee list of employer query
const Myemployeequery = ({
  username = localStorage.getItem("username"),
  history,
  client
}) => (
  <Query query={GetMyEmployeeQuery} variables={{ username }}>
    {({ loading, data, error }) => {
      if (loading) {
        return (
          <GridContainer justify="center">
            <GridItem xs={10} sm={8} md={8}>
              <ScreenLoading />
            </GridItem>
          </GridContainer>
        );
      }
      // To show error page on failure to retrieve data from backend

      if (error)
        return (
          <div>
            <Errorpage />
          </div>
        );
      return localStorage.getItem("aadhar_verified") === "0" ? ( // redirect to new user page when the user is new
        <Redirect to="/identityverify" />
      ) : (
        <div>
          {data.getMyAllEmployees === null ? (
            //If data not exits show data not found page

            <Datanotfound
              title={"No Employees Found"}
              message={
                "You will get this data if Your Employee Onboarded into Verify Mate"
              }
              button={true}
              link={"/employee/onboardemployee"}
              buttontext={"Onboard Employee"}
            />
          ) : (
            <MyemployeeList
              key={data.getMyAllEmployees}
              MyAllEmployees={data.getMyAllEmployees}
              history={history}
              client={client}
            />
          )}
        </div>
      );
    }}
  </Query>
);

class MyAllEmployee extends React.Component {
  render() {
    return (
      //apollo client instances
      <ApolloConsumer>
        {client => (
          <Myemployeequery history={this.props.history} client={client} />
        )}
      </ApolloConsumer>
    );
  }
}

export default MyAllEmployee;
