import React from "react";
import { Query } from "react-apollo";
import { ApolloConsumer } from "react-apollo";

//queries
import Notificationquery from "../../Graphql/Notificationquery";
import HeaderLinks from "./HeaderLinks.jsx";
var isError = false;
// retrieve all notifications
const NotificationQuery = ({
  username = localStorage.getItem("username"),
  client,
  history,
  rtlActive,
  back
}) => (
  <Query query={Notificationquery} variables={{ username }}>
    {({ loading, data, error }) => {
      if (loading) {
        return true;
      }
      //if error set variable as true to display warning in notification
      if (error) {
        isError = true;
      }
      if (back && !isError) {
        back(data.getNotification);
      }
      return (
        <div>
          {/* if error occur  */}
          {isError ? (
            <HeaderLinks
              getNotification={null}
              rtlActive={rtlActive}
              history={history}
              client={client}
              isError={isError}
            />
          ) : (
            <HeaderLinks
              getNotification={data.getNotification}
              rtlActive={rtlActive}
              history={history}
              client={client}
            />
          )}
        </div>
      );
    }}
  </Query>
);

class GetNotification extends React.Component {
  render() {
    return (
      <ApolloConsumer>
        {client => (
          <NotificationQuery
            history={this.props.history}
            client={client}
            rtlActive={this.props.rtlActive}
            back={this.props.back}
          />
        )}
      </ApolloConsumer>
    );
  }
}

export default GetNotification;
