import React from "react";
import PropTypes from "prop-types";
import ReactCardFlip from "react-card-flip";
import { graphql } from "react-apollo";
import { Link } from "react-router-dom";
import { ApolloConsumer } from "react-apollo";
import Tour from "reactour";
import { getGlobal, resetGlobal } from "reactn";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";

// material ui icons
import Links from "@material-ui/icons/Link";
import Lock from "@material-ui/icons/Lock";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";
// Custom components
import LoaderButton from "Custom-component/LoaderButton";
import CustomInput from "Custom-component/CustomInput/CustomInput.jsx";
import InfoArea from "Custom-component/InfoArea/InfoArea.jsx";
// Styles
import dashboardStyle from "./dashboardstyle";

//queries and mutation
import EmployeeOnboardRequest from "../../Graphql/EmployeeOnboardRequest";
import ProfileviewRequestMutation from "../../Graphql/ProfileviewRequestMutation";
//file import
import ProfileValidation from "../Validation/ProfileValidation.jsx";
import ErrorPage from "../Pages/errorPage.jsx";

class Dashboardpage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: "",
      isFlipped: false,
      onboardcardFlip: false,
      requestsuccess: false,
      onboardrequestsuccess: false,
      //Statistics count
      totaldocument: this.props.count.getTotalCount.count,
      rejecteddocument: this.props.count.getRejectedCount.count,
      pendingdocument: this.props.count.getPendingCount.count,
      approveddocument: this.props.count.getApprovedCount.count,

      //Request Employee profile
      Profilerequest_EmployeeUsername: "",
      Profilerequest_EmployeeUsernameState: "",

      //Onboard employee

      Onboard_Employee_Email: "",
      Onboard_Employee_EmailState: "",
      showemployeeidentitydetails: true,
      showemployee_employmentdetails: false,
      showemployeeonboarddetails: true,

      //Error Message
      Profilerequest_EmployeeUsernameErrMsg: "",
      Onboard_Employee_EmailErrMsg: "",
      isLoading: false,
      isLoading1: false,
      hashError: false,

      //for react tour declaring steps and variables
      help: this.props.help,
      steps: [
        {
          content:
            "Request Employee for viewing their documents. Provide employee username and click send request. To view the status of request click  the Click Here button",

          position: "top",
          selector: ".requestClass"
        }
      ]
    };
  }

  //handling error in components
  componentDidCatch(error, info) {
    this.setState({ hashError: true });
  }
  //Input Onchange event
  change_dashboard(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "onboardemployeeemail":
        var employeeemail = ProfileValidation.validateEmail(
          event.target.value,
          stateName
        );
        if (!employeeemail[stateName]) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            [stateName + "ErrMsg"]: employeeemail[stateName]
          });
        }
        break;
      case "employeeusername":
        var employeeUsername = ProfileValidation.validateUsername(
          event.target.value,
          stateName
        );
        if (!employeeUsername[stateName]) {
          this.setState({
            [stateName + "State"]: "success"
          });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            [stateName + "ErrMsg"]: employeeUsername[stateName]
          });
        }
        break;
      default:
        break;
    }
    //assign the value to the state variable
    this.setState({ [stateName]: event.target.value });
  }

  //fucntion to  request employee for document share
  handlerequestemployee = async () => {
    if (this.state.Profilerequest_EmployeeUsernameState === "success") {
      this.setState({ isLoading: true });
      await this.props
        .mutate({
          variables: {
            username: localStorage.getItem("username"),
            employee_username: this.state.Profilerequest_EmployeeUsername,
            ip: localStorage.getItem("ip"),
            browser: localStorage.getItem("browserName"),
            version: localStorage.getItem("browserVersion"),
            device_id: localStorage.getItem("deviceId")
          }
        })
        .then(response => {
          if (response.data !== null) {
            this.setState({
              isLoading: false,
              isFlipped: true,
              requestsuccess: true
            });
          } else {
            this.setState({
              isLoading: false,
              isFlipped: true,
              requestsuccess: false
            });
          }
        })
        .catch(err => {
          this.setState({
            isLoading: false,
            isFlipped: true,
            requestsuccess: false
          });
        });
    }
  };

  //function to send onboard request to employee
  handleOnboardRequest = async client => {
    if (this.state.Onboard_Employee_EmailState === "success") {
      this.setState({ isLoading1: true });
      await client
        .query({
          query: EmployeeOnboardRequest,
          variables: {
            username: localStorage.getItem("username"),
            employee_mail: this.state.Onboard_Employee_Email
          }
        })
        .then(response => {
          if (response.data !== null) {
            this.setState({
              isLoading1: false,
              onboardcardFlip: true,
              onboardrequestsuccess: true
            });
          } else {
            this.setState({
              isLoading1: false,
              onboardcardFlip: true,
              onboardrequestsuccess: false
            });
          }
        })
        .catch(err => {
          this.setState({
            isLoading1: false,
            onboardcardFlip: true,
            onboardrequestsuccess: false
          });
        });
    }
  };

  //fucntion to close the card flip on request employee component
  handlecloseflip = () => {
    this.setState({
      Profilerequest_EmployeeUsername: "",
      Profilerequest_EmployeeUsernameState: "",
      isFlipped: false
    });
  };
  //fucntion to close the card flip on onboard component
  handleOnboardflipClose = () => {
    this.setState({
      Onboard_Employee_Email: "",
      Onboard_Employee_EmailState: "",
      onboardcardFlip: false
    });
  };
  // function to close the tour
  closeTour = () => {
    resetGlobal();
    this.setState({ help: false });
  };

  // To navigate to corresponding page
  lastStep = () => {
    this.props.history.push("/employee/employeeprofileverification");
  };
  render() {
    const { classes } = this.props;
    const { steps } = this.state;
    if (this.state.hashError) {
      return (
        <div>
          <ErrorPage />
        </div>
      );
    } else {
      return (
        //Employer Statistics data
        <ApolloConsumer>
          {client => (
            <div>
              {this.state.alert}
              <div className={classes.dashboardrowContainer}>
                {/* react tour */}
                <Tour
                  onRequestClose={this.closeTour}
                  steps={steps}
                  isOpen={
                    getGlobal().value ? true : this.state.help ? true : false
                  }
                  lastStepNextButton={
                    <Link
                      style={{ color: "#000" }}
                      to="/employee/employeeprofileverification"
                    >
                      Click Here
                    </Link>
                  }
                  accentColor="#FFC90D"
                />

                {/* Document request count statistics */}
                <Card className={classes.statscard}>
                  <CardHeader color="info" stats icon>
                    <CardIcon color="info">
                      <Icon>insert_drive_file</Icon>
                    </CardIcon>
                    <p className={classes.cardCategory}>Verification Request</p>
                    <h3 className={classes.cardCategory}>
                      {this.state.totaldocument}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <Icon>info</Icon>

                      <Link
                        to={{
                          pathname: "/employee/viewrequest",
                          state: "-"
                        }}
                        className={classes.a}
                      >
                        More Info
                      </Link>
                    </div>
                  </CardFooter>
                </Card>

                {/* Document Approve by employer statistics */}

                <Card className={classes.statscard}>
                  <CardHeader color="success" stats icon>
                    <CardIcon color="success">
                      <Icon>check_circle</Icon>
                    </CardIcon>
                    <p className={classes.cardCategory}>Approved Document</p>
                    <h3 className={classes.cardCategory}>
                      {this.state.approveddocument}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <Icon>info</Icon>

                      <Link
                        to={{
                          pathname: "/employee/viewrequest",
                          state: "Approved"
                        }}
                        className={classes.a}
                      >
                        More Info
                      </Link>
                    </div>
                  </CardFooter>
                </Card>

                {/* Document rejected by employer count statistics */}

                <Card className={classes.statscard}>
                  <CardHeader color="danger" stats icon>
                    <CardIcon color="danger">
                      <Icon>cancel</Icon>
                    </CardIcon>
                    <p className={classes.cardCategory}>Rejected Document</p>
                    <h3 className={classes.cardCategory}>
                      {this.state.rejecteddocument}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <Icon>info</Icon>

                      <Link
                        to={{
                          pathname: "/employee/viewrequest",
                          state: "Rejected"
                        }}
                        className={classes.a}
                      >
                        More Info
                      </Link>
                    </div>
                  </CardFooter>
                </Card>

                {/* Document approval request Pending count statistics */}

                <Card className={classes.statscard}>
                  <CardHeader color="warning" stats icon>
                    <CardIcon color="warning">
                      <Icon>hourglass_empty</Icon>
                    </CardIcon>
                    <p className={classes.cardCategory}> Pending Document</p>
                    <h3 className={classes.cardCategory}>
                      {this.state.pendingdocument}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <Icon>info</Icon>

                      <Link
                        to={{
                          pathname: "/employee/viewrequest",
                          state: "Pending"
                        }}
                        className={classes.a}
                      >
                        More Info
                      </Link>
                    </div>
                  </CardFooter>
                </Card>
              </div>
              {/* Request for Review Employee document */}
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={10}>
                  <Card>
                    <CardHeader color="info" text>
                      <CardText color="info">
                        <h4 className={classes.cardIconTitle.color}>
                          <b> Request Employment History</b>
                        </h4>
                      </CardText>
                    </CardHeader>
                    <CardBody>
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={5}>
                          <InfoArea
                            title="Requesting for employee document"
                            description="Request the employees who are part of VERIFY MATE to share their current or previous employment record with you. "
                            icon={Links}
                            iconColor="rose"
                          />
                          <InfoArea
                            title="Secure Documents"
                            description="Documents, which is shared by employee is very secured. Documents are access by authority only"
                            icon={Lock}
                            iconColor="primary"
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={5}>
                          <ReactCardFlip isFlipped={this.state.isFlipped}>
                            <Card key="front" className={classes.cardSignup}>
                              <h4 className={classes.cardTitle}>
                                <b>Request Employee</b>
                              </h4>
                              <CardBody>
                                <GridContainer justify="center">
                                  <GridItem
                                    xs={12}
                                    sm={10}
                                    md={10}
                                    className="requestClass"
                                  >
                                    <CustomInput
                                      success={
                                        this.state
                                          .Profilerequest_EmployeeUsernameState ===
                                        "success"
                                      }
                                      error={
                                        this.state
                                          .Profilerequest_EmployeeUsernameState ===
                                        "error"
                                      }
                                      id="Dashboard_Profilerequest_EmployeeUsername"
                                      name="Profilerequest_EmployeeUsername"
                                      formControlProps={{
                                        fullWidth: true,
                                        className:
                                          classes.customFormControlClasses
                                      }}
                                      helpText={
                                        this.state
                                          .Profilerequest_EmployeeUsernameState ===
                                          "error"
                                          ? this.state
                                            .Profilerequest_EmployeeUsernameErrMsg
                                          : null
                                      }
                                      inputProps={{
                                        onChange: event =>
                                          this.change_dashboard(
                                            event,
                                            "Profilerequest_EmployeeUsername",
                                            "employeeusername",
                                            3
                                          ),
                                        // onChange:this.handleInputChange,
                                        type: "text",
                                        startAdornment: (
                                          <InputAdornment
                                            position="start"
                                            className={classes.inputAdornment}
                                          >
                                            <Icon
                                              className={
                                                classes.inputAdornmentIcon
                                              }
                                            >
                                              person
                                            </Icon>
                                          </InputAdornment>
                                        ),
                                        placeholder: "Employee Username",
                                        value: this.state
                                          .Profilerequest_EmployeeUsername
                                      }}
                                    />

                                    {this.state.isFlipped ? null : (
                                      <div className={classes.center}>
                                        <LoaderButton
                                          id="login_button"
                                          // disabled={this.enable()}
                                          type="submit"
                                          onClick={this.handlerequestemployee}
                                          isLoading={this.state.isLoading}
                                          text="Send Request"
                                          loadingText="Sending…"
                                        />
                                      </div>
                                    )}
                                  </GridItem>
                                </GridContainer>
                              </CardBody>
                            </Card>
                            <Card key="back" className={classes.cardSignup}>
                              {this.state.requestsuccess ? (
                                <CardBody>
                                  <GridContainer justify="center">
                                    <GridItem xs={12} sm={10} md={10}>
                                      <div className={classes.center}>
                                        <h3 className={classes.cardTitle}>
                                          Request sent Successfully..
                                        </h3>
                                        <Icon className={classes.successicons}>
                                          check_circle
                                        </Icon>
                                        <p />
                                        <Button
                                          size="sm"
                                          color="success"
                                          style={
                                            this.state.isFlipped
                                              ? null
                                              : { display: "none" }
                                          }
                                          className={classes.center}
                                          id="Employer_request_employee_success"
                                          onClick={this.handlecloseflip}
                                        >
                                          OK &nbsp;
                                        </Button>
                                      </div>
                                    </GridItem>
                                  </GridContainer>
                                </CardBody>
                              ) : (
                                  <CardBody>
                                    <GridContainer justify="center">
                                      <GridItem xs={12} sm={10} md={10}>
                                        <div className={classes.center}>
                                          <h3 className={classes.cardTitle}>
                                            UserName Incorrect!!
                                        </h3>
                                          <Icon className={classes.rejecticons}>
                                            cancel
                                        </Icon>
                                          <p />
                                          <Button
                                            size="sm"
                                            color="danger"
                                            style={
                                              this.state.isFlipped
                                                ? null
                                                : { display: "none" }
                                            }
                                            className={classes.center}
                                            id="Employer_request_employee_reject"
                                            onClick={this.handlecloseflip}
                                          >
                                            Retry &nbsp;{" "}
                                            <Icon className={classes.retryicons}>
                                              refresh
                                          </Icon>
                                          </Button>
                                        </div>
                                      </GridItem>
                                    </GridContainer>
                                  </CardBody>
                                )}
                            </Card>
                          </ReactCardFlip>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
              {/* Onboarding Employee by the employer */}
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={10}>
                  <Card>
                    <CardHeader color="info" text>
                      <CardText color="info">
                        <h4 className={classes.cardIconTitle.color}>
                          <b>Invite Employee</b>
                        </h4>
                      </CardText>
                    </CardHeader>
                    <CardBody>
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={5}>
                          <InfoArea
                            title="Onboarding Employee"
                            description="Do not spend time on composing a mail, we will do that for you. Invite your employee with a single click."
                            icon={Links}
                            iconColor="rose"
                          />
                          <InfoArea
                            title="Secure Platform"
                            description="Employee receive an email by the authority. So its is very secure to onboard."
                            icon={Lock}
                            iconColor="primary"
                          />
                        </GridItem>

                        <GridItem xs={12} sm={8} md={5}>
                          <ReactCardFlip isFlipped={this.state.onboardcardFlip}>
                            <Card key="front" className={classes.cardSignup}>
                              <h4 className={classes.cardTitle}>
                                <b>Invite Employee</b>
                              </h4>
                              <CardBody>
                                {this.state.showemployeeonboarddetails ? (
                                  <GridContainer justify="center">
                                    <GridItem xs={12} sm={10} md={10}>
                                      <CustomInput
                                        success={
                                          this.state
                                            .Onboard_Employee_EmailState ===
                                          "success"
                                        }
                                        error={
                                          this.state
                                            .Onboard_Employee_EmailState ===
                                          "error"
                                        }
                                        id="Onboard_Employee_Email"
                                        formControlProps={{
                                          fullWidth: true,
                                          className:
                                            classes.customFormControlClasses
                                        }}
                                        helpText={
                                          this.state
                                            .Onboard_Employee_EmailState ===
                                            "error"
                                            ? this.state
                                              .Onboard_Employee_EmailErrMsg
                                            : null
                                        }
                                        inputProps={{
                                          placeholder: "Employee Email",
                                          onChange: event =>
                                            this.change_dashboard(
                                              event,
                                              "Onboard_Employee_Email",
                                              "onboardemployeeemail"
                                            ),
                                          startAdornment: (
                                            <InputAdornment
                                              position="start"
                                              className={classes.inputAdornment}
                                            >
                                              <Icon
                                                className={
                                                  classes.inputAdornmentIcon
                                                }
                                              >
                                                email
                                              </Icon>
                                            </InputAdornment>
                                          ),
                                          value: this.state
                                            .Onboard_Employee_Email
                                        }}
                                      />
                                      {this.state.onboardcardFlip ? null : (
                                        <div className={classes.center}>
                                          {/* <Button
																					color="info"
																					className={classes.center}
																					id="Onboard_Employee_Click"
																					onClick={() =>
																						this.handleOnboardRequest(
																							client
																						)}
																				>
																					INVITE EMPLOYEE &nbsp;
																					<Icon className={classes.icons}>
																						send
																					</Icon>
																				</Button> */}
                                          <LoaderButton
                                            id="invite_employee_button"
                                            className={classes.center}
                                            type="submit"
                                            onClick={() =>
                                              this.handleOnboardRequest(client)
                                            }
                                            isLoading={this.state.isLoading1}
                                            text="Invite Employee"
                                            loadingText="Sending Request…"
                                          />
                                        </div>
                                      )}
                                    </GridItem>
                                  </GridContainer>
                                ) : null}
                              </CardBody>
                            </Card>
                            <Card key="back" className={classes.cardSignup}>
                              {this.state.onboardrequestsuccess ? (
                                <CardBody>
                                  <GridContainer justify="center">
                                    <GridItem xs={12} sm={10} md={10}>
                                      <div className={classes.center}>
                                        <h3 className={classes.cardTitle}>
                                          Request sent Successfully..
                                        </h3>
                                        <Icon className={classes.successicons}>
                                          check_circle
                                        </Icon>
                                        <p />
                                        <Button
                                          size="sm"
                                          color="success"
                                          className={classes.center}
                                          style={
                                            this.state.onboardcardFlip
                                              ? null
                                              : { display: "none" }
                                          }
                                          id="Employer_request_employee_success"
                                          onClick={this.handleOnboardflipClose}
                                        >
                                          OK &nbsp;
                                        </Button>
                                      </div>
                                    </GridItem>
                                  </GridContainer>
                                </CardBody>
                              ) : (
                                  <CardBody>
                                    <GridContainer justify="center">
                                      <GridItem xs={12} sm={10} md={10}>
                                        <div className={classes.center}>
                                          <h3 className={classes.cardTitle}>
                                            Incorrect Email
                                        </h3>

                                          <p />
                                          <Button
                                            size="sm"
                                            color="danger"
                                            className={classes.center}
                                            id="Onboard_employee_request_reject"
                                            onClick={this.handleOnboardflipClose}
                                          >
                                            Retry &nbsp;{" "}
                                            <Icon className={classes.retryicons}>
                                              refresh
                                          </Icon>
                                          </Button>
                                        </div>
                                      </GridItem>
                                    </GridContainer>
                                  </CardBody>
                                )}
                            </Card>
                          </ReactCardFlip>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
          )}
        </ApolloConsumer>
      );
    }
  }
}

Dashboardpage.propTypes = {
  classes: PropTypes.object.isRequired
};

const dashboardwithstyle = withStyles(dashboardStyle)(Dashboardpage);
export default graphql(ProfileviewRequestMutation)(dashboardwithstyle);
