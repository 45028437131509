import React from "react";
import { connect } from "react-redux";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
// @material-ui/icons
import Business from "@material-ui/icons/Business";
import Email from "@material-ui/icons/Email";
import Link from "@material-ui/icons/Link";
import AccountBox from "@material-ui/icons/AccountBox";
import ArtTrack from "@material-ui/icons/ArtTrack";
import LocationCity from "@material-ui/icons/LocationCity";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "Custom-component/CustomInput/CustomInput.jsx";
import InfoArea from "Custom-component/InfoArea/InfoArea.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
//styles
import "./phonestyle.css";
//file import
import ProfileValidation from "../../Validation/ProfileValidation.jsx";

const style = {
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#FFC90D !important"
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px"
  },
  iconCheckboxIcon: {
    fontSize: "100px !important",
    lineHeight: "111px",
    marginTop: "15px !important",
    textAlign: "center !important"
  }
};

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company: {
        //employer company data
        employer_company_name: "",
        employer_company_contactnumber: "",
        employer_company_mailid: "",
        employer_company_address: "",
        employer_din_number: "",
        employer_pan_number: ""
      },

      //filed validation state
      employer_company_nameState: "",
      employer_company_contactnumberState: "",
      employer_pan_number: "",
      employer_pan_numberState: "",
      employer_din_number: "",
      employer_din_numberState: "",
      employer_company_mailidState: "",
      employer_company_addressState: "",
      employer_company_addressErrormessage: "",
      employer_company_nameErrormessage: "",
      employer_din_numberErrormessage: "",
      employer_pan_numberErrormessage: "",
      employer_company_mailidErrormessage: ""
    };
  }
  sendState() {
    return this.state;
  }
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  // function for input validation
  change_company_details_register(event, stateName, type) {
    var value = event.target.value;
    switch (type) {
      case "email":
        var email = ProfileValidation.validateEmail(value, stateName);
        if (!email[stateName]) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            [stateName + "Errormessage"]: email[stateName]
          });
        }
        break;
      case "name_address":
        var verify = ProfileValidation.validateNameAddress(value, stateName);

        if (!verify[stateName]) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            [stateName + "Errormessage"]: verify[stateName]
          });
        }
        break;
      case "pan_din":
        var verifyPanDin = ProfileValidation.validatePanDin(value, stateName);
        if (!verifyPanDin[stateName]) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            [stateName + "Errormessage"]: verifyPanDin[stateName]
          });
        }
        break;
      default:
        break;
    }
    this.setState({
      company: {
        ...this.state.company,
        [stateName]: value
      }
    });
  }
  //phone number onchange function
  changePhoneNumberRegister(employer_company_contactnumber) {
    try {
      this.setState({
        company: {
          ...this.state.company,
          employer_company_contactnumber: employer_company_contactnumber
        }
      });
      if (isValidPhoneNumber(employer_company_contactnumber)) {
        this.setState({ employer_company_contactnumberState: undefined });
      } else {
        this.setState({
          employer_company_contactnumberState: "Invalid phone number"
        });
      }
    } catch (err) {
      this.setState({
        employer_company_contactnumberState: "Enter valid phone number"
      });
    }
  }

  isValidated() {
    if (
      this.state.employer_company_nameState === "success" &&
      this.state.employer_company_contactnumberState === undefined &&
      this.state.employer_din_numberState !== "error" &&
      this.state.employer_pan_numberState === "success" &&
      this.state.employer_company_mailidState === "success" &&
      this.state.employer_company_addressState === "success"
    ) {
      this.props.getCompanydata(this.state.company);
      return true;
    } else {
      const { company } = this.state;
      if (company.employer_company_name === "") {
        this.setState({
          employer_company_nameState: "error",
          employer_company_nameErrormessage: "Company name is required"
        });
      }
      if (company.employer_company_contactnumber === "") {
        this.setState({
          employer_company_contactnumberState: "Phone number is required"
        });
      }
      if (company.employer_company_mailid === "") {
        this.setState({
          employer_company_mailidState: "error",
          employer_company_mailidErrormessage: "Email is required"
        });
      }
      if (company.employer_pan_number === "") {
        this.setState({
          employer_pan_numberState: "error",
          employer_pan_numberErrormessage: "PAN is required"
        });
      }
      if (company.employer_company_address === "") {
        this.setState({
          employer_company_addressState: "error",
          employer_company_addressErrormessage: "Address is required"
        });
      }
      return false;
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        {/* Employer company details page */}
        <h4 className={classes.infoText}>Give your company details</h4>
        <GridContainer justify="center">
          <GridItem xs={12} sm={5} lg={5}>
            <InfoArea
              title="Requesting for a pin"
              description="We've created the marketing campaign of the website. It was a very interesting collaboration."
              icon={Link}
              iconColor="info"
            />
            <CustomInput
              success={this.state.employer_company_nameState === "success"}
              error={this.state.employer_company_nameState === "error"}
              id="Register_employer_company_name"
              name="Register_employer_company_name"
              formControlProps={{
                fullWidth: true
              }}
              helpText={
                this.state.employer_company_nameState === "error"
                  ? this.state.employer_company_nameErrormessage
                  : null
              }
              inputProps={{
                placeholder: "Company Name*",
                onChange: event =>
                  this.change_company_details_register(
                    event,
                    "employer_company_name",
                    "name_address",
                    5
                  ),
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className={classes.inputAdornment}
                  >
                    <Business className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                )
              }}
            />
            <CustomInput
              success={this.state.employer_pan_numberState === "success"}
              error={this.state.employer_pan_numberState === "error"}
              id="Register_employer_company_pan"
              name="Register_employer_company_pan"
              formControlProps={{
                fullWidth: true
              }}
              helpText={
                this.state.employer_pan_numberState === "error"
                  ? this.state.employer_pan_numberErrormessage
                  : null
              }
              inputProps={{
                placeholder: "PAN Number*",
                onChange: event =>
                  this.change_company_details_register(
                    event,
                    "employer_pan_number",
                    "pan_din",
                    5
                  ),
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className={classes.inputAdornment}
                  >
                    <AccountBox className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                )
              }}
            />
            <CustomInput
              success={this.state.employer_din_numberState === "success"}
              error={this.state.employer_din_numberState === "error"}
              id="Register_employer_company_din"
              name="Register_employer_company_din"
              formControlProps={{
                fullWidth: true
              }}
              helpText={
                this.state.employer_din_numberState === "error"
                  ? this.state.employer_din_numberErrormessage
                  : null
              }
              inputProps={{
                placeholder: "DIN Number",
                onChange: event =>
                  this.change_company_details_register(
                    event,
                    "employer_din_number",
                    "pan_din",
                    5
                  ),
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className={classes.inputAdornment}
                  >
                    <ArtTrack className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                )
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={5} lg={5}>
            <InfoArea
              title="About Company"
              description="We've created the marketing campaign of the website. It was a very interesting collaboration."
              icon={Link}
              iconColor="info"
            />
            <CustomInput
              success={this.state.employer_company_mailidState === "success"}
              error={this.state.employer_company_mailidState === "error"}
              id="Register_employer_company_mailid"
              name="Register_employer_company_mailid"
              formControlProps={{
                fullWidth: true
              }}
              helpText={
                this.state.employer_company_mailidState === "error"
                  ? this.state.employer_company_mailidErrormessage
                  : null
              }
              inputProps={{
                placeholder: "Email*",
                onChange: event =>
                  this.change_company_details_register(
                    event,
                    "employer_company_mailid",
                    "email"
                  ),
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className={classes.inputAdornment}
                  >
                    <Email className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                )
              }}
            />
            <PhoneInput
              country="IN"
              placeholder="Company Phone Number*"
              name="Register_employer_company_contactnumber"
              value={this.state.employer_company_contactnumber}
              onChange={employer_company_contactnumber =>
                this.changePhoneNumberRegister(employer_company_contactnumber)
              }
              error={this.state.employer_company_contactnumberState}
            />
            <CustomInput
              success={this.state.employer_company_addressState === "success"}
              error={this.state.employer_company_addressState === "error"}
              id="Register_employer_company_address"
              name="Register_employer_company_address"
              formControlProps={{
                fullWidth: true
              }}
              helpText={
                this.state.employer_company_addressState === "error"
                  ? this.state.employer_company_addressErrormessage
                  : null
              }
              inputProps={{
                multiline: true,
                rows: 1,
                placeholder: "Company Address*",
                onChange: event =>
                  this.change_company_details_register(
                    event,
                    "employer_company_address",
                    "name_address",
                    5
                  ),
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className={classes.inputAdornment}
                  >
                    <LocationCity className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                )
              }}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

//connect this component to the store
const mapStateToProps = state => {
  return {
    employers: state.employers
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getCompanydata: company => {
      dispatch({
        type: "GET_COMPANY_DATA",
        payload: company
      });
    }
  };
};
const withstylecompany = withStyles(style)(Step2);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withstylecompany);
