const baseurl = "https://devapi.verifymate.app";
const dev_env = "";
const prod_env = "/prod";
const release_env = "/release";
const platform = "/employer";
const s3Platform = "";
const profilePlatform = "";
//development configuration
const dev = {
  s3: {
    BUCKET: "GVP",
    s3Download: baseurl + dev_env + "/employee/retrieveFile",
    s3Upload: baseurl + dev_env + platform + "/uploadFile",
    s3ListFiles: baseurl + dev_env + platform + "/listFiles",
    s3DeleteFiles: baseurl + dev_env + platform + "/deleteFile",
    s3DownloadFiles: baseurl + dev_env + platform + "/retrieveFile"
  },
  siteKey: "6LeCepsUAAAAAPJoAWAG97Nwosc_KukG-8syoZoj",
  graphqlendpoint: {
    URL: baseurl + dev_env + platform + "/graphql"
  },
  profileImage: {
    ProfileUpload: baseurl + dev_env + platform + "/uploadProfileImage",
    ProfileRetrieve: baseurl + dev_env + platform + "/retrieveProfileImage",
    ProfileRemove: baseurl + dev_env + platform + "/deleteProfileImage",
    ProfileChange: baseurl + dev_env + platform + "/replaceProfileImage"
  },
  aadharbrigge_url: "https://prod.aadhaarbridge.com/kyc-sdk/_init",
  get_secret:
    baseurl + "/secretses/getSecret/ap-south-1/VmateAadharCredentials",
  compare_aadhar: baseurl + dev_env + platform + "/compareProfiledetails",
  Cognito: {
    signup_url: baseurl + dev_env + platform + "/signUp",
    confirmsignup_url: baseurl + dev_env + platform + "/confirmSignUp",
    signin_url: baseurl + dev_env + platform + "/signIn",
    resendcode_url: baseurl + dev_env + platform + "/resendConfirmationCode",
    changepassword_url: baseurl + dev_env + platform + "/changePassword",
    signout_url: baseurl + dev_env + platform + "/signOut",
    forgetpassword_url: baseurl + dev_env + platform + "/forgotPassword",
    confirmForgotPassword_url:
      baseurl + dev_env + platform + "/confirmForgotPassword",
    changeEmail_url: baseurl + dev_env + platform + "/updateEmail",
    confirmEmail_url: baseurl + dev_env + platform + "/verifyEmail",
    listUser: baseurl + dev_env + platform + "/usernameAvailability",
    update_profile_payload: baseurl + dev_env + platform + "/updateAttributes"
  }
};

//release configuration

const release = {
  s3: {
    BUCKET: "GVP",
    s3Download: baseurl + release_env + s3Platform + "/retrieveFileFromS3"
  },
  siteKey: "6LeCepsUAAAAAPJoAWAG97Nwosc_KukG-8syoZoj",
  graphqlendpoint: {
    URL: baseurl + release_env + platform + "/graphql"
  },
  profileImage: {
    ProfileUpload: baseurl + release_env + profilePlatform + "/upload",
    ProfileRetrieve: baseurl + release_env + profilePlatform + "/retrieve",
    ProfileRemove: baseurl + release_env + profilePlatform + "/delete",
    ProfileChange: baseurl + release_env + profilePlatform + "/replace"
  },
  aadharbrigge_url: "https://prod.aadhaarbridge.com/kyc-sdk/_init",

  get_secret:
    baseurl + "/secretses/getSecret/ap-south-1/VmateAadharCredentials",
  compare_aadhar: baseurl + release_env + platform + "/compareProfiledetails",
  Cognito: {
    signup_url: baseurl + release_env + platform + "/signUp",
    confirmsignup_url: baseurl + release_env + platform + "/confirmSignUp",
    signin_url: baseurl + release_env + platform + "/signIn",
    changepassword_url: baseurl + release_env + platform + "/changePassword",
    signout_url: baseurl + release_env + platform + "/signOut",
    resendcode_url:
      baseurl + release_env + platform + "/resendConfirmationCode",
    forgetpassword_url: baseurl + release_env + platform + "/forgotPassword",
    confirmForgotPassword_url:
      baseurl + release_env + platform + "/confirmForgotPassword",
    changeEmail_url: baseurl + release_env + platform + "/employerUpdateEmail",
    confirmEmail_url: baseurl + release_env + platform + "/employerVerifyEmail",
    listUser: baseurl + release_env + platform + "/employerListUsers"
  }
};
//Production configuration

const prod = {
  s3: {
    BUCKET: "GVP",
    s3Download: baseurl + prod_env + s3Platform + "/retrieveFileFromS3"
  },
  profileImage: {
    ProfileUpload: baseurl + prod_env + profilePlatform + "/upload",
    ProfileRetrieve: baseurl + prod_env + profilePlatform + "/retrieve",
    ProfileRemove: baseurl + prod_env + profilePlatform + "/delete",
    ProfileChange: baseurl + prod_env + profilePlatform + "/replace"
  },
  siteKey: "6LeCepsUAAAAAPJoAWAG97Nwosc_KukG-8syoZoj",
  graphqlendpoint: {
    URL: baseurl + prod_env + platform + "/graphql"
  },
  get_secret:
    baseurl + "/secretses/getSecret/ap-south-1/VmateAadharCredentials",
  compare_aadhar: baseurl + prod_env + platform + "/compareProfiledetails",
  aadharbrigge_url: "https://prod.aadhaarbridge.com/kyc-sdk/_init",

  Cognito: {
    signup_url: baseurl + prod_env + platform + "/signUp",
    confirmsignup_url: baseurl + prod_env + platform + "/confirmSignUp",
    signin_url: baseurl + prod_env + platform + "/signIn",
    changepassword_url: baseurl + prod_env + platform + "/changePassword",
    signout_url: baseurl + prod_env + platform + "/signOut",
    resendcode_url: baseurl + prod_env + platform + "/resendConfirmationCode",
    forgetpassword_url: baseurl + prod_env + platform + "/forgotPassword",
    confirmForgotPassword_url:
      baseurl + prod_env + platform + "/confirmForgotPassword",
    changeEmail_url: baseurl + prod_env + platform + "/employerUpdateEmail",
    confirmEmail_url: baseurl + prod_env + platform + "/employerVerifyEmail",
    listUser: baseurl + prod_env + platform + "/employerListUsers"
  }
};
const configuration =
  process.env.REACT_APP_HOST_ENV === "prod"
    ? prod
    : process.env.REACT_APP_HOST_ENV === "release"
    ? release
    : dev;
export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...configuration
};
