import React from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import Gravatar from "react-gravatar";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icon
import Lock from "@material-ui/icons/Lock";
// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import config from "../../config.js"; //Configuration file
//Custom components
import LoaderButton from "Custom-component/LoaderButton";
import CustomInput from "Custom-component/CustomInput/CustomInput.jsx";

//styles
import lockScreenPageStyle from "assets/jss/material-dashboard-pro-react/views/lockScreenPageStyle.jsx";

var sha256 = require("js-sha256"); //Encryption plugin

class LockScreen extends React.Component {
	constructor(props) {
		super(props);
		// we use this to make the card to appear after the page has been rendered
		this.state = {
			cardAnimaton: "cardHidden",
			lockscreen_userpassword: "",
			lockscreen_userpasswordState: "",
			alert: null,
			passwordErrorMessage: "",
			errorMessage: "",
			isLoading: false
		};

		this.autoCloseAlert = this.autoCloseAlert.bind(this);
		this.successhideAlert = this.successhideAlert.bind(this);
		this.failhideAlert = this.failhideAlert.bind(this);
		this.warningWithConfirmMessage = this.warningWithConfirmMessage.bind(this);
	}
	autoCloseAlert(name) {
		this.setState({
			alert: (
				<SweetAlert
					success
					style={{ display: "block", marginTop: "-200px", color: "#000000" }}
					title="Your session refreshed successfully!"
					onConfirm={() => this.successhideAlert()}
					showConfirm={false}
				>
					<h4>Welcome {name} </h4>
				</SweetAlert>
			)
		});
		setTimeout(this.successhideAlert, 2000);
	}

	warningWithConfirmMessage() {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{ display: "block", marginTop: "-200px", color: "#000000" }}
					title="Login Failed"
					onConfirm={() => this.failhideAlert()}
					onCancel={() => this.failhideAlert()}
					confirmBtnCssClass={
						this.props.classes.button + " " + this.props.classes.danger
					}
					confirmBtnText="Try Again"
				>
					{this.state.errorMessage}
				</SweetAlert>
			)
		});
	}
	successhideAlert() {
		this.setState({
			alert: null
		});

		this.props.history.push("/dashboard");
	}

	failhideAlert() {
		this.setState({
			alert: null
		});
		this.props.history.push("/auth/lockscreen");
	}

	// function that verifies if a string has a given length or not
	verifyLength(value, length) {
		if (value.length >= length) {
			return true;
		}
		return false;
	}
	password_validate(value) {
		var result = {
			capital: /[A-Z]/,
			small: /[a-z]/,
			digit: /[0-9]/,
			special: /[!@#$%^&+-.]/,
			full: /[A-Za-z0-9!@#$%^&+-.]{8,13}$/
		};
		return (
			result.capital.test(value) &&
			result.small.test(value) &&
			result.digit.test(value) &&
			result.special.test(value) &&
			result.full.test(value)
		);
	}
	change(event, stateName, type, stateNameEqualTo) {
		switch (type) {
			case "length":
				if (this.verifyLength(event.target.value, stateNameEqualTo)) {
					this.setState({ [stateName + "State"]: "success" });
				} else {
					this.setState({ [stateName + "State"]: "error" });
				}
				break;
			case "password":
				if (event.target.value) {
					if (this.password_validate(event.target.value)) {
						this.setState({ [stateName + "State"]: "success" });
					} else {
						this.setState({
							passwordErrorMessage:
								"Password should contains at least one capital letter,small letter,special character,numericvalue",
							[stateName + "State"]: "error"
						});
					}
				} else {
					this.setState({
						passwordErrorMessage: "Password is required",
						[stateName + "State"]: "error"
					});
				}
				break;
			default:
				break;
		}
		switch (type) {
			case "checkbox":
				this.setState({ [stateName]: event.target.checked });
				break;
			default:
				this.setState({ [stateName]: event.target.value });
				break;
		}
	}

	handleClick = async event => {
		event.preventDefault();
		this.setState({ isLoading: true });
		try {
			const signin_payload = {
				username: localStorage.getItem("username"),
				password: sha256(this.state.lockscreen_userpassword)
			};
			var headers = {
				"Content-Type": "application/json"
			};
			axios
				.post(config.Cognito.signin_url, signin_payload, { headers: headers })
				.then(response => {
					if (response.data.data === null) {
						if (response.data.errors.error_message === "GBESM10001") {
							this.setState({
								isLoading: false
							});
							this.props.history.push("/auth");
							localStorage.setItem("userlogged", false);
						} else {
							localStorage.setItem("userlogged", false);
							this.setState({
								isLoading: false,
								errorMessage: response.data.errors.error_message
							});
							this.warningWithConfirmMessage();
						}
					} else {
						this.setState({
							isLoading: false
						});
						localStorage.setItem("accessToken", response.data.data.AccessToken);
						//this.props.getuserdata(user_data);
						this.autoCloseAlert(response.data.data.Name);
						localStorage.setItem("userlogged", true);
					}
				})
				.catch(err => {
					localStorage.setItem("userlogged", false);
					this.setState({
						isLoading: false,
						errorMessage:
							"We experiencing difficulties with connectivity of the application, Please try again later"
					});
					this.warningWithConfirmMessage();
				});
		} catch (e) {
			this.setState({
				isLoading: false,
				errorMessage:
					"We experiencing difficulties with connectivity of the application, Please try again later"
			});
			this.warningWithConfirmMessage();
		}
	};

	enable() {
		if (this.state.lockscreen_userpasswordState === "success") {
			return false;
		} else {
			return true;
		}
	}
	componentDidMount() {
		// we add a hidden class to the card and after 700 ms we delete it and the transition appears
		this.timeOutFunction = setTimeout(
			function() {
				this.setState({ cardAnimaton: "" });
			}.bind(this),
			700
		);
	}
	componentWillUnmount() {
		clearTimeout(this.timeOutFunction);
		this.timeOutFunction = null;
	}
	render() {
		const { classes } = this.props;
		return (
			<div className={classes.container}>
				<GridContainer justify="center">
					<GridItem xs={12} sm={6} md={6}>
						<form>
							<Card
								profile
								className={
									classes.customCardClass +
									" " +
									classes[this.state.cardAnimaton]
								}
							>
								<CardAvatar profile className={classes.cardAvatar}>
									<Gravatar
										email={
											!localStorage.getItem("useremail")
												? "mp"
												: localStorage.getItem("useremail")
										}
										className={classes.avatarImg}
										default="mp"
										size={130}
									/>
								</CardAvatar>
								<CardBody profile>
									<h4 className={classes.cardTitle}>
										{!localStorage.getItem("name")
											? "undefined"
											: localStorage.getItem("name")}
									</h4>

									<CustomInput
										success={
											this.state.lockscreen_userpasswordState === "success"
										}
										error={this.state.lockscreen_userpasswordState === "error"}
										id="lockscreen_userpassword"
										name="Lockscreen_userpassword"
										formControlProps={{
											fullWidth: true,
											className: classes.customFormControlClasses
										}}
										helpText={
											this.state.lockscreen_userpasswordState === "error"
												? this.state.passwordErrorMessage
												: null
										}
										inputProps={{
											onChange: event =>
												this.change(
													event,
													"lockscreen_userpassword",
													"password",
													3
												),
											// onChange:this.handleInputChange,
											type: "password",

											startAdornment: (
												<InputAdornment
													position="start"
													className={classes.inputAdornment}
												>
													<Lock className={classes.inputAdornmentIcon} />
												</InputAdornment>
											),
											placeholder: "Enter Your Password"
										}}
									/>
								</CardBody>
								<CardFooter className={classes.justifyContentCenter}>
									{/* <Button color="info" type="submit" round onClick={this.handleClick}>
										Unlock
									</Button> */}
									<LoaderButton
										id="lock_button"
										disabled={this.enable()}
										onClick={this.handleClick}
										type="submit"
										isLoading={this.state.isLoading}
										text="Unlock"
										loadingText="Unlocking..."
									/>
								</CardFooter>
							</Card>
						</form>
						{this.state.alert}
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

LockScreen.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(lockScreenPageStyle)(LockScreen);
