import React from "react";
import { Query } from "react-apollo";
import { Redirect } from "react-router-dom";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//query
import DocumentCountQuery from "../../Graphql/Documentquery";
//File imports
import Dashboardpage from "./Dashboardpage.jsx";
import Errorpage from "../Pages/errorPage.jsx";
import ScreenLoading from "../LoadingScreen.jsx";

//Employment query
const Dashboardquery = ({
  username = localStorage.getItem("username"),
  history,
  client
}) => (
  <Query
    query={DocumentCountQuery}
    variables={{ username }}
    options={{ pollInterval: 1000 }}
  >
    {({ loading, data, error }) => {
      if (loading) {
        return (
          <GridContainer justify="center">
            <GridItem xs={10} sm={8} md={8}>
              <ScreenLoading />
            </GridItem>
          </GridContainer>
        );
      }
      // To show error page on failure to retrieve data from backend
      if (error)
        return (
          <div>
            <Errorpage />
          </div>
        );
      localStorage.setItem(
        "isNewUser",
        !data.getApprovedCount.count && !data.getRejectedCount.count
      );
      return localStorage.getItem("aadhar_verified") === "0" ? ( // redirect to new user page when the user is new
        <Redirect to="/identityverify" />
      ) : (
        <div>
          <Dashboardpage
            help={
              !data.getApprovedCount.count && !data.getRejectedCount.count
                ? true
                : false
            }
            key={username}
            count={data}
            history={history}
            client={client}
          />
        </div>
      );
    }}
  </Query>
);

export default Dashboardquery;
