import Dashboard from "../Container/Dashboard/Dashboard.jsx";
import Profile from "../Container/Employer Profile/profile.jsx";
import History from "../Container/History/HistoryQuerypage";

import ViewRequest from "../Container/RequestApproval/Request.jsx";
import Profileverfication from "../Container/ViewProfileHistory/EmployeeProfileRetrievequery.jsx";
import MyAllEmployee from "../Container/MyEmployee/MyAllEmployee.jsx";

import EmployeeOnboardingQuery from "../Container/Onboarding/EmployeeOnboardingQuery.jsx";
// import Manageuser from "../Container/User-manangement/Manageuser.jsx";
// import UserRolesGridList from "../Container/Roles and Permission/UserRolesGridList.jsx";
//import Integration from "../Container/HRMSIntegration/HRMSintegration.jsx";
import Billings from "../Container/Billings/Billings.jsx";
import AadharIntegrationQuery from "../Container/Aadhar/AadharIntegrationQuery";
import EmployerActivityQuery from "../Container/Activity/EmployerActivityQuery";
// import Help from '../Container/Help/Help.jsx';
// import FAQ from '../Container/FAQS/FAQS.jsx';
// import Feedback from '../Container/Feedback/Feedback.jsx';
// import/ Newuserpage from '../Container/Pages/newuserpage.jsx';
// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import HelpOutline from "@material-ui/icons/HelpOutline";
import Announcement from "@material-ui/icons/Announcement";
import WatchLater from "@material-ui/icons/WatchLater";
import InsertEmoticon from "@material-ui/icons/InsertEmoticon";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Person from "@material-ui/icons/Person";
import ShowChart from "@material-ui/icons/ShowChart";
// import Group from "@material-ui/icons/Group";
import CreditCard from "@material-ui/icons/CreditCard";
import Http from "@material-ui/icons/Http";

var authenticatedroutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard
  },
  {
    path: "/profile",
    name: "Authority Profile",
    icon: AccountCircle,
    component: Profile
  },
  {
    collapse: true,
    path: "/employee",
    name: "Employee",
    state: "openEmployee",
    icon: Person,
    views: [
      {
        path: "/employee/viewrequest",
        name: "Verification Request",
        mini: "VR",
        component: ViewRequest
      },
      {
        path: "/employee/onboardemployee",
        name: "Onboard Employee",
        mini: "OE",
        component: EmployeeOnboardingQuery
      },
      {
        path: "/employee/myemployee",
        name: "Employee",
        mini: "EM",
        component: MyAllEmployee
      },
      {
        path: "/employee/employeeprofileverification",
        name: "Employment History",
        mini: "EH",
        component: Profileverfication
      }
    ]
  },
  // {
  //   //: future
  //   collapse: true,
  //   path: "/usermanagement",
  //   name: "User Managements",
  //   state: "openUser",
  //   icon: Group,
  //   views: [
  //     {
  //       path: "/usermanagement/manageusers",
  //       name: "Users List",
  //       mini: "UL",
  //       component: Manageuser
  //     },
  //     {
  //       path: "/usermanagement/userpermissions",
  //       name: "Roles and Permissions",
  //       mini: "RP",
  //       component: UserRolesGridList
  //     }
  //   ]
  // },

  {
    path: "/history",
    name: "Verification History",
    icon: WatchLater,
    component: History
  },
  {
    path: "/allactivities",
    name: "Activity",
    icon: ShowChart,
    component: EmployerActivityQuery
  },
  {
    path: "/integration",
    name: "Integration",
    icon: Http,
    component: Billings
  },
  {
    path: "/billings",
    name: "Billings",
    icon: CreditCard,
    component: Billings
  },
  { path: "/help", name: "Help", icon: HelpOutline, component: Billings },
  { path: "/faq", name: "FAQs", icon: Announcement, component: Billings },
  {
    path: "/identityverify",
    name: "Aadhar",
    aadharCollapse: true,
    icon: InsertEmoticon,
    component: AadharIntegrationQuery
  },
  {
    path: "/feedback",
    name: "Feedback",
    icon: InsertEmoticon,
    component: Billings
  },
  { redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" },
  { redirect: true, path: "*", pathTo: "/404", name: "NotFound" }
];

export default authenticatedroutes;
