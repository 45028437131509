import React from "react";
import { ApolloConsumer } from "react-apollo";
import Dashboardquery from "./Dashboardquery"; //Query page

// initiate client instances
class Dashboard extends React.Component {
	render() {
		return (
			<ApolloConsumer>
				{client => (
					<Dashboardquery history={this.props.history} client={client} />
				)}
			</ApolloConsumer>
		);
	}
}

export default Dashboard;
