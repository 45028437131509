import gql from "graphql-tag";
const ProfileviewRequestMutation = gql`
  mutation ProfileValidationQuery(
    $username: String!
    $employee_username: String!
    $ip: String!
    $browser: String!
    $version: String!
    $device_id: String!
  ) {
    sendProfileValidationRequest(
      username: $username
      employee_username: $employee_username
      ip: $ip
      browser: $browser
      version: $version
      device_id: $device_id
    ) {
      message
      __typename
    }
    __typename
  }
`;
export default ProfileviewRequestMutation;
