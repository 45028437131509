import React from "react";
import { Query } from "react-apollo";
import { ApolloConsumer } from "react-apollo";
import ScreenLoading from "../LoadingScreen.jsx";
import { Redirect } from "react-router-dom";
//components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//file import
import Profileverification from "./Profileverification.jsx";
import Errorpage from "../Pages/errorPage.jsx";
import Datanotfound from "../Pages/Datanotfound.jsx";
//queries
import Profilevalidationquery from "../../Graphql/Profilevalidationquery";

//Employment query
const Profilequery = ({
  username = localStorage.getItem("username"),
  client
}) => (
  <Query query={Profilevalidationquery} variables={{ username }}>
    {({ loading, data, error }) => {
      if (loading) {
        return (
          <GridContainer justify="center">
            <GridItem xs={10} sm={8} md={8}>
              <ScreenLoading />
            </GridItem>
          </GridContainer>
        );
      }
      // To show error page on failure to retrieve data from backend
      if (error)
        return (
          <div>
            <Errorpage />
          </div>
        );
      return localStorage.getItem("aadhar_verified") === "0" ? ( // redirect to new user page when the user is new
        <Redirect to="/identityverify" />
      ) : (
        <div>
          {data.viewProfileValidation.profileValidation === null ? (
            //if there is no employee profile history found
            <Datanotfound
              title={"No Employment History Found"}
              message={
                "You will get this data if You Request your Employee to share their Employment Data with you"
              }
              button={true}
              link={"/dashboard"}
              buttontext={"Request Employee"}
            />
          ) : (
            <Profileverification
              key={username}
              employee_profile={data.viewProfileValidation.profileValidation}
              client={client}
            />
          )}
        </div>
      );
    }}
  </Query>
);

class Profile extends React.Component {
  render() {
    return (
      <ApolloConsumer>
        {client => <Profilequery history={this.props} client={client} />}
      </ApolloConsumer>
    );
  }
}

export default Profile;
