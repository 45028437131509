import gql from "graphql-tag";

const EmployerHistoryRetrieveQuery = gql`
  query historyquery($username: String!) {
    getEmployerHistory(username: $username) {
      history {
        employer_username
        employer_name
        employee_username
        employee_name
        file_hash
        organization_name
        employee_id
        designation
        department
        transaction_id
        transaction_details
        joining_date
        end_date
        action
        added_on
      }
    }
  }
`;

export default EmployerHistoryRetrieveQuery;
