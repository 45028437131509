import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import { Router, Route, Switch } from "react-router-dom";
import indexRoutes from "./routes/index.jsx";
import "assets/scss/material-dashboard-pro-react.css";
import * as serviceWorker from "./serviceWorker";
import Notfound from "./Container/Pages/Notfound.jsx";
import history from "./history.js";

// const UserAuthContext = React.createContext({});

ReactDOM.render(
	<Provider store={store}>
		<Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
			<Switch>
				<Route exact path="/404" component={Notfound} />
				{indexRoutes.map((prop, key) => {
					return (
						<Route path={prop.path} component={prop.component} key={key} />
					);
				})}
			</Switch>
		</Router>
	</Provider>,
	document.getElementById("root")
);
serviceWorker.register();
