import React, { Component } from "react";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
//images
import ComingSoon from "assets/img/ComingSoon.png";
//styles
import billingStyle from "./billingStlyle.css";

class Billings extends Component {
  render() {
    return (
      <div>
        <GridContainer justify="center">
          <div className="row">
            <div className="cell">
              <img src={ComingSoon} alt="..." className="img" />
            </div>
          </div>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(billingStyle)(Billings);
