import React, { Suspense } from "react";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tour from "reactour";
import { getGlobal, resetGlobal } from "reactn";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
//core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
//@material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
import FileCopyOutlined from "@material-ui/icons/FileCopyOutlined";
// Custom components
import Badge from "Custom-component/Badge/Badge.jsx";
//File Import
import PictureUpload from "../../Custom-component/File upload/PictureUpload.jsx";
import config from "../../config";
import ScreenLoading from "../LoadingScreen.jsx";
//Styles
import userProfileStyles from "./profilepagestyle.jsx";
// Date formater
var dateFormat = require("dateformat");
const Editprofile = React.lazy(() => import("./Editprofile.jsx"));

class Profilepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // show form variable
      show_editform: false,
      show_viewform: false,

      // employer identity variables
      employer_username: this.props.user.username,
      employer_unique_id: !this.props.user.hyperledger_id
        ? this.props.user.username
        : this.props.user.hyperledger_id,
      employer_aadhar_number: this.props.user.aadhar_number,
      employer_aadhar_verified: this.props.user.aadhar_verified,
      employer_email_verified:
        this.props.user.email_verified === "0" ? false : true,
      // employer Personal data variables
      employer_fullname: this.props.user.name,
      employer_gender: this.props.user.gender,
      employer_dob: dateFormat(
        new Date(this.props.user.birthdate),
        "dd/mm/yyyy"
      ),
      employer_dob_edit: this.props.user.birthdate,
      employer_personal_mailid: this.props.user.personal_email,
      employer_personal_contactnumber: this.props.user.personal_phone_number,

      // employer company details variables
      employer_pan_number: this.props.user.PAN,
      employer_din_number: !this.props.user.DIN ? "-" : this.props.user.DIN,
      employer_company_name: this.props.user.company_name,
      employer_company_address: this.props.user.company_address,
      employer_company_email: this.props.user.email,
      employer_company_contactnumber: this.props.user.phone_number,
      number_of_documents_verified: this.props.getdocCount.count,
      number_of_current_employees: this.props.getempCount.count,
      urlcopied: "copy",
      //change profile
      profile_image_hash: this.props.profile_image_hash,
      fileContent: null,
      loaderDisplay: true,
      error_message: "",
      bc: false,

      // react tour variables
      help: false,
      steps: [
        {
          content: "Click camera icon to update the profile picture",
          position: "left",
          selector: ".UpdateprofilePic"
        },

        {
          content: "Click Here! To edit the profile",
          position: "top",
          selector: ".editClass"
        }
      ] // Steps to display in tour
    };
  }
  //Show the Edit profile page
  editprofile = () => {
    this.setState({
      show_editform: true,
      show_viewform: false,
      urlcopied: "copy",
      help: false
    });
  };

  componentDidMount() {
    // to display react tour for new user
    this.setState({
      help: JSON.parse(localStorage.getItem("isNewUser")),
      prevhelp: JSON.parse(localStorage.getItem("isNewUser"))
    });
    if (this.props.user.profile_image_hash) {
      try {
        const retrieveProfileData = {
          userName: this.state.employer_username,
          hasedFilename: this.props.user.profile_image_hash
        };
        axios
          .post(config.profileImage.ProfileRetrieve, retrieveProfileData)
          .then(response => {
            if (response.data.data) {
              this.setState({
                show_viewform: true,
                loaderDisplay: false,
                fileContent:
                  "data:image/jpeg;base64," + response.data.data.fileContent
              });
            } else {
              this.setState({ show_viewform: true, loaderDisplay: false });
            }
          })
          .catch(err => {
            this.setState({ show_viewform: true, loaderDisplay: false });
          });
      } catch (e) {
        this.setState({ show_viewform: true, loaderDisplay: false });
      }
    } else {
      this.setState({ show_viewform: true, loaderDisplay: false });
    }
  }
  //Show the view profile page
  viewprofile = (email, isVerified) => {
    resetGlobal();
    this.setState({
      show_editform: false,
      show_viewform: true,
      employer_company_email: email,
      employer_email_verified:
        isVerified === ""
          ? this.state.employer_email_verified
          : isVerified === "0"
          ? false
          : true,
      prevhelp: false
    });
  };

  // function that sets notification display time
  showNotification(place) {
    if (!this.state[place]) {
      var x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(
        function() {
          x[place] = false;
          this.setState(x);
        }.bind(this),
        6000
      );
    }
  }
  //getting error message
  back = e => {
    if (e.errorMessage !== "") {
      this.setState({
        error_message: e.errorMessage
      });
      this.showNotification("bc");
    }
  };
  // function  to view profile page
  updateProfile = data => {
    this.setState({
      showEdit: false,
      employer_fullname: data.employer_fullname,
      employer_personal_contactnumber: data.employer_personal_contactnumber,
      employer_dob: data.employer_dob,
      employer_dob_edit: dateFormat(
        new Date(data.employer_dob_edit),
        "yyyy-mm-dd"
      ),
      employer_personal_mailid: data.employer_personal_mailid,
      employer_gender: data.employer_gender,
      employer_company_name: this.state.employer_company_name,
      employer_company_address: data.employer_company_address,
      employer_company_email: data.employer_company_email,
      employer_company_contactnumber: data.employer_company_contactnumber,
      employer_email_verified:
        data.isVerified === ""
          ? this.state.employer_email_verified
          : data.isVerified === "0"
          ? false
          : true,
      employer_pan_number: data.employer_pan_number,
      employer_din_number: !data.employer_din ? "-" : data.employer_din,
      show_editform: false,
      show_viewform: true,
      urlcopied: "copy"
    });
  };
  // function to close the tour
  closeTour = () => {
    resetGlobal();
    this.setState({ prevhelp: true, help: false });
  };

  render() {
    const { classes } = this.props;
    const { steps } = this.state;
    return (
      <div>
        <Snackbar
          place="bc"
          color="danger"
          icon={AddAlert}
          message={this.state.error_message}
          open={this.state.bc}
          closeNotification={() => this.setState({ bc: false })}
          close
        />
        {this.state.loaderDisplay ? <ScreenLoading /> : null}
        {// view profile page
        this.state.show_viewform ? (
          <div>
            {/* react tour */}
            <Tour
              onRequestClose={this.closeTour}
              steps={steps}
              isOpen={getGlobal().value ? true : this.state.help ? true : false}
              accentColor="#FFC90D"
            />
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={11}>
                <Card profile>
                  <CardAvatar profile className="UpdateprofilePic">
                    <PictureUpload
                      profile_image_hash={this.props.user.profile_image_hash}
                      email={this.state.employer_company_email}
                      fileContent={this.state.fileContent}
                      data={this.back}
                    />
                  </CardAvatar>
                  <CardBody profile>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={11}>
                        <h5 className={classes.cardTitle}>
                          {this.state.employer_company_name}
                        </h5>
                        <h4 className={classes.cardCategory}>
                          {this.state.employer_fullname}
                        </h4>
                        <p className={classes.description}>
                          This Profile is based on the details you have give on
                          registration...You can update your personal details.
                        </p>
                      </GridItem>
                    </GridContainer>
                    {/* Company details of the employer */}
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <Card className={classes.cardSignup}>
                          <p className={classes.cardheader}>Company Details</p>
                          <CardBody className={classes.cardBody}>
                            <GridContainer justify="center">
                              <GridItem xs={12} sm={12} md={6}>
                                <GridContainer justify="flex-start">
                                  <GridItem xs={9} sm={4} md={4}>
                                    <h4 className={classes.cardsideHeader}>
                                      Name
                                    </h4>
                                  </GridItem>
                                  <GridItem xs={1} sm={1} md={1}>
                                    <h4>:</h4>
                                  </GridItem>
                                  <GridItem xs={12} sm={6} md={6}>
                                    <h4> {this.state.employer_company_name}</h4>
                                  </GridItem>
                                </GridContainer>
                                <GridContainer justify="flex-start">
                                  <GridItem xs={9} sm={4} md={4}>
                                    <h4 className={classes.cardsideHeader}>
                                      Address
                                    </h4>
                                  </GridItem>
                                  <GridItem xs={1} sm={1} md={1}>
                                    <h4>:</h4>
                                  </GridItem>
                                  <GridItem xs={12} sm={6} md={6}>
                                    <h4>
                                      {this.state.employer_company_address}
                                    </h4>
                                  </GridItem>
                                </GridContainer>
                                <GridContainer justify="flex-start">
                                  <GridItem xs={9} sm={4} md={4}>
                                    <h4 className={classes.cardsideHeader}>
                                      Email
                                    </h4>
                                  </GridItem>
                                  <GridItem xs={1} sm={1} md={1}>
                                    <h4>:</h4>
                                  </GridItem>
                                  <GridItem xs={12} sm={6} md={6}>
                                    <h4>
                                      {this.state.employer_company_email}
                                      {/* if employer not verified their email id */}
                                      {this.state
                                        .employer_email_verified ? null : (
                                        <Badge
                                          color="info"
                                          tooltip={true}
                                          tooltipContent={
                                            "Your Email is not verified.Contact Administrator to verify it."
                                          }
                                        >
                                          {"unverified"}
                                        </Badge>
                                      )}
                                    </h4>
                                  </GridItem>
                                </GridContainer>
                                <GridContainer justify="flex-start">
                                  <GridItem xs={9} sm={4} md={4}>
                                    <h4 className={classes.cardsideHeader}>
                                      PAN <small>(Business)</small>
                                    </h4>
                                  </GridItem>
                                  <GridItem xs={1} sm={1} md={1}>
                                    <h4>:</h4>
                                  </GridItem>
                                  <GridItem xs={12} sm={6} md={6}>
                                    <h4>{this.state.employer_pan_number}</h4>
                                  </GridItem>
                                </GridContainer>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6}>
                                <GridContainer justify="flex-start">
                                  <GridItem xs={9} sm={4} md={4}>
                                    <h4 className={classes.cardsideHeader}>
                                      DIN Number
                                    </h4>
                                  </GridItem>
                                  <GridItem xs={1} sm={1} md={1}>
                                    <h4>:</h4>
                                  </GridItem>
                                  <GridItem xs={12} sm={6} md={6}>
                                    <h4>{this.state.employer_din_number}</h4>
                                  </GridItem>
                                </GridContainer>
                                <GridContainer justify="flex-start">
                                  <GridItem xs={9} sm={4} md={4}>
                                    <h4 className={classes.cardsideHeader}>
                                      Phone Number
                                    </h4>
                                  </GridItem>
                                  <GridItem xs={1} sm={1} md={1}>
                                    <h4>:</h4>
                                  </GridItem>
                                  <GridItem xs={12} sm={6} md={6}>
                                    <h4>
                                      {
                                        this.state
                                          .employer_company_contactnumber
                                      }
                                    </h4>
                                  </GridItem>
                                </GridContainer>
                                <GridContainer justify="flex-start">
                                  <GridItem xs={9} sm={4} md={4}>
                                    <h4 className={classes.cardsideHeader}>
                                      Number of Documents Verified
                                    </h4>
                                  </GridItem>
                                  <GridItem xs={1} sm={1} md={1}>
                                    <h4>:</h4>
                                  </GridItem>
                                  <GridItem xs={12} sm={6} md={6}>
                                    <h4>
                                      {this.state.number_of_documents_verified}
                                    </h4>
                                  </GridItem>
                                </GridContainer>
                                <GridContainer justify="flex-start">
                                  <GridItem xs={9} sm={4} md={4}>
                                    <h4 className={classes.cardsideHeader}>
                                      Number of Employees
                                    </h4>
                                  </GridItem>
                                  <GridItem xs={1} sm={1} md={1}>
                                    <h4>:</h4>
                                  </GridItem>
                                  <GridItem xs={12} sm={6} md={6}>
                                    <h4>
                                      {this.state.number_of_current_employees}
                                    </h4>
                                  </GridItem>
                                </GridContainer>
                              </GridItem>
                            </GridContainer>
                          </CardBody>
                        </Card>
                      </GridItem>
                    </GridContainer>
                    {/* Super admin details of the employer */}
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={12}>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={6}>
                            <Card className={classes.cardSignup}>
                              <p className={classes.cardheader}>
                                Admin Authority Details
                              </p>
                              <CardBody className={classes.cardBody}>
                                <GridContainer justify="flex-start">
                                  <GridItem xs={9} sm={4} md={4}>
                                    <h4 className={classes.cardsideHeader}>
                                      Name
                                    </h4>
                                  </GridItem>
                                  <GridItem xs={1} sm={1} md={1}>
                                    <h4>:</h4>
                                  </GridItem>
                                  <GridItem xs={12} sm={6} md={6}>
                                    <h4> {this.state.employer_fullname}</h4>
                                  </GridItem>
                                </GridContainer>
                                <GridContainer justify="flex-start">
                                  <GridItem xs={9} sm={4} md={4}>
                                    <h4 className={classes.cardsideHeader}>
                                      Gender
                                    </h4>
                                  </GridItem>
                                  <GridItem xs={1} sm={1} md={1}>
                                    <h4>:</h4>
                                  </GridItem>
                                  <GridItem xs={12} sm={6} md={6}>
                                    <h4> {this.state.employer_gender}</h4>
                                  </GridItem>
                                </GridContainer>
                                <GridContainer justify="flex-start">
                                  <GridItem xs={9} sm={4} md={4}>
                                    <h4 className={classes.cardsideHeader}>
                                      DOB
                                    </h4>
                                  </GridItem>
                                  <GridItem xs={1} sm={1} md={1}>
                                    <h4>:</h4>
                                  </GridItem>
                                  <GridItem xs={12} sm={6} md={6}>
                                    <h4> {this.state.employer_dob}</h4>
                                  </GridItem>
                                </GridContainer>
                              </CardBody>
                            </Card>
                          </GridItem>

                          {/* Identity details of the employer */}
                          <GridItem xs={12} sm={12} md={6}>
                            <Card className={classes.cardSignup}>
                              <p className={classes.cardheader}>
                                Identity Details
                              </p>
                              <CardBody className={classes.cardBody}>
                                <GridContainer justify="flex-start">
                                  <GridItem xs={9} sm={4} md={4}>
                                    <h4 className={classes.cardsideHeader}>
                                      Unique Id
                                    </h4>
                                  </GridItem>
                                  <GridItem xs={1} sm={1} md={1}>
                                    <h4>:</h4>
                                  </GridItem>
                                  <GridItem xs={12} sm={6} md={6}>
                                    <div
                                      style={{
                                        width: "200px",
                                        overflow: "hidden"
                                      }}
                                    >
                                      <IconButton
                                        style={{
                                          float: "right",
                                          backgroundColor: "#FFC90D",
                                          "&:hover ,&:focus": {
                                            color: "black"
                                          },
                                          color: "#ffffff"
                                        }}
                                        className="uniqueidClass"
                                        id="generateurl_urlcopy_iconbutton"
                                        key="employer_unique_id"
                                        aria-label="employer_unique_id"
                                        color="inherit"
                                      >
                                        <CopyToClipboard
                                          text={this.state.employer_unique_id}
                                          onCopy={() =>
                                            this.setState({
                                              urlcopied: "copied"
                                            })
                                          }
                                        >
                                          <Tooltip
                                            id="employer_unique_id_tooltip"
                                            title={this.state.urlcopied}
                                            placement="top"
                                          >
                                            <FileCopyOutlined
                                              style={{
                                                color: "#fff"
                                              }}
                                            />
                                          </Tooltip>
                                        </CopyToClipboard>
                                      </IconButton>
                                      <h4 className={classes.uniqueid_text}>
                                        {this.state.employer_unique_id}
                                      </h4>
                                    </div>
                                  </GridItem>
                                </GridContainer>
                                <GridContainer justify="flex-start">
                                  <GridItem xs={9} sm={4} md={4}>
                                    <h4 className={classes.cardsideHeader}>
                                      Username
                                    </h4>
                                  </GridItem>
                                  <GridItem xs={1} sm={1} md={1}>
                                    <h4>:</h4>
                                  </GridItem>
                                  <GridItem xs={12} sm={6} md={6}>
                                    <h4> {this.state.employer_username}</h4>
                                  </GridItem>
                                </GridContainer>
                                <GridContainer justify="flex-start">
                                  <GridItem xs={9} sm={4} md={4}>
                                    <h4 className={classes.cardsideHeader}>
                                      National Identity Number
                                    </h4>
                                  </GridItem>
                                  <GridItem xs={1} sm={1} md={1}>
                                    <h4>:</h4>
                                  </GridItem>
                                  <GridItem xs={12} sm={6} md={6}>
                                    <h4>
                                      {this.state.employer_aadhar_number}{" "}
                                    </h4>
                                  </GridItem>
                                </GridContainer>
                              </CardBody>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                    {/* Personal contact and Email of employer  if Exist */}
                    {!this.state.employer_personal_contactnumber &&
                    !this.state.employer_personal_mailid ? null : (
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <Card className={classes.cardSignup}>
                            <p className={classes.cardheader}>
                              Personal Details
                            </p>
                            <CardBody className={classes.cardBody}>
                              <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={6}>
                                  <GridContainer justify="flex-start">
                                    <GridItem xs={9} sm={4} md={4}>
                                      <h4 className={classes.cardsideHeader}>
                                        Personal Phone Number
                                      </h4>
                                    </GridItem>
                                    <GridItem xs={1} sm={1} md={1}>
                                      <h4>:</h4>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                      <h4>
                                        {!this.state
                                          .employer_personal_contactnumber
                                          ? "-"
                                          : this.state
                                              .employer_personal_contactnumber}
                                      </h4>
                                    </GridItem>
                                  </GridContainer>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                  <GridContainer justify="flex-start">
                                    <GridItem xs={9} sm={4} md={4}>
                                      <h4 className={classes.cardsideHeader}>
                                        Personal Email
                                      </h4>
                                    </GridItem>
                                    <GridItem xs={1} sm={1} md={1}>
                                      <h4>:</h4>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                      <h4>
                                        {!this.state.employer_personal_mailid
                                          ? "-"
                                          : this.state.employer_personal_mailid}
                                      </h4>
                                    </GridItem>
                                  </GridContainer>
                                </GridItem>
                              </GridContainer>
                            </CardBody>
                          </Card>
                        </GridItem>
                      </GridContainer>
                    )}
                    <Button
                      color="info"
                      className="editClass"
                      onClick={this.editprofile}
                    >
                      Edit Profile
                    </Button>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        ) : null}
        {// Edit profile page
        this.state.show_editform ? (
          <div>
            <Suspense fallback={<ScreenLoading />}>
              <Editprofile
                userdata={this.state}
                close={this.viewprofile}
                updateProfile={this.updateProfile}
                help={this.state.prevhelp}
                // history={this.props.history}
              />
            </Suspense>
          </div>
        ) : null}
      </div>
    );
  }
}
export default withStyles(userProfileStyles)(Profilepage);
