// ##############################
// // // RegisterPage view styles
// #############################

const notfoundstyle = {
  container: {
    // background: "#010C4E !important",
    // textAlign: "center",
    position: 'relative',
    zIndex: '3',
    paddingTop: '15px',
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
    height: '100%',
    '@media (min-width: 768px)': {
      width: '900px'
    },
    '@media (min-width: 992px)': {
      width: '1100px'
    },
    '@media (min-width: 1200px)': {
      width: '1600px'
    },
    '&:before,&:after': {
      display: 'table',
      content: '" "'
    },
    '&:after': {
      clear: 'both'
    }
  },
  cardClasses: {
    boxShadow: '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
    marginBottom: '100px',
    padding: '10px 10px !important',
    textAlign: 'center',
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    height: '100%',
    margin: '15px 0 !important',
    // boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    borderRadius: '6px',
    color: 'rgba(0, 0, 0, 0.87)',
    background: '#2DBEFF !important'
  },
  center: {
    textAlign: 'center'
  },
  right: {
    textAlign: 'right'
  },
  left: {
    textAlign: 'left'
  },
  form: {
    padding: '0 20px',
    position: 'relative'
  },
  img: {
    width: '95%',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    border: '0'
  },

  customFormControlClasses: {
    margin: '0 12px'
  },
  checkboxLabelControl: {
    margin: '0'
  },
  checkboxLabel: {
    marginLeft: '6px',
    color: 'rgba(0, 0, 0, 0.26)'
  },
  //   cardClasses:{

  //     textAlign: "center",
  //     display: "inline-block",
  //     position: "relative",
  //     width: "100%",
  //     height:"100%",
  //     margin: "15px 0 !important",
  //     boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
  //     borderRadius: "6px",
  //     color: "rgba(0, 0, 0, 0.87)",
  //     background: "#010C4E !important"

  // },

  cardTitle: {
    fontSize: '2.6em'
  }
}

export default notfoundstyle
