import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import { browserName, browserVersion } from "react-device-detect";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { resetGlobal } from "reactn";
// * Apollo client
import { ApolloClient } from "apollo-client";
import { ApolloProvider } from "react-apollo";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
// import { RetryLink } from 'apollo-link-retry';

import config from "../config"; // *End point configuration file

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// Custom components
import Header from "Custom-component/Header/Header.jsx";
import Footer from "Custom-component/Footer/Footer.jsx";
import Sidebar from "Custom-component/Sidebar/Sidebar.jsx";
// import AadharVerifyPage from "../Container/Aadhar/AadharVerifyPage";
//file imports
import Authenticatedroutes from "../routes/authenticatedroutes.jsx";
import history from "../history";
import Changepassword from "./Changepwd.jsx";
import ErrorPage from "../Container/Pages/errorPage.jsx";

//images
import image from "assets/img/lightbackground.jpg";
import Vlogo from "assets/img/Vlogo.png";
//styles
import authenticatedlayoutstyle from "./authenticatedlayoutstyle";

//configure the graphql endpoint
const httpLink = createHttpLink({
  uri: config.graphqlendpoint.URL
});

//configure the auth token
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("accessToken");
  return {
    headers: {
      ...headers,
      Authorization: token ? ` ${token}` : ""
    }
  };
});
//handling graphql or network error for the whole application
const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        //   switch (err.extensions.code) {
        // 	case 'UNAUTHENTICATED':
        console.log(`[GraphQL error]: Message: ${err}`);
      }

      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      if (`${networkError}`.includes("401")) {
        localStorage.clear();
        history.push({
          pathname: "/auth"
          // state: window.location.href
        });
      }
    }
  }
);

//composing all configuartion in link using apollolink
const link = ApolloLink.from([authLink, errorLink, httpLink]);

//creating apollo client instance
const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});

const switchRoutes = (
  <Switch>
    {Authenticatedroutes.map((prop, key) => {
      if (prop.redirect)
        return <Redirect from={prop.path} to={prop.pathTo} key={key} exact />;
      if (prop.collapse) {
        return prop.views.map((prop, key) => {
          return (
            <Route path={prop.path} component={prop.component} key={key} />
          );
        });
      }

      return (
        <Route path={prop.path} component={prop.component} key={key} exact />
      );
    })}
  </Switch>
);

var ps;

class Authenticatedlayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      employer_aadhaar_number: "",
      aadhar_number: "",
      employer_aadhaar_numberState: "",
      // showaadhaarnumber: true,
      showOTP: false,
      haserror: false,
      showChapgepasswordModal: false,
      aadhar_availability: false,
      request_id: localStorage.getItem("username") + new Date().getTime(),
      client_code: "",
      api_key: "",
      salt: "",
      hash_value: "",
      alert: null,
      country: "",
      showCountry: true,
      countryMessage: "",
      titleSweetAlert: "Failed",
      CurrentLocation: this.props.location.pathname,
      message:
        "We experiencing difficulties with connectivity of the application. Please try again later"
    };
    this.resizeFunction = this.resizeFunction.bind(this);
    this.close = this.close.bind(this);
    this.changepasswordModalClick = this.changepasswordModalClick.bind(this);
    this.failhideAlert = this.failhideAlert.bind(this);
    this.warningWithConfirmMessage = this.warningWithConfirmMessage.bind(this);
  }
  changepasswordModalClick() {
    this.setState({ showChapgepasswordModal: true });
  }

  // handle user logout -> clear all local storage data
  handleLogout = async event => {
    var headers = {
      Authorization: localStorage.getItem("accessToken")
    };
    try {
      await axios
        .get(config.Cognito.signout_url, { headers: headers })
        .then(data => {
          resetGlobal();
          localStorage.clear();
          this.props.history.push("/auth/login");
        })
        .catch(err => {
          resetGlobal();
          localStorage.clear();
          this.props.history.push("/auth/login");
          // this.warningWithConfirmMessage(err);
        });
    } catch (e) {
      this.warningWithConfirmMessage(e);
    }
  };
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", this.resizeFunction);

    //* this is to identity the user browser detaisl /ip /
    axios
      .get(" https://api.ipify.org/?format=json")
      .then(res => {
        localStorage.setItem("ip", res.data.ip);
      })
      .catch(err => {
        localStorage.setItem("ip", "-");
      });
    localStorage.setItem("browserName", browserName);
    localStorage.setItem("browserVersion", browserVersion);
    localStorage.setItem("deviceId", null);

    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    window.removeEventListener("resize", this.resizeFunction);
  }
  componentWillReceiveProps(nextProps) {
    // Any time props.email changes, update state.
    if (this.state.CurrentLocation !== window.location.pathname) {
      this.setState({ CurrentLocation: window.location.pathname });
      resetGlobal();
    }
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  componentDidCatch(error) {
    this.setState({ haserror: true });
  }
  // close function for change password field
  close(value) {
    this.setState({ showChapgepasswordModal: value });
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  getRoute() {
    return this.props.location.pathname !== "/maps/full-screen-maps";
  }
  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  warningWithConfirmMessage() {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-150px", color: "#000000" }}
          title={this.state.titleSweetAlert}
          onConfirm={() => this.failhideAlert()}
          onCancel={() => this.failhideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Ok"
        >
          {this.state.message}
        </SweetAlert>
      )
    });
  }
  failhideAlert() {
    this.setState({
      alert: null
    });
    localStorage.clear();
    history.push({
      pathname: "/auth"
    });
  }

  render() {
    const { classes, ...rest } = this.props;

    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });

    if (this.state.haserror) {
      return (
        <div>
          <ErrorPage />
        </div>
      );
    } else {
      return (
        <div className={classes.wrapper}>
          {/* if user logged and their identity is verified allow to do
					dashboard */}
          {localStorage.getItem("userlogged") ? (
            <ApolloProvider client={client}>
              <Sidebar
                changepasswordclick={this.changepasswordModalClick}
                handleLogout={this.handleLogout}
                routes={Authenticatedroutes}
                // logoText={"Verify Mate"}
                logo={Vlogo}
                image={image}
                handleDrawerToggle={this.handleDrawerToggle}
                open={this.state.mobileOpen}
                color="blue"
                bgColor="black"
                client={client}
                miniActive={this.state.miniActive}
                {...rest}
              />
              <div className={mainPanel} ref="mainPanel">
                <Header
                  sidebarMinimize={this.sidebarMinimize.bind(this)}
                  miniActive={this.state.miniActive}
                  routes={Authenticatedroutes}
                  handleDrawerToggle={this.handleDrawerToggle}
                  client={client}
                  {...rest}
                />
                {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
                {this.getRoute() ? (
                  <div className={classes.content}>
                    <div className={classes.container}>{switchRoutes}</div>
                  </div>
                ) : (
                  <div className={classes.map}>{switchRoutes}</div>
                )}
                {this.getRoute() ? <Footer fluid /> : null}
              </div>
            </ApolloProvider>
          ) : (
            // * if user not logged
            <div>
              <Redirect to="/auth" />
            </div>
          )}
          {this.state.showChapgepasswordModal ? (
            <Changepassword close={this.close} />
          ) : null}
          {this.state.alert}
        </div>
      );
    }
  }
}

Authenticatedlayout.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(authenticatedlayoutstyle)(Authenticatedlayout);
