import React from 'react';
import Gravatar from 'react-gravatar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
//@material-ui core
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
//@material-ui icons
import CameraAlt from '@material-ui/icons/CameraAlt';
//default image
import defaultImage from 'assets/img/avatar.png';
//file import
import config from '../../config.js';
import history from '../../history.js';
const ratio = require('aspect-ratio');

const styles = theme => ({
  typography: {
    padding: '2px'
  },
  viewiconbutton: {
    marginTop: '-81px',
    marginLeft: '88px',
    color: '#000',
    backgroundColor: '#cccccc',
    '&:hover ,&:focus': {
      backgroundColor: '#999999',
      color: 'black'
    }
  },
  popper: {
    left: '8px!important',
    top: '5px!important'
  }
});

let reader, file;
var filename;
class PictureUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile_image_hash: this.props.profile_image_hash,
      file: null,
      imagePreviewUrl:
        this.props.fileContent === null ? defaultImage : this.props.fileContent,
      anchorEl: null,
      open: false,
      errorMessage: '',
      dimensions: {}
    };
  }

  handleClick = event => {
    const { currentTarget } = event;
    this.setState(state => ({
      anchorEl: currentTarget,
      open: !state.open
    }));
  };

  // to validate the profile image
  validateProfile = (width, height) => {
    // to identify the aspect ratio of uploaded image
    var compareValue = 4 / 3;
    var aspectratio = ratio(width, height);
    var splitRatio = aspectratio.split(':');
    var currentImageDecimalValue =
      parseInt(splitRatio[0], 10) / parseInt(splitRatio[1], 10);
    //converting decimal to string to compare the ratio
    var decimalToString = currentImageDecimalValue.toString();
    var compareValueToString = compareValue.toString();

    // comparing the image is within 4:3 aspect ratio
    if (decimalToString <= compareValueToString) {
      var profileFileContent = reader.result.split(',');
      if (this.state.profile_image_hash) {
        const changeData = {
          fileContentToReplace: profileFileContent[1],
          hasedFilename: this.state.profile_image_hash,
          fileName: file.name,
          userName: localStorage.getItem('username')
        };
        //call to change the image
        this.uploadProfile(config.profileImage.ProfileChange, changeData);
      } else {
        const uploadData = {
          fileContent: profileFileContent[1],
          fileName: file.name,
          userName: localStorage.getItem('username')
        };
        //call to upload the image
        this.uploadProfile(config.profileImage.ProfileUpload, uploadData);
      }
    } else {
      this.setState({
        open: false,
        errorMessage: 'Please upload Passport size image'
      });
      this.props.data(this.state);
    }
  };

  handleImageChange = e => {
    e.preventDefault();
    reader = new FileReader();
    file = e.target.files[0];
    reader.onloadend = () => {
      filename = file.name;
      this.setState({ file: file });

      if (
        // supported file formats validation
        filename.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG)$/i)
      ) {
        // to identify height and width of image
        let im = new Image();
        im.src = reader.result;
        im.onload = () => {
          this.validateProfile(im.width, im.height);
        };
      } else {
        this.setState({
          open: false,
          errorMessage:
            'Unsupported image format.Supported formats are jpg,jpeg,png'
        });
        this.props.data(this.state);
      }
    };
    reader.readAsDataURL(file);
  };

  // function to upload the image
  uploadProfile = (filepath, input) => {
    this.setState({ open: false });
    var headers = {
      Authorization: localStorage.getItem('accessToken')
    };

    try {
      axios
        .post(filepath, input, { headers: headers })
        .then(response => {
          if (response.data.data.hashedFileName) {
            this.setState({
              imagePreviewUrl: reader.result,
              open: false,
              profile_image_hash: response.data.data.hashedFileName,
              errorMessage: ''
            });
          } else if (response.data.errors.error_code === 'GEMFU10002') {
            this.setState({
              open: false,
              errorMessage:
                'Unsupported image format. Supported formats are jpg,jpeg,png'
            });

            this.props.data(this.state);
          } else {
            this.setState({
              open: false,
              errorMessage:
                'Unable to change the profile. Please try after some time'
            });

            this.props.data(this.state);
          }
        })
        .catch(err => {
          if (err.response.status === 401) {
            localStorage.clear();
            history.push('/auth/login');
          } else {
            this.setState({
              open: false,
              errorMessage:
                'Unable to change the profile. Please try after some time'
            });
            this.props.data(this.state);
          }
        });
    } catch (e) {
      this.setState({
        open: false,
        errorMessage: 'Unable to change the profile. Please try after some time'
      });
      this.props.data(this.state);
    }
  };

  // function to remove the file
  handleRemoveProfile = () => {
    this.setState({ open: false });
    try {
      const removeData = {
        hasedFilename: this.state.profile_image_hash,
        userName: localStorage.getItem('username')
      };
      var headers = {
        Authorization: localStorage.getItem('accessToken')
      };
      axios
        .post(config.profileImage.ProfileRemove, removeData, {
          headers: headers
        })
        .then(response => {
          if (response.data.data) {
            this.setState({
              file: null,
              imagePreviewUrl: defaultImage,
              errorMessage: '',
              profile_image_hash: null,
              open: false
            });
          } else {
            this.setState({
              open: false,
              errorMessage:
                'Unable to remove the profile. Please try after some time'
            });

            this.props.data(this.state);
          }
        })
        .catch(err => {
          if (err.response.status === 401) {
            localStorage.clear();
            history.push('/auth/login');
          } else {
            this.setState({
              open: false,
              errorMessage:
                'Unable to remove the profile. Please try after some time'
            });

            this.props.data(this.state);
          }
        });
    } catch (e) {
      this.setState({
        open: false,
        errorMessage: 'Unable to remove the profile. Please try after some time'
      });

      this.props.data(this.state);
    }
  };

  upload() {
    document.getElementById('selectImage').click();
  }
  handleClose_Popper = () => {
    this.setState({ open: false });
  };
  render() {
    const { classes } = this.props;
    const { anchorEl, open } = this.state;
    const id = open ? 'simple-popper' : null;
    return (
      <div className='picture-container'>
        <div className='picture'>
          {this.state.profile_image_hash !== null ? (
            <div>
              <img
                src={this.state.imagePreviewUrl}
                className='picture-src'
                alt='...'
              />
            </div>
          ) : (
            <Gravatar email={this.props.email} default='mp' size={300} />
          )}
        </div>
        <IconButton
          className={classes.viewiconbutton}
          id='profile_picture_upload'
          key='profilepicture'
          aria-label='pic'
          color='inherit'
          onClick={this.handleClick}
        >
          <CameraAlt />
        </IconButton>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          transition
          className={classes.popper}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose_Popper}>
                  <Typography className={classes.typography}>
                    <span>
                      <Button id='plus' onClick={this.upload}>
                        Change
                      </Button>
                      <input
                        id='selectImage'
                        hidden
                        type='file'
                        onChange={e => this.handleImageChange(e)}
                      />
                    </span>
                    {this.state.profile_image_hash ? (
                      <Button onClick={this.handleRemoveProfile}>Remove</Button>
                    ) : null}
                  </Typography>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    );
  }
}

PictureUpload.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(PictureUpload);
