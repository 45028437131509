import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

const step1style = {
  ...customCheckboxRadioSwitch,
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#FFC90D !important"
  },
  inputAdornment: {
    position: "relative"
  },
  iconCheckboxIcon: {
    fontSize: "100px !important",
    lineHeight: "111px",
    marginTop: "15px !important",
    textAlign: "center !important"
  }
};

export default step1style;
