import gql from 'graphql-tag';

const EmployeeCountQuery = gql`
	query employeeCount($username: String!) {
		currentEmployeesCount(username: $username) {
			count
			__typename
		}
		getApprovedCount(username: $username) {
			count
			__typename
		}
	}
`;
export default EmployeeCountQuery;
