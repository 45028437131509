import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
// material-ui components
import Replay from '@material-ui/icons/Replay';
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import Button from '../../components/CustomButtons/Button.jsx';
import errorpagestyle from './errorpagestyle';
import error from '../../assets/img/Connection Broke.png';
// style
import './error.css';
class ErrorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    };
    this.refresh = this.refresh.bind(this);
  }

  handleClose = () => {};

  refresh() {
    window.location.reload();
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          animationDuration={1000}
        >
          <h1
            style={{ color: 'white', fontWeight: 'bold', textAlign: 'center' }}
          >
            OOPs!!!
          </h1>
          <h3 style={{ color: 'white', textAlign: 'center' }}>
            We are sorry, but something went wrong.
          </h3>
          <h3 style={{ color: 'white', textAlign: 'center' }}>
            We are working on it
          </h3>

          <div className='row'>
            <div className='cell'>
              <img src={error} alt='...' className='img' />
            </div>
          </div>
          <div className={classes.center}>
            <GridContainer justify='center'>
              <GridItem xs={12} sm={12} md={3}>
                <Button
                  id='errorhandling_tryagain_button'
                  color='tumblr'
                  onClick={() => this.refresh()}
                >
                  <Replay /> &nbsp; Please Try Again
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Modal>
      </div>
    );
  }
}

ErrorPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(errorpagestyle)(ErrorPage);
