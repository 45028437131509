import gql from "graphql-tag";
const CommentRetrieveQuery = gql`
  query CommentQuery($username: String!, $file_hash: String!) {
    getComments(username: $username, file_hash: $file_hash) {
      comments {
        file_hash
        comment
        added_by
        added_at
        modified_at
      }
      __typename
    }
    __typename
  }
`;
export default CommentRetrieveQuery;
