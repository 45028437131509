import gql from "graphql-tag";

const EmployeeOnboardRequest = gql`
  query EmailQuery($username: String!, $employee_mail: String!) {
    onBoardRequestToEmployee(
      username: $username
      employee_mail: $employee_mail
    ) {
      message
      __typename
    }
    __typename
  }
`;

export default EmployeeOnboardRequest;
