import React, { Fragment, Suspense } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import MUIDataTable from "mui-datatables";
import Tour from "reactour";
import { getGlobal, resetGlobal } from "reactn";
import ScreenLoading from "../LoadingScreen.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
//Styles
import Mytheme from "../Custom-Styles/CustomMuiTheme";
import IconStyles from "../Custom-Styles/GridIconStyle";
import ErrorPage from "../Pages/errorPage.jsx";
//queries
import CommentRetrieveQuery from "../../Graphql/CommentRetrieveQuery";
import BlockchaindetailsQuery from "../../Graphql/blockchaindetailsQuery";
// Date formater
var dateFormat = require("dateformat");
const EmployeeExitTrigger = React.lazy(() =>
  import("./EmployeeExitTrigger.jsx")
);
const CurrentEmployeeView = React.lazy(() =>
  import("./CurrentEmployeeView.jsx")
);
class Myemployee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employee_tabledata: [],
      showEmployee_resignation_page: false,
      showcurrent_employee_grid: true,
      showcurrent_employee_view: false,
      employee_employment_data: [],
      loadingalert: false,
      loadingalert_error: false,
      blockchaindetails_response: "",
      hashError: false,

      // joy ride variables

      prevhelp: true,
      havingCurrentEmployee: false,
      steps: [
        {
          content: "Here we list the employee's of the organization",
          position: "bottom",
          selector: ".EmployeeClass"
        },
        {
          content: () => (
            <Fragment>
              <b> View Employee Details</b>
              <br />
              You can view the details of employee
            </Fragment>
          ),
          position: "top",

          selector: ".ViewEmployeeClass"
        },
        {
          content: () => (
            <Fragment>
              <b> Exit Employee Process</b>
              <br />
              To exit the current employee of the organisation
            </Fragment>
          ),
          position: "top",
          selector: ".editEmployeeClass"
        }
      ] // steps to display in react tour
    };
  }

  // function to close the tour
  closeTour = () => {
    resetGlobal();
    this.setState({ help: false, prevhelp: true });
  };

  handleBack = () => {
    resetGlobal();
    this.setState({
      showEmployee_resignation_page: false,
      showcurrent_employee_grid: true,
      showcurrent_employee_view: false,
      prevhelp: false
    });
  };
  //handling error in components
  componentDidCatch(error, info) {
    //if (greater than the total available page length) error occur we redirecting it to starting page
    if (
      error.toString().includes("greater than the total available page length")
    ) {
      this.setState({ page: 0 });
    } else {
      this.setState({ hashError: true });
    }
  }

  employeeTable(all_employees) {
    var employee_tabledata = [];
    var help = true;
    var havingCurrentEmployee = false;
    var self = this;

    const { client, classes } = this.props;
    //for grid view element should be in same order column and the data pushed here should be same count.
    Object.keys(all_employees).forEach(function(key) {
      if (all_employees[key].end_date) {
        var endDate = dateFormat(
          new Date(all_employees[key].end_date),
          "dd/mm/yyyy"
        );
        var dateParts = endDate.split("/");
        var day = dateParts[0];
        var month = dateParts[1] - 1;
        var year = dateParts[2];
        // set a date
        var exitDate = new Date(year, month, day);

        // set a date
        var today = new Date();
        today.setHours(0, 0, 0, 0);
      }

      // To check whether we have current employee or not
      if (
        (all_employees[key].employement_status === "Current" ||
          (all_employees[key].end_date && exitDate >= today)) &&
        !havingCurrentEmployee
      ) {
        havingCurrentEmployee = true;
      }

      // to start the react tour if is new user
      if (all_employees[key].employement_status === "Exited" && help) {
        help = false;
      }

      // to push the data to form table
      employee_tabledata.push([
        //6
        all_employees[key].name,
        all_employees[key].employee_id,
        dateFormat(new Date(all_employees[key].joining_date), "dd/mm/yyyy"),
        all_employees[key].end_date === null
          ? "-"
          : dateFormat(new Date(all_employees[key].end_date), "dd/mm/yyyy"),
        all_employees[key].designation,
        all_employees[key].department,
        all_employees[key].end_date && exitDate >= today
          ? "Current"
          : all_employees[key].employement_status,

        <div className="actions-right">
          {/* Icon button to view the employment data in details */}
          <IconButton
            aria-label="view"
            className={classes.viewiconbutton}
            onClick={async () => {
              self.setState({
                help: false,
                showcurrent_employee_grid: false,
                loadingalert: true,
                loadingalert_error: false
              });
              all_employees[key].verified_status !== "Pending"
                ? await client
                    .query({
                      query: CommentRetrieveQuery,
                      variables: {
                        username: all_employees[key].username,
                        file_hash: all_employees[key].file_hash
                      }
                    })
                    .then(response => {
                      client
                        .query({
                          query: BlockchaindetailsQuery,
                          variables: {
                            file_hash: all_employees[key].file_hash
                          }
                        })
                        .then(res => {
                          self.setState({
                            loadingalert: false,
                            blockchaindetails_response: res,
                            employee_employment_data: all_employees[key], //set the particular employee data in state variable employee employment data
                            showEmployee_resignation_page: false,
                            showcurrent_employee_grid: false,
                            showcurrent_employee_view: true,
                            employer_comments: response
                          });
                        })
                        .catch(err => {
                          self.setState({
                            showcurrent_employee_grid: true,
                            loadingalert: false,
                            loadingalert_error: true
                          });
                        });
                    })
                    .catch(err => {
                      self.setState({
                        showcurrent_employee_grid: true,
                        loadingalert: false,
                        loadingalert_error: true
                      });
                    })
                : self.setState({
                    loadingalert: false,
                    employee_employment_data: all_employees[key], //set the particular employee data in state variable employee employment data
                    showEmployee_resignation_page: false,
                    showcurrent_employee_grid: false,
                    showcurrent_employee_view: true,
                    employer_comments: "No Comments Found"
                  });
            }}
          >
            <Tooltip title="View" className="ViewEmployeeClass">
              <Visibility />
            </Tooltip>
          </IconButton>{" "}
          &nbsp; &nbsp;
          {/* Icon button to edit the employee exit data */}
          <IconButton
            aria-label="edit"
            className={classes.editiconbutton}
            disabled={all_employees[key].employement_status === "Exited"}
            onClick={async () => {
              self.setState({
                showcurrent_employee_grid: false,
                help: false,
                loadingalert: true
              });
              all_employees[key].verified_status !== "Pending"
                ? await client
                    .query({
                      query: CommentRetrieveQuery,
                      variables: {
                        username: all_employees[key].username,
                        file_hash: all_employees[key].file_hash
                      }
                    })
                    .then(response => {
                      self.setState({
                        loadingalert: false,
                        employee_employment_data: all_employees[key], //set the particular employee data in state variable employee employment data
                        showEmployee_resignation_page: true,
                        showcurrent_employee_grid: false,
                        showcurrent_employee_view: false,
                        employer_comments: response
                      });
                    })
                    .catch(err => {
                      self.setState({
                        showcurrent_employee_grid: true,
                        loadingalert: false,
                        loadingalert_error: true
                      });
                    })
                : self.setState({
                    loadingalert: false,
                    loadingalert_error: true,
                    employee_employment_data: all_employees[key], //set the particular employee data in state variable employee employment data
                    showEmployee_resignation_page: true,
                    showcurrent_employee_grid: false,
                    showcurrent_employee_view: false,
                    employer_comments: "No Comments Found"
                  });
            }}
          >
            <Tooltip title="Edit" className="editEmployeeClass">
              <Edit
                style={
                  all_employees[key].employement_status === "Exited"
                    ? { color: "#b7b3ae" }
                    : null
                }
              />
            </Tooltip>
          </IconButton>{" "}
        </div>
      ]);
    });

    self.setState({
      employee_tabledata: employee_tabledata,
      help: help,
      prevhelp: help,
      havingCurrentEmployee: havingCurrentEmployee
    });
  }
  componentDidMount() {
    this.employeeTable(this.props.MyAllEmployees.employees);
  }
  componentDidUpdate(props, state) {
    if (state.employee_tabledata.length) {
      var allemployee = props.MyAllEmployees.employees;
      var changed = false;
      Object.keys(allemployee).forEach(function(key) {
        if (
          allemployee[key].employement_status !==
            state.employee_tabledata[key][6] &&
          state.employee_tabledata[key][3] === "-"
        ) {
          changed = true;
        }
      });
    }
    if (changed) {
      this.employeeTable(props.MyAllEmployees.employees);
    }
  }
  render() {
    const { classes, history, client } = this.props;
    const { steps } = this.state;
    const options = {
      filterType: "dropdown",
      responsive: "stacked",
      fixedHeader: true,
      page: this.state.page,
      selectableRows: false
    };
    //Employee Table column order
    const columns = [
      {
        name: "Employee Name",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "Employee Id",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "DOJ",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "DOE",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "Designation",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "Department",
        options: {
          filter: true,
          sort: true
        }
      },

      {
        name: "Employment Status",
        options: {
          filter: true,
          sort: true,
          display: false,
          filterList: ["Current"]
        }
      },

      {
        name: "Action",
        options: {
          filter: false,
          sort: true,
          download: false
        }
      }
    ];
    if (this.state.hashError) {
      return (
        <div>
          <ErrorPage />
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <GridContainer>
            <GridItem xs={12}>
              {this.state.showcurrent_employee_grid ? (
                <div>
                  <Card>
                    {this.state.havingCurrentEmployee ? (
                      <Tour
                        onRequestClose={this.closeTour}
                        steps={steps}
                        isOpen={
                          getGlobal().value
                            ? true
                            : this.state.help
                            ? true
                            : false
                        }
                        accentColor="#FFC90D"
                      />
                    ) : null}
                    <CardHeader color="info" icon>
                      <CardIcon color="info" className="EmployeeClass">
                        <Icon>
                          <i className="fa fa-users" />
                        </Icon>
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>Employees List</h4>
                    </CardHeader>
                    <CardBody>
                      <MuiThemeProvider theme={Mytheme}>
                        <MUIDataTable
                          title={""}
                          data={this.state.employee_tabledata}
                          columns={columns}
                          options={options}
                        />
                      </MuiThemeProvider>
                    </CardBody>
                  </Card>
                </div>
              ) : null}
              {this.state.showEmployee_resignation_page ? (
                // Current Employee Employment Exit Page
                <Suspense fallback={<ScreenLoading />}>
                  <EmployeeExitTrigger
                    handleback={this.handleBack}
                    employee_employment_data={
                      this.state.employee_employment_data
                    }
                    history={history}
                    client={client}
                    employer_comments={this.state.employer_comments}
                    help={this.state.prevhelp}
                  />
                </Suspense>
              ) : null}
              {this.state.showcurrent_employee_view ? (
                // Employee  Employment View Page
                <Suspense fallback={<ScreenLoading />}>
                  <CurrentEmployeeView
                    handleback={this.handleBack}
                    employee_employment_data={
                      this.state.employee_employment_data
                    }
                    transaction_details={this.state.blockchaindetails_response}
                    employer_comments={this.state.employer_comments}
                  />
                </Suspense>
              ) : null}
            </GridItem>
          </GridContainer>
          {this.state.loadingalert ? <ScreenLoading /> : null}
          {this.state.loadingalert_error ? (
            <SweetAlert
              style={{
                display: "block",
                marginTop: "-100px",
                color: "#000000"
              }}
              title="Failed to fetch."
              onConfirm={() => this.setState({ loadingalert_error: false })}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.danger
              }
              showConfirm={true}
            >
              <h4>Please try Again.</h4>
            </SweetAlert>
          ) : null}
        </React.Fragment>
      );
    }
  }
}

export default withStyles(IconStyles)(Myemployee);
