import gql from 'graphql-tag';

const GetMyEmployeeQuery = gql`
	query EmployeesQuery($username: String!) {
		getMyAllEmployees(username: $username) {
			employees {
				username
				name
				document_name
				file_id
				file_hash
				document_extension
				document_upload_type
				employee_id
				employer_name
				employer_mail_id
				organization_name
				joining_date
				end_date
				employement_status
				experience
				designation
				department
				verified_status
				uploaded_by
				uploaded_at
				approved_by
				approver_email
				__typename
			}
			__typename
		}
	}
`;
export default GetMyEmployeeQuery;
