import { container } from "assets/jss/material-dashboard-pro-react.jsx";
import userProfileStyles from "../Employer Profile/profilepagestyle";

const AadharVerifyStyle = {
  ...userProfileStyles,
  container: {
    ...container,
    position: "relative",
    zIndex: "3"
  },
  form: {
    padding: "0 20px",
    position: "relative"
  },
  inputAdornment: {
    marginRight: "18px",
    position: "relative"
  },
  inputAdornmentIcon: {
    color: "#FFC90D !important"
  },
  mainheading: {
    color: "#4c4c4e",
    fontWeight: "bolder",
    textAlign: "center",
    marginTop: "30px"
  },
  maincontainer: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    flexBasis: "70%",
    alignItems: "center",
    alignContent: "center",
    margin: "5%",
    "@media (max-width: 600px)": {
      display: "flex",
      flexFlow: "column",
      margin: "0px"
    }
  },
  maincontainerColumn: {
    flex: "1",
    margin: "20px"
  },
  subTitle: {
    fontSize: "1.313rem",
    maxWidth: "500px"
  },
  notApplicableMsg: {
    textAlign: "justify"
  },
  countrySubmitButton: {
    display: "flex",
    marginTop: "25px",
    justifyContent: "center"
  }
};

export default AadharVerifyStyle;
