import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
// core components
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';
// material ui components
import withStyles from '@material-ui/core/styles/withStyles';
// file imports
import config from '../../config';
import history from '../../history.js';

class Aadhar extends React.Component {
  constructor(props) {
    super(props);
    const { status, uuid, hash } = this.props;
    this.state = {
      hash: hash,
      status: status,
      uuid: uuid,
      alert: null,
      alertSuccess: true,
      showmodal: true
    };
  }
  componentDidMount() {
    var headers = {
      Authorization: localStorage.getItem('accessToken')
    };
    var aadhar_payload = {
      aadhar_number: localStorage.getItem('AadharNumber'),
      uuid: this.state.uuid
    };
    if (this.state.uuid !== '' && localStorage.getItem('AadharNumber') !== '') {
      try {
        axios
          .post(config.compare_aadhar, aadhar_payload, {
            headers: headers
          })
          .then(res => {
            if (res.data.data) {
              localStorage.setItem('name', res.data.name);
              this.setState({ showmodal: false });
            } else {
              this.setState({ showmodal: false, status: 'fail' });
            }
          })
          .catch(err => {
            if (err.response.status === 401) {
              localStorage.clear();
              history.push('/auth/login');
            } else {
              this.setState({ showmodal: false, status: 'fail' });
            }
          });
      } catch (e) {
        this.setState({ showmodal: false, status: 'fail' });
      }
    } else {
      this.setState({ showmodal: false, status: 'fail' });
    }
  }
  // time duration for success alert
  aadhar_handle_alerts = status => {
    if (status === 'success') {
      localStorage.setItem('aadhar_verified', 1);
    }
    history.push('/dashboard');
    this.setState({
      alert: null
    });
  };
  render() {
    return (
      <div>
        {this.state.showmodal ? (
          <SweetAlert
            id='login_failure1'
            Success
            style={{ display: 'block', marginTop: '-245px' }}
            title='Verifying...'
            onConfirm={() => this.aadhar_handle_alerts('fail')}
            onCancel={() => this.aadhar_handle_alerts('fail')}
            showConfirm={false}
          >
            Please Wait
          </SweetAlert>
        ) : this.state.status === 'SUCCESS' &&
          this.state.alertSuccess === true ? (
          <SweetAlert
            id='login_failure2'
            Success
            style={{ display: 'block', marginTop: '-245px' }}
            title='Verification Completed'
            onConfirm={() => this.aadhar_handle_alerts('success')}
            showConfirm={true}
            confirmBtnCssClass={
              this.props.classes.button + ' ' + this.props.classes.info
            }
            confirmBtnText='Ok'
          >
            Aadhar Verification completed successfully.
          </SweetAlert>
        ) : this.state.status && this.state.alertSuccess === true ? (
          <SweetAlert
            id='login_failure4'
            warning
            style={{ display: 'block', marginTop: '-245px' }}
            title='Verification Failed'
            onConfirm={() => this.aadhar_handle_alerts('fail')}
            onCancel={() => this.aadhar_handle_alerts('fail')}
            showConfirm={true}
            confirmBtnCssClass={
              this.props.classes.button + ' ' + this.props.classes.danger
            }
            confirmBtnText='Try Again'
          >
            Please Try Again
          </SweetAlert>
        ) : null}
        {this.state.alert}
      </div>
    );
  }
}
Aadhar.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(sweetAlertStyle)(Aadhar);
